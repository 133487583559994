import { setupSGWTConnect, SGWTConnectCore } from "@sgwt/connect-core";

export const getWidgetBus = () => {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
};

export const sgwtConnectComponent = (): SGWTConnectCore => {
  const configuration = window['globalConfiguration'].sgConnectConfig;
  return setupSGWTConnect({
    authorization_endpoint: configuration.authorizationEndpoint,
    client_id: configuration.clientId,
    scope: 'openid profile mail api.manage-ict.v1 api.manage-commercial-contacts.v2 api.manage-contacts.v1 api.cerberus-api.v1 api.get-detailed-profitability-information.v1 api.cpr-dataextract-api.v1 api.cpr-api-massupload.v1 api.cpr-datacollect.v1',
    redirect_uri: configuration.redirectUri,
  });
};
