import * as React from "react";
import { isEmpty, find, map } from "lodash";
import { SetSettings, Domain } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import { MultiSelector } from "components/common/MultiSelector/MultiSelector";
import { DomainEnum } from "api/request/request.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { getActiveWebModules, getClaimUrl } from "./visibility.state.logic";
import { getClaimOptions, getClaimValues } from "./VisibilityWebModules";
import { isWebModuleChecked } from "components/Request/RequestValidate/Tabs/VisibilityWebModules";
import { IdName } from "common/common.typings";

export interface WebModulesCheckboxesProps {
  webModulesSettings: SetSettings[];
  dimensions: Dimension[];
  domainsConfig: Domain[];
  onWebModuleSelectionChanged: (domainId: DomainEnum, checked: boolean) => void;
  onClaimValueChanged?: (domainId: DomainEnum, claimId: string, values: string[]) => void;
  fetchClaimValues: (claimId: string, claimServiceUrl: string, domain?: DomainEnum) => void;
}

export const WebModulesCheckboxes: React.FunctionComponent<WebModulesCheckboxesProps> = ({
  domainsConfig,
  dimensions,
  webModulesSettings,
  onWebModuleSelectionChanged,
  onClaimValueChanged,
  fetchClaimValues,
}) => {  
  const checkDefaultClaims = (domainId: string) => {
    if (!isEmpty(webModulesSettings) && find(webModulesSettings[0].settings, { claim: { domainId } })) {
      return true;
    }
    return false;
  };

  const suppliers = getClaimValues(webModulesSettings, DomainEnum.MASSUPLOAD, "suppliers");
  
  const sbls = getClaimValues(webModulesSettings, DomainEnum.CORRECTION, "sbl");
  const dataCollectSuppliers = getClaimValues(webModulesSettings, DomainEnum.DATACOLLECT, "suppliers");
  
  
  for (const domainId of Object.keys(DomainEnum)) {
    const domain: DomainEnum = DomainEnum[domainId] as DomainEnum;
    isWebModuleChecked[domainId] = isWebModuleEnabled(webModulesSettings, domain);
  }

  React.useEffect(() => {
    if (isWebModuleChecked.MASSUPLOAD && !isEmpty(domainsConfig)) {
      const suppliersClaimUrl = getClaimUrl(domainsConfig, DomainEnum.MASSUPLOAD, "suppliers");
      fetchClaimValues("suppliers", suppliersClaimUrl);
    }
  }, [isWebModuleChecked.MASSUPLOAD, domainsConfig]);

  React.useEffect(() => {
    if (isWebModuleChecked.CORRECTION && !isEmpty(domainsConfig)) {
      const sblsClaimUrl = getClaimUrl(domainsConfig, DomainEnum.CORRECTION, "sbl");
      fetchClaimValues("sbl", sblsClaimUrl);
    }
  }, [isWebModuleChecked.CORRECTION, domainsConfig]);

  React.useEffect(() => {
    if (isWebModuleChecked.DATACOLLECT && !isEmpty(domainsConfig)) {
      const dataCollectSuppliersClaimUrl = getClaimUrl(domainsConfig, DomainEnum.DATACOLLECT, "suppliers");
      
      fetchClaimValues("suppliers", dataCollectSuppliersClaimUrl, DomainEnum.DATACOLLECT);
      
    }
  }, [isWebModuleChecked.DATACOLLECT, domainsConfig]);

  return (
    <div className="col-12">
      <div className="row align-items-center my-2">
        <label className="text-left"> Modules : </label>
        <div className="col-md-12 col-sm-12 ml-3">
          <Checkbox
            id="correction"
            label="Correction"
            classNames="col"
            checked={checkDefaultClaims("correction")}
            onChange={value => onWebModuleSelectionChanged(DomainEnum.CORRECTION, value)}
          />
          <div className="col-6 ml-4">
            <fieldset disabled={!isWebModuleChecked.CORRECTION}>
              <div className="row align-items-center my-2">
                <label className="text-left"> SBL : </label>
                <div className="col-md-6 col-sm-12 ml-4">
                  <MultiSelector
                    onChanged={values =>
                      checkDefaultClaims("correction") && onClaimValueChanged(DomainEnum.CORRECTION, "sbl", values)
                    }
                    items={getClaimOptions(dimensions, "sbl", DomainEnum.CORRECTION)}
                    selectedItems={sbls}
                    disabled={!isWebModuleChecked.CORRECTION}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 my-2 ml-3">
          <Checkbox
            id="forecast"
            label="Forecast"
            classNames="col"
            checked={checkDefaultClaims("forecast")}
            onChange={value => onWebModuleSelectionChanged(DomainEnum.FORECAST, value)}
          />
        </div>
        <div className="col-md-12 col-sm-12 my-2 ml-3">
          <Checkbox
            id="psm"
            label="PSM/CBC"
            classNames="col"
            checked={checkDefaultClaims("psm")}
            onChange={value => onWebModuleSelectionChanged(DomainEnum.PSM, value)}
          />
        </div>

        <div className="col-md-12 col-sm-12 my-2 ml-3">
          <Checkbox
            id="dc"
            label="MassUpload"
            classNames="col"
            checked={checkDefaultClaims("massupload")}
            onChange={value => onWebModuleSelectionChanged(DomainEnum.MASSUPLOAD, value)}
          />
          <div className="col-6 ml-2">
            <fieldset disabled={!isWebModuleChecked.MASSUPLOAD}>
              <div className="row align-items-center my-3">
                <label className="text-left"> Suppliers : </label>
                <div className="col-md-6 col-sm-12">
                  <MultiSelector
                    onChanged={values =>
                      checkDefaultClaims("massupload") &&
                      onClaimValueChanged(DomainEnum.MASSUPLOAD, "suppliers", values)
                    }
                    items={map(
                      getClaimOptions(dimensions, "suppliers", DomainEnum.MASSUPLOAD),
                      (value): IdName<string> => {
                        return { id: value.id.toString(), name: value.name };
                      }
                    )}
                    selectedItems={suppliers}
                    disabled={!isWebModuleChecked.MASSUPLOAD}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 my-2 ml-3">
          <Checkbox
            id="datacollect"
            label="DataCollect"
            classNames="col"
            checked={checkDefaultClaims("datacollect")}
            onChange={value => onWebModuleSelectionChanged(DomainEnum.DATACOLLECT, value)}
          />
          <div className="col-6 ml-2">
            <fieldset disabled={!isWebModuleChecked.DATACOLLECT}>
              <div className="row align-items-center my-3">
                <label className="text-left"> Suppliers : </label>
                <div className="col-md-6 col-sm-12">
                  <MultiSelector
                    onChanged={values =>
                      checkDefaultClaims("datacollect") &&
                      onClaimValueChanged(DomainEnum.DATACOLLECT, "suppliers", values)
                    }
                    items={map(
                      getClaimOptions(dimensions, "suppliers", DomainEnum.DATACOLLECT),
                      (value): IdName<string> => {
                        return { id: value.id.toString(), name: value.name };
                      }
                    )}
                    selectedItems={dataCollectSuppliers}
                    disabled={!isWebModuleChecked.DATACOLLECT}
                  />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

const isWebModuleEnabled = (webModulesSettings: SetSettings[], domainId: DomainEnum): boolean => {
  return getActiveWebModules(webModulesSettings).some(domain => domain === domainId);
};
