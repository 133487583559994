import { UserRequestDetail } from "../../request.typings";
import { isType } from "typescript-fsa";
import { fetchRequestByIdAction } from "../../detail/request.detail.action";

export interface RequestInfoState {
  request: UserRequestDetail;
  isFetching: boolean;
  requestId?: string;
  errorMessage: string;
}

export const initUserState: RequestInfoState = {
  request: null,
  errorMessage: null,
  isFetching: false,
};

export const fetchRequestInfoReducer = (state: RequestInfoState = initUserState, action): RequestInfoState => {
  if (isType(action, fetchRequestByIdAction.started)) {
    return { ...state, isFetching: true, request: null, errorMessage: null };
  }
  if (isType(action, fetchRequestByIdAction.done)) {
    return { ...state, request: action.payload.result, isFetching: false };
  }
  if (isType(action, fetchRequestByIdAction.failed)) {
    return { ...state, isFetching: false, request: null, errorMessage: action.payload.error };
  }
  return state;
};

export const requestInfoReducer = fetchRequestInfoReducer;
