import * as React from "react";
import { Collapse } from "components/common/Collapse/Collapse";
import {
  SetSettings,
  UserVisibility,
  SgViewTagsColorEnum,
  MarkViewTagsColorEnum,
  SetIdEnum,
  VisibilityClone,
  Domain,
  VisibilityClaim,
  VisibilitySettings,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import { size, get, isEmpty, filter, first } from "lodash";
import { ViewSettingEnum, SetIds } from "./VisibilitySet";
import { VisibilitySetList } from "./VisibilitySetList";
import { FormActions } from "components/common/FormActions/FormAction";
import {
  addVisibilitySet,
  updateClaimId,
  updateClaimValues,
  deleteVisibilitySet,
  getSetSettings,
  addNewVisibilitySet,
  getViewAccess,
  fillVisibilityClaimsColors,
  getClaims,
  newVisibilitySet,
  shouldRenderVisibility,
  updateEnabledWebModules,
  updateWebModulesClaimValues,
  getActiveWebModules,
  getTagListForWebmodules,
  updateDataQualityClaimValues,
  updateKoreanDataFlag,
  updateClientContributionClaimValues,
  updateClaimOperator,
} from "./visibility.state.logic";
import { Checkbox } from "components/common/Checkbox/Checkbox";
import { RequestModal } from "./RequestModal";
import { VisibilityTagList } from "./VisibilityTagList";
import { CloneModal } from "./CloneModal";
import { DomainEnum } from "api/request/request.typings";
import { VisibilityWebModulesContainer } from "containers/request/validate/requestVisibility/webModules/request.webmodules.container";
import { Loading } from "components/common/Loading/Loading";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { VisibilityDataQualityContainer } from "containers/request/validate/requestVisibility/dataQuality/request.dataquality.container";
import { IconInfo } from "components/common/Icon/Icon";
import KoreanDataSetting from "./KoreanDataSetting";
import { VisibilityClientContributionContainer } from "containers/request/validate/requestVisibility/clientContribution/request.clientcontribution.container";

export const defaultValidationComment =
  "The requested visibility is beyond the user's perimeter. Please justify the business need below.";
export interface StateProps {
  isFetching: boolean;
  isVisibilityFecthing: boolean;
  requestId: string;
  visibility: UserVisibility;
  connectedUser: string;
  isValidateCommentRequired: boolean;
  domains: Domain[];
  webModulesSettings?: SetSettings[];
  dimensions: Dimension[];
  visibilityType: string;
  dataQualitySettings?: SetSettings[];
  koreanDataFlag : boolean | null;
  koreanDataComment: string | null;
  clientContributionSettings?: SetSettings[];
}

export interface DispatchProps {
  fetchVisibility: (requestId: string) => void;
  fetchAxises: () => void;
  cancelRequest: () => void;
  submitRequest: (allSettings: SetSettings[], requestId: string, koreanDataFlag: boolean | null, koreanDataComment: string | null) => void;
  validateRequest: (allSettings: SetSettings[], requestId: string, koreanDataFlag: boolean | null, koreanDataComment: string | null) => void;
  rejectRequest: (requestId: string, rejectionComment: string) => void;
  validateRequestFromModal: (requestId: string, rejectionComment: string) => void;
  cloneRequest: (cloneVisibility: VisibilityClone) => void;
}

export type Props = StateProps & DispatchProps;

export interface State {
  isLoaded: boolean;
  sgViewAccess: boolean;
  markViewAccess: boolean;
  sgViewSettings: SetSettings[];
  markViewSettings: SetSettings[];
  webModulesSettings: SetSettings[];
  extendedSgViewAccess: boolean;
  extendedMarkViewAccess: boolean;
  extendedSgViewSettings: SetSettings[];
  extendedMarkViewSettings: SetSettings[];
  asofViewAccess: boolean;
  facetViewAccess: boolean;
  asofViewSettings: SetSettings[];
  facetViewSettings: SetSettings[];
  showRejectRequestModal: boolean;
  showValidateRequestModal: boolean;
  showCloneRequestModal: boolean;
  requestComment?: string;
  isRejectButtonEnabled: boolean;
  isValidateButtonEnabled: boolean;
  isShowValidateModalHandled: boolean;
  dataQualitySettings: SetSettings[];
  koreanDataFlag: boolean | null;
  koreanDataComment: string | null;
  clientContributionSettings: SetSettings[];
}

export class Visibility extends React.Component<Props, State> {
  public static getDerivedStateFromProps(props: Props, state: State): State {
    if (shouldRenderVisibility(props, state)) {
      const markViewSettings = get(props, "visibility.markViewSettings");
      const sgViewSettings = get(props, "visibility.sgViewSettings");
      const webModulesSettings = get(props, "visibility.webModulesSettings");
      const extendedSgViewSettings = get(props, "visibility.extendedSgViewSettings");
      const extendedMarkViewSettings = get(props, "visibility.extendedMarkViewSettings");
      const asofViewSettings = get(props, "visibility.asofViewSettings");
      const facetViewSettings = get(props, "visibility.facetViewSettings");
      const dataQualitySettings = get(props, "visibility.dataQualitySettings");
      const clientContributionSettings = get(props, "visibility.clientContributionSettings");

      if (markViewSettings && sgViewSettings) {
        return {
          ...state,
          isLoaded: true,
          markViewSettings: addNewVisibilitySet(markViewSettings),
          markViewAccess: getViewAccess(markViewSettings),
          sgViewSettings: addNewVisibilitySet(sgViewSettings),
          sgViewAccess: getViewAccess(sgViewSettings),
          extendedSgViewAccess: getViewAccess(extendedSgViewSettings),
          extendedSgViewSettings: addNewVisibilitySet(extendedSgViewSettings),
          extendedMarkViewAccess: getViewAccess(extendedMarkViewSettings),
          extendedMarkViewSettings: addNewVisibilitySet(extendedMarkViewSettings),
          asofViewAccess: getViewAccess(asofViewSettings),
          facetViewAccess: getViewAccess(facetViewSettings),
          asofViewSettings: addNewVisibilitySet(asofViewSettings),
          facetViewSettings: addNewVisibilitySet(facetViewSettings),
          webModulesSettings,
          dataQualitySettings,
          koreanDataFlag: get(props, "koreanDataFlag"),
          koreanDataComment: get(props, "koreanDataComment"),
          clientContributionSettings
        };
      }
    }

    if (
      props.isValidateCommentRequired === true &&
      state.showValidateRequestModal === false &&
      state.isShowValidateModalHandled === true
    ) {
      return { ...state, showValidateRequestModal: props.isValidateCommentRequired };
    }

    return state;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoaded: false,
      sgViewAccess: false,
      markViewAccess: false,
      sgViewSettings: [],
      markViewSettings: [],
      webModulesSettings: [],
      extendedSgViewAccess: false,
      extendedMarkViewAccess: false,
      extendedSgViewSettings: [],
      extendedMarkViewSettings: [],
      asofViewAccess: false,
      facetViewAccess: false,
      asofViewSettings: [],
      facetViewSettings: [],
      showRejectRequestModal: false,
      showValidateRequestModal: false,
      isRejectButtonEnabled: false,
      isValidateButtonEnabled: false,
      isShowValidateModalHandled: false,
      showCloneRequestModal: false,
      dataQualitySettings: [],
      koreanDataComment: null,
      koreanDataFlag: null,
      clientContributionSettings: []
    };
  }

  public componentDidMount() {
    const { requestId } = this.props;
    this.props.fetchAxises();
    this.props.fetchVisibility(requestId);
  }

  public render() {
    const { domains, isFetching, dimensions, visibilityType } = this.props;
    const {
      sgViewSettings,
      markViewSettings,
      webModulesSettings,
      sgViewAccess,
      markViewAccess,
      extendedSgViewAccess,
      extendedMarkViewAccess,
      extendedSgViewSettings,
      extendedMarkViewSettings,
      asofViewAccess,
      facetViewAccess,
      asofViewSettings,
      facetViewSettings,
      showRejectRequestModal,
      isRejectButtonEnabled,
      showValidateRequestModal,
      isValidateButtonEnabled,
      showCloneRequestModal,
      dataQualitySettings,
      koreanDataFlag,
      koreanDataComment,
      clientContributionSettings
    } = this.state;

    const sgViewClaims = fillVisibilityClaimsColors(getClaims(sgViewSettings), SgViewTagsColorEnum, sgViewAccess);
    const markViewClaims = fillVisibilityClaimsColors(
      getClaims(markViewSettings),
      MarkViewTagsColorEnum,
      markViewAccess
    );
    const webModulesClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
      getClaims(webModulesSettings),
      SgViewTagsColorEnum,
      false,
      true,
      true,
      true
    );
    const extendedSgViewClaims = fillVisibilityClaimsColors(
      getClaims(extendedSgViewSettings),
      SgViewTagsColorEnum,
      extendedSgViewAccess
    );
    const extendedMarkViewClaims = fillVisibilityClaimsColors(
      getClaims(extendedMarkViewSettings),
      MarkViewTagsColorEnum,
      extendedMarkViewAccess
    );
    const asofViewClaims = fillVisibilityClaimsColors(
      getClaims(asofViewSettings),
      MarkViewTagsColorEnum,
      asofViewAccess
    );
    const facetViewClaims = fillVisibilityClaimsColors(
      getClaims(facetViewSettings),
      MarkViewTagsColorEnum,
      facetViewAccess
    );
    const dataQualityClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
      getClaims(dataQualitySettings),
      SgViewTagsColorEnum,
      false,
      true,
      true,
      true
    );
    const updatedWebModules: VisibilityClaim = {
      ...webModulesClaims[0],
      claims: getTagListForWebmodules(webModulesClaims[0].claims, dimensions),
    };

    const sgViewAxis = get(first(filter(domains, domain => domain.domainId === "sgview")), "domainValues");
    const markViewAxis = get(first(filter(domains, domain => domain.domainId === "markview")), "domainValues");

    const extendedSgViewAxis = get(
      first(filter(domains, domain => domain.domainId === "extendedsgview")),
      "domainValues"
    );
    const extendedMarkViewAxis = get(
      first(filter(domains, domain => domain.domainId === "extendedmarkview")),
      "domainValues"
    );

    const asofViewAxis = get(first(filter(domains, domain => domain.domainId === "asofview")), "domainValues");

    const clientContributionClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
      getClaims(clientContributionSettings),
      SgViewTagsColorEnum,
      false,
      true,
      true,
      true
    )

    return (
      <>
        <Loading isVisible={isFetching} />
        <div>
          <RequestModal
            title={"Reject Visibility Request"}
            show={showRejectRequestModal}
            size={"lg"}
            submitLabel={"Reject"}
            onCancel={this.closeRejectRequestModal}
            onClose={this.closeRejectRequestModal}
            onChange={this.handleOnRejectComment}
            backdropClose={false}
            onSubmit={this.handleOnReject}
            isSubmitValid={isRejectButtonEnabled}
            labelRequired={true}
            label="Add Comment"
          />

          <RequestModal
            title={"Validate Visibility Request"}
            show={showValidateRequestModal}
            size={"lg"}
            submitLabel={"Validate"}
            onCancel={this.closeValidateRequestModal}
            onClose={this.closeValidateRequestModal}
            onChange={this.handleOnValidateComment}
            backdropClose={false}
            onSubmit={this.handleOnValidateFromModal}
            isSubmitValid={isValidateButtonEnabled}
            labelRequired={true}
            label={defaultValidationComment}
          />

          <CloneModal
            title={"Clone Visibility"}
            show={showCloneRequestModal}
            size={"lg"}
            submitLabel={"Select "}
            onCancel={this.closeCloneRequestModal}
            onClose={this.closeCloneRequestModal}
            backdropClose={false}
            onSubmit={this.handleOnCloneFromModal}
            labelRequired={false}
          />
          <br />

          {visibilityType === VisibilitySettings.mainData && (
            <>
              <VisibilityTagList sgViewClaims={sgViewClaims} markViewClaims={markViewClaims} showTitle={false} />
              <KoreanDataSetting
                koreanDataFlag={koreanDataFlag}
                onKoreanDataFlagChange={this.handleKoreanDataFlagChange}
                koreanDataComment={koreanDataComment}
                onKoreanDataCommentChange={this.handleKoreanDataCommentChange}
              ></KoreanDataSetting>
              <Collapse
                id="sg_visiblity_setting"
                label="SG view settings"
                count={sgViewAccess ? size(sgViewSettings) : 0}
              >
                <div className="d-flex">
                  <Checkbox
                    id="sg_visibility_access"
                    checked={sgViewAccess}
                    label="Allow access"
                    onChange={this.handleSgViewNoAccessVisibilitySet}
                  />
                  <a
                    className="ml-2"
                    data-toggle="tooltip"
                    title="The Allow Access configuration will allow user to have access to all available C-profit Data in SG View"
                  >
                    <IconInfo size="sm" />
                  </a>
                </div>
                <VisibilitySetList
                  setsSettings={sgViewSettings}
                  axises={sgViewAxis}
                  allowAccess={sgViewAccess}
                  type={ViewSettingEnum.SG}
                  onClaimIdChange={this.handleClaimIdChange}
                  onClaimValueChange={this.handleClaimValuesChange}
                  onClaimOperatorChange={this.handleClaimOperatorChange}
                  onAddVisibilitySet={this.handleAddVisibilitySet}
                  onDeleteVisibilitySet={this.handleDeleteVisibilitySet}
                />
              </Collapse>
              <Collapse
                id="mark_visiblity_setting"
                label="Mark view settings"
                count={markViewAccess ? size(markViewSettings) : 0}
              >
                <div className="d-flex">
                  <Checkbox
                    id="mark_visibility_access"
                    checked={markViewAccess}
                    label="Allow access"
                    onChange={this.handleMarkViewNoAccessVisibilitySet}
                  />
                  <a
                    className="ml-2"
                    data-toggle="tooltip"
                    title="The Allow Access configuration will allow user to have access to all available C-profit Data in Mark View"
                  >
                    <IconInfo size="sm" />
                  </a>
                </div>
                <VisibilitySetList
                  setsSettings={markViewSettings}
                  axises={markViewAxis}
                  allowAccess={markViewAccess}
                  type={ViewSettingEnum.MARK}
                  onClaimIdChange={this.handleClaimIdChange}
                  onClaimValueChange={this.handleClaimValuesChange}
                  onClaimOperatorChange={this.handleClaimOperatorChange}
                  onAddVisibilitySet={this.handleAddVisibilitySet}
                  onDeleteVisibilitySet={this.handleDeleteVisibilitySet}
                />
              </Collapse>
            </>
          )}
          {visibilityType === VisibilitySettings.webModules && (
            <>
              <VisibilityTagList webModulesClaims={[updatedWebModules]} showTitle={false} />
              <Collapse
                id="webmodules_settings"
                label="WebModules settings"
                count={size(getActiveWebModules(webModulesSettings)) - 1} // The minus is applied to remove the claim Profile tag
              >
                <VisibilityWebModulesContainer
                  domainsConfig={domains}
                  onWebModuleSelectionChanged={this.handleWebModuleSelectionChange}
                  webModulesSettings={webModulesSettings}
                  onClaimValueChanged={this.handleWebModulesClaimValueChange}
                />
              </Collapse>
            </>
          )}
          {visibilityType === VisibilitySettings.extendedData && (
            <>
              <VisibilityTagList
                extendedSgViewClaims={extendedSgViewClaims}
                extendedMarkViewClaims={extendedMarkViewClaims}
                asOfViewClaims={asofViewClaims}
                facetViewClaims={facetViewClaims}
                showTitle={false}
              />
              <KoreanDataSetting
                koreanDataFlag={koreanDataFlag}
                onKoreanDataFlagChange={this.handleKoreanDataFlagChange}
                onKoreanDataCommentChange={this.handleKoreanDataCommentChange}
                koreanDataComment={koreanDataComment}
              ></KoreanDataSetting>
              <Collapse
                id="extended_sg_visiblity_setting"
                label="SG view settings"
                count={extendedSgViewAccess ? size(extendedSgViewSettings) : 0}
              >
                <div className="d-flex">
                  <Checkbox
                    id="extended_sg_visibility_access"
                    checked={extendedSgViewAccess}
                    label="Allow access"
                    onChange={this.handleExtendedSgViewNoAccessVisibilitySet}
                  />
                  <a
                    className="ml-2"
                    data-toggle="tooltip"
                    title="The Allow Access configuration will allow user to have access to all available C-profit Extended Data in SG View"
                  >
                    <IconInfo size="sm" />
                  </a>
                </div>
                <VisibilitySetList
                  setsSettings={extendedSgViewSettings}
                  axises={extendedSgViewAxis}
                  allowAccess={extendedSgViewAccess}
                  type={ViewSettingEnum.EXTENDED_SG}
                  onClaimIdChange={this.handleClaimIdChange}
                  onClaimValueChange={this.handleClaimValuesChange}
                  onClaimOperatorChange={this.handleClaimOperatorChange}
                  onAddVisibilitySet={this.handleAddVisibilitySet}
                  onDeleteVisibilitySet={this.handleDeleteVisibilitySet}
                />
              </Collapse>
              <Collapse
                id="extended_mark_visiblity_setting"
                label="Mark view settings"
                count={extendedMarkViewAccess ? size(extendedMarkViewSettings) : 0}
              >
                <div className="d-flex">
                  <Checkbox
                    id="extended_mark_visibility_access"
                    checked={extendedMarkViewAccess}
                    label="Allow access"
                    onChange={this.handleExtendedMarkViewNoAccessVisibilitySet}
                  />
                  <a
                    className="ml-2"
                    data-toggle="tooltip"
                    title="The Allow Access configuration will allow user to have access to all available C-profit Extended Data in Mark View"
                  >
                    <IconInfo size="sm" />
                  </a>
                </div>
                <VisibilitySetList
                  setsSettings={extendedMarkViewSettings}
                  axises={extendedMarkViewAxis}
                  allowAccess={extendedMarkViewAccess}
                  type={ViewSettingEnum.EXTENDED_MARK}
                  onClaimIdChange={this.handleClaimIdChange}
                  onClaimValueChange={this.handleClaimValuesChange}
                  onClaimOperatorChange={this.handleClaimOperatorChange}
                  onAddVisibilitySet={this.handleAddVisibilitySet}
                  onDeleteVisibilitySet={this.handleDeleteVisibilitySet}
                />
              </Collapse>
              <Collapse
                id="asof_visiblity_setting"
                label="As of view settings"
                count={asofViewAccess ? size(asofViewSettings) : 0}
              >
                <Checkbox
                  id="asof_visibility_access"
                  checked={asofViewAccess}
                  label="Allow access"
                  onChange={this.handleAsofViewNoAccessVisibilitySet}
                />
                <VisibilitySetList
                  setsSettings={asofViewSettings}
                  axises={asofViewAxis}
                  allowAccess={asofViewAccess}
                  type={ViewSettingEnum.ASOF}
                  onClaimIdChange={this.handleClaimIdChange}
                  onClaimValueChange={this.handleClaimValuesChange}
                  onClaimOperatorChange={this.handleClaimOperatorChange}
                  onAddVisibilitySet={this.handleAddVisibilitySet}
                  onDeleteVisibilitySet={this.handleDeleteVisibilitySet}
                />
              </Collapse>
              <Collapse
                id="facet_visiblity_setting"
                label="Facet view settings"
                count={facetViewAccess ? size(facetViewSettings) : 0}
              >
                <Checkbox
                  id="facet_visibility_access"
                  checked={facetViewAccess}
                  label="Allow access"
                  onChange={this.handleFacetViewNoAccessVisibilitySet}
                />
              </Collapse>
            </>
          )}
          {visibilityType === VisibilitySettings.dataQuality && (
            <>
              <VisibilityTagList dataQualityClaims={dataQualityClaims} showTitle={false} />
              <Collapse id="dataquality_settings" label="Data quality settings" count={size(dataQualitySettings)}>
                <VisibilityDataQualityContainer
                  domainsConfig={domains}
                  dataQualitySettings={dataQualitySettings}
                  onClaimValueChanged={this.handleDataQualityClaimValueChange}
                />
              </Collapse>
            </>
          )}
          {visibilityType === VisibilitySettings.clientcontribution && (
            <>
              <VisibilityTagList clientContributionClaims={clientContributionClaims} showTitle={false} />
              <Collapse id="clientcontribution_settings" label="Client Contribution Settings" count={size(clientContributionSettings)}>
                <VisibilityClientContributionContainer
                  domainsConfig={domains}
                  clientContributionSettings={clientContributionSettings}  
                  onClaimValueChanged={this.handleClientContributionClaimValueChange}
                />
              </Collapse>
            </>
          )}
          <FormActions
            onSubmit={this.handleOnSubmit}
            onClone={this.openCloneRequestModal}
            submitLabel="Save"
            validateLabel="Validate"
            cloneLabel="Clone"
            onValidate={this.handleOnValidate}
            onReject={this.openRejectRequestModal}
            rejectLabel="Reject"
          />
        </div>
      </>
    );
  }

  private handleOnRejectComment = (comment: string) => {
    this.setState({ isRejectButtonEnabled: comment.length > 0 });
    this.setState({ requestComment: comment });
  };

  private handleOnValidateComment = (comment: string) => {
    this.setState({ isValidateButtonEnabled: comment.length > 0 });
    this.setState({ requestComment: comment });
  };

  private handleOnValidate = () => {
    const { validateRequest, requestId } = this.props;
    const {
      sgViewSettings,
      markViewSettings,
      webModulesSettings,
      markViewAccess,
      sgViewAccess,
      extendedMarkViewAccess,
      extendedMarkViewSettings,
      extendedSgViewAccess,
      extendedSgViewSettings,
      asofViewAccess,
      asofViewSettings,
      facetViewAccess,
      facetViewSettings,
      dataQualitySettings,
      koreanDataFlag,
      koreanDataComment,
      clientContributionSettings
    } = this.state;
    const fullSgViewSettings = getSetSettings(sgViewSettings, sgViewAccess, DomainEnum.SGVIEW);
    const fullMarkViewSettings = getSetSettings(markViewSettings, markViewAccess, DomainEnum.MARKVIEW);
    const fullExtendedSgViewSettings = getSetSettings(
      extendedSgViewSettings,
      extendedSgViewAccess,
      DomainEnum.EXTENDEDSGVIEW
    );
    const fullExtendedMarkViewSettings = getSetSettings(
      extendedMarkViewSettings,
      extendedMarkViewAccess,
      DomainEnum.EXTENDEDMARKVIEW
    );
    const fullAsofViewSettings = getSetSettings(asofViewSettings, asofViewAccess, DomainEnum.ASOFVIEW);
    const fullFacetViewSettings = getSetSettings(facetViewSettings, facetViewAccess, DomainEnum.FACETVIEW);

    validateRequest(
      [
        ...fullSgViewSettings,
        ...fullMarkViewSettings,
        ...webModulesSettings,
        ...fullExtendedSgViewSettings,
        ...fullExtendedMarkViewSettings,
        ...fullAsofViewSettings,
        ...fullFacetViewSettings,
        ...dataQualitySettings,
        ...clientContributionSettings
      ],
      requestId,
      koreanDataFlag,
      koreanDataComment,
    );
    this.setState({ showValidateRequestModal: true, isShowValidateModalHandled: true });
  };

  private handleOnSubmit = () => {
    const { submitRequest, requestId } = this.props;
    const {
      sgViewSettings,
      markViewSettings,
      webModulesSettings,
      markViewAccess,
      sgViewAccess,
      extendedMarkViewAccess,
      extendedMarkViewSettings,
      extendedSgViewAccess,
      extendedSgViewSettings,
      asofViewAccess,
      asofViewSettings,
      facetViewAccess,
      facetViewSettings,
      dataQualitySettings,
      koreanDataFlag,
      koreanDataComment,
      clientContributionSettings
    } = this.state;
    const fullSgViewSettings = getSetSettings(sgViewSettings, sgViewAccess, DomainEnum.SGVIEW);
    const fullMarkViewSettings = getSetSettings(markViewSettings, markViewAccess, DomainEnum.MARKVIEW);
    const fullExtendedSgViewSettings = getSetSettings(
      extendedSgViewSettings,
      extendedSgViewAccess,
      DomainEnum.EXTENDEDSGVIEW
    );
    const fullExtendedMarkViewSettings = getSetSettings(
      extendedMarkViewSettings,
      extendedMarkViewAccess,
      DomainEnum.EXTENDEDMARKVIEW
    );
    const fullAsofViewSettings = getSetSettings(asofViewSettings, asofViewAccess, DomainEnum.ASOFVIEW);
    const fullFacetViewSettings = getSetSettings(facetViewSettings, facetViewAccess, DomainEnum.FACETVIEW);
    submitRequest(
      [
        ...fullSgViewSettings,
        ...fullMarkViewSettings,
        ...webModulesSettings,
        ...fullExtendedSgViewSettings,
        ...fullExtendedMarkViewSettings,
        ...fullAsofViewSettings,
        ...fullFacetViewSettings,
        ...dataQualitySettings,
        ...clientContributionSettings
      ],
      requestId,
      koreanDataFlag,
      koreanDataComment
    );
  };

  private handleOnReject = () => {
    const { rejectRequest, requestId } = this.props;
    const { requestComment } = this.state;
    rejectRequest(requestId, requestComment);
    this.setState({ showRejectRequestModal: false });
  };

  private handleOnValidateFromModal = () => {
    const { validateRequestFromModal, requestId } = this.props;
    const { requestComment } = this.state;
    validateRequestFromModal(requestId, isEmpty(requestComment) ? defaultValidationComment : requestComment);
    this.setState({ showValidateRequestModal: false });
  };

  private readonly handleClaimIdChange = (
    { axisLabel, setId, viewSettingType }: SetIds,
    claimId: string,
    claimLabel: string,
    operator: string
  ) => {
    this.setState(state => updateClaimId(state, { axisLabel, viewSettingType, setId }, claimId, claimLabel, operator));
  };

  private readonly handleClaimValuesChange = ({ axisLabel, setId, viewSettingType }: SetIds, claimValues: string[]) => {
    this.setState(state => updateClaimValues(state, { axisLabel, setId, viewSettingType }, claimValues));
  };
  
  private readonly handleClaimOperatorChange = ({ axisLabel, setId, viewSettingType }: SetIds, operator: string) => {
    this.setState(state => updateClaimOperator(state, { axisLabel, setId, viewSettingType }, operator));
  };

  private handleAddVisibilitySet = (viewSettingType: ViewSettingEnum) => {
    this.setState(state => addVisibilitySet(state, viewSettingType));
  };

  private handleDeleteVisibilitySet = (viewSettingType: ViewSettingEnum) => {
    this.setState(state => deleteVisibilitySet(state, viewSettingType));
  };

  private handleSgViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ sgViewAccess: checked });
    this.setState({ sgViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private handleMarkViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ markViewAccess: checked });
    this.setState({ markViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private handleExtendedSgViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ extendedSgViewAccess: checked });
    this.setState({ extendedSgViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private handleExtendedMarkViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ extendedMarkViewAccess: checked });
    this.setState({ extendedMarkViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private handleAsofViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ asofViewAccess: checked });
    this.setState({ asofViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private handleFacetViewNoAccessVisibilitySet = (checked: boolean) => {
    this.setState({ facetViewAccess: checked });
    this.setState({ facetViewSettings: [newVisibilitySet(SetIdEnum.One)] });
  };

  private closeRejectRequestModal = () => {
    this.setState({ showRejectRequestModal: false });
  };

  private openRejectRequestModal = () => {
    this.setState({ showRejectRequestModal: true });
    this.setState({ requestComment: `Rejected by ${this.props.connectedUser}` });
  };

  private closeValidateRequestModal = () => {
    this.setState({ showValidateRequestModal: false, isShowValidateModalHandled: false });
  };

  private closeCloneRequestModal = () => {
    this.setState({ showCloneRequestModal: false });
  };

  private openCloneRequestModal = () => {
    this.setState({ showCloneRequestModal: true });
    this.setState({ isLoaded: false });
  };

  private readonly handleOnCloneFromModal = (contactId: string) => {
    const { cloneRequest, visibility } = this.props;
    this.setState({ showCloneRequestModal: false });
    cloneRequest({ contactId, applicationId: visibility.applicationId });
  };

  private readonly handleWebModuleSelectionChange = (domainId: DomainEnum, checked: boolean) => {
    this.setState(state => updateEnabledWebModules(state, domainId, checked));
  };

  private readonly handleWebModulesClaimValueChange = (domainId: DomainEnum, claimId: string, values: string[]) => {
    const { domains } = this.props;
    this.setState(state => updateWebModulesClaimValues(state, domains, domainId, claimId, values));
  };

  private readonly handleDataQualityClaimValueChange = (domainId: DomainEnum, claimId: string, values: string[]) => {
    const { domains } = this.props;
    this.setState(state => updateDataQualityClaimValues(state, domains, domainId, claimId, values));
  };

  private readonly handleKoreanDataFlagChange = (flag: string) => {
    let flagBool = null;
    switch (flag) {
      case "true":
        flagBool = true;
        break;
      case "false":
        flagBool = false;
        break;
    }
    this.setState(state => updateKoreanDataFlag(state, flagBool));
  };

  private readonly handleKoreanDataCommentChange = (comment: string) => {
    this.setState({ koreanDataComment: comment });
  };

  private readonly handleClientContributionClaimValueChange = (domainId: DomainEnum, claimId: string, values: string[]) => {
    const { domains } = this.props;
    this.setState(state => updateClientContributionClaimValues(state, domains, domainId, claimId, values));
  };
}
