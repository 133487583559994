import { NavLink, NavLinkProps } from "react-router-dom";
import * as React from "react";

export interface Props extends NavLinkProps {
  containerClass?: string;
}

export const NavItem: React.FunctionComponent<Props> = ({ to, title, exact, containerClass }) => (
  <li className={containerClass ? containerClass : "nav-item"}>
    <NavLink exact={exact} to={to} className="nav-link" activeClassName="active">
      {title}
    </NavLink>
  </li>
);
