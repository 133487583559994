export interface UserProfile {
  id: string;
  userId?: string;
  userEmail: string;
  canImpersonate: boolean;
  organizationTeamId: string;
  name?: string;
  fullName: string;
  countryIso3?: string;
  userRight: ConnectedUserProfileRights;
}

export interface ConnectedUserProfileRights {
  canCreateRequest: boolean;
  canValidateCprofitRequest: boolean;
  canAccessCoverage: boolean;
}

export interface Applications {
  hasAccessToCprofit?: boolean;
}

export const DefaultUserProfile: UserProfile = {
  id: "",
  userEmail: "",
  canImpersonate: false,
  organizationTeamId: "",
  fullName: "",
  countryIso3: "",
  userRight: {
    canCreateRequest: false,
    canValidateCprofitRequest: false,
    canAccessCoverage: false,
  },
};
