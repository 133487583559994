import * as React from "react";
import { ComponentColor } from "../components.typings";
import { map, capitalize, includes, filter } from "lodash";
import { Badge } from "../Badge/Badge";
import * as Styles from "./TagList.scss";
import { Claim } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { DomainEnum } from "api/request/request.typings";

export interface TagListProps {
  claim: Claim;
  type: ComponentColor;
  outline?: boolean;
  prependSquare?: boolean;
  className?: string;
  children?: React.ReactNode;
  domainId: string;
  displayOperator: boolean;
}

export const TagList: React.FunctionComponent<TagListProps> = ({
  claim,
  type,
  prependSquare,
  className,
  children,
  domainId,
  displayOperator
}: TagListProps) => {

  const getOperatorSymbol = () => {
    const labelsWithoutOperator = ["No access", "Coverage", "Allow access"]
    if (labelsWithoutOperator.findIndex(l => claim.label === l) !== -1){
      return "";
    }
    switch (claim.operator) {
      case "equal":
        return "=";
      case "notequal":
        return "≠";
      default:
        return "";
    }
  };

  return (
    <>
      <Badge
        value={
          includes(
            filter(
              DomainEnum,
              domain =>
                domain !== DomainEnum.MARKVIEW &&
                domain !== DomainEnum.SGVIEW &&
                domain !== DomainEnum.WEBMODULES &&
                domain !== DomainEnum.FACETVIEW &&
                domain !== DomainEnum.EXTENDEDMARKVIEW &&
                domain !== DomainEnum.EXTENDEDSGVIEW &&
                domain !== DomainEnum.ASOFVIEW &&
                domain !== DomainEnum.DATAQUALITY &&
                domain !== DomainEnum.CLIENTCONTRIBUTION
            ),
            domainId
          ) && claim.id === "allowaccess"
            ? capitalize(domainId)
            : claim.label
        }
        type={type}
        outline={false}
        prependSquare={prependSquare}
        className={`${className} ${Styles.SetMargin}`}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
      {displayOperator ?
        <Badge
          value={getOperatorSymbol()}
          type={type}
          outline={false}
          className={`${className} ${Styles.SetMargin}`}
          // eslint-disable-next-line react/no-children-prop
          children={children}
        />
        : <></>}
      {map(claim.values, (value, index) => (
        <Badge
          key={index}
          value={value}
          type={type}
          outline={true}
          prependSquare={prependSquare}
          className={`${className} ${Styles.SetMargin}`}
          // eslint-disable-next-line react/no-children-prop
          children={children}
        />
      ))}
    </>
  );
};
