import * as React from "react";
import { UserRequestDetail } from "containers/request/request.typings";
import { Loading } from "components/common/Loading/Loading";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { SectionTitle } from "components/common/Title/SectionTitle";
import { map } from "lodash";
import { UserRequestsRow } from "./UserRequestsRow";

export interface Props {
  userRight: ConnectedUserProfileRights;
  requests: UserRequestDetail[];
  isFetching: boolean;
  title: string;
}

const Header: React.FunctionComponent = () => (
  <thead>
    <tr>
      <th>Status</th>
      <th>Create Date</th>
      <th>Latest Update</th>
      <th>Modified by</th>
      <th>End Date</th>
      <th>Validator</th>
      <th />
    </tr>
  </thead>
);

const Body: React.FunctionComponent<Pick<Props, "requests" | "userRight">> = ({ requests, userRight }) => (
  <tbody>
    {!requests ? (
      <tr>
        <td colSpan={5}>no request found</td>
      </tr>
    ) : (
      map(requests, request => <UserRequestsRow request={request} right={userRight} />)
    )}
  </tbody>
);

export const UserRequestList: React.FunctionComponent<Props> = ({ requests, isFetching, title, userRight }) => (
  <>
    <SectionTitle size={"5"} title={title} />
    <Loading isVisible={isFetching} />
    <table className="table table-responsive-md pb-5">
      <Header />
      <Body requests={requests} userRight={userRight} />
    </table>
  </>
);
