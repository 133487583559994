import * as React from "react";
import { isEmpty, map, isEqual, find } from "lodash";
import { IdAndLabel } from "common/common.typings";
export interface Props<T, V> {
  selectedId?: V;
  options: T[];
  onSelectionChange?: (selection: V) => void;
}

interface State<T, V> {
  selectedId?: V;
}

export class Select<T extends IdAndLabel<V>, V> extends React.Component<Props<T, V>, State<T, V>> {
  public constructor(props: Props<T, V>) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = { selectedId: this.props.selectedId };
  }

  public componentDidMount() {
    if (this.props.selectedId) {
      this.props.onSelectionChange(this.props.selectedId);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props<T, V>>): void {
    if (!isEqual(prevProps.selectedId, this.props.selectedId) || !isEqual(prevProps.options, this.props.options)) {
      const selectedId = this.props.selectedId;
      const existingItem = find(this.props.options, option => option.id === selectedId);
      if (!selectedId) {
        this.setState({ selectedId });
      } else if (existingItem) {
        this.setState({ selectedId }, () => this.props.onSelectionChange(selectedId));
      }
    }
  }

  public render() {
    const { options } = this.props;
    const { selectedId } = this.state;

    return !isEmpty(options) ? (
      <select
        data-testid="select"
        className="form-control form-control-lg"
        onChange={this.handleOnChange}
        value={selectedId ? selectedId.toString() : ""}
      >
        {map(options, ({ id, label }) => (
          <option key={id.toString()} value={id.toString()}>
            {label}
          </option>
        ))}
      </select>
    ) : null;
  }

  private handleOnChange(event: any) {
    event.preventDefault();
    const value = event.target.value;
    this.setState({ selectedId: value });
    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(value);
    }
  }
}
