import { UserRequestDetail } from "containers/request/request.typings";
import * as React from "react";
import { ReadOnlyField } from "components/common/ReadOnlyField/ReadOnlyField";
import { RequestStatus } from "components/common/RequestStatus/RequestStatus";
import { SectionTitle } from "components/common/Title/SectionTitle";
import TextArea from "components/common/TextArea/TextArea";
import { StatusEnum } from "containers/referential/referential.typings";
import { noop } from "lodash";

interface Props {
  request: UserRequestDetail;
}

export const RequestDetail: React.FunctionComponent<Props> = ({ request }: Props) => {
  const {
    status,
    userComment,
    startDate,
    endDate,
    validator,
    validatorComment,
    createdDate,
    modifiedUser,
    modifiedDate,
    impulseId,
    cerberusRole,
    validators,
    requestor
  } = request;
  return (
    <div className="row my-4">
      <div className={"col"}>
        <SectionTitle size={"4"} title={"Request information"} />
        <div className={"row mt-2"}>
          <div className="col">
            <RequestStatus status={status} />
          </div>
        </div>
        <div className={"row"}>
          <ReadOnlyField id={"impulseId"} label={"Impulse ticket number"} value={impulseId} className={"col-4"} />
          <ReadOnlyField
            id={"cerberusRole"}
            label={"Requested Cerberus role"}
            value={cerberusRole}
            className={"col-4"}
          />
          <ReadOnlyField id={"endDate"} label={"End Date"} value={endDate || "-"} className={"col-4"} />
        </div>
        <div className={"row"}>
          <TextArea
            id="comment"
            label="Comment"
            labelRequired={false}
            value={userComment || "none"}
            containerClassName="col-12 col-md-12 col-xl-12"
            labelClassName="mb-0"
            className="form-control-xl"
            onChange={noop}
            readonly={true}
            rows={userComment && userComment.length > 40 ? 3 : 1}
          />
        </div>
        <div className={"row"}>
          <div className={"col"}>
            <div className={"row"}>
              <ReadOnlyField id={"createdBy"} label={"Created by"} value={requestor && requestor.fullName} className={"col-6"} />
              <ReadOnlyField id={"createdDate"} label={"Creation date"} value={createdDate} className={"col-6"} />
            </div>
            <div className={"row"}>
              <ReadOnlyField
                id={"modifiedUser"}
                label={"Modified by"}
                value={(modifiedUser && modifiedUser.fullName) || "-"}
                className={"col-6 col-md-6"}
              />
              <ReadOnlyField
                id={"lastModificationDate"}
                label={"Latest Update"}
                value={modifiedDate || "-"}
                className={"col-6"}
              />
            </div>
            <div className={"row"}>
              <ReadOnlyField
                id={"validator"}
                label={"Validator"}
                value={(validator && validator.fullName) || "-"}
                className={"col-6"}
              />
              <ReadOnlyField id={"startDate"} label={"Validation date"} value={startDate} className={"col-6"} />
            </div>
          </div>
        </div>
        <div className={"row mt-0"}>
          <ReadOnlyField
            id={"validatorComment"}
            label={"Validator comment"}
            value={validatorComment || "none"}
            className={"col-12"}
          />
          {status.id === StatusEnum.PENDING && (
            <ReadOnlyField
              id={"validators"}
              label={"Assigned to"}
              value={validators && validators.length > 0 ? validators.join("; ") : ""}
              className={"col-12"}
            />
          )}
        </div>
      </div>
    </div>
  );
};
