import * as React from "react";
import { BlankCanvas } from "components/common/BlankCanvas/BlankCanvas";
import { ROUTES } from "common/routes";

export interface Props {
  visible: boolean;
  title?: string;
  description?: string;
}

export const ErrorCanvas: React.FunctionComponent<Props> = ({ visible, title, description }) => {
  return visible ? (
    <BlankCanvas iconName={"error"} title={title} description={description}>
      <div className="my-5">
        <a className={"btn btn-discreet-primary btn-xl"} href={ROUTES.HOME}>
          Home page
        </a>
      </div>
    </BlankCanvas>
  ) : null;
};
