import * as React from "react";
import { ContactsPicker, WidgetContactDTO } from "components/Contact/ContactPicker/ContactPicker";
import { ContactViewerContainer } from "containers/contact/contact.container";
import { UserRequestCreate } from "containers/request/request.typings";
import { PageHeader } from "components/common/PageHeader/PageHeader";
import { BlankCanvas } from "components/common/BlankCanvas/BlankCanvas";
import { ApplicationSelectContainer } from "containers/referential/application/application.container";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { RoleSelectContainer } from "containers/referential/role/role.container";
import { ApplicationEnum, RoleEnum } from "containers/referential/referential.typings";
import { get } from "lodash";

export interface StateProps {
  userEmail: string;
  isFetching: boolean;
  actualRole?: string;
  isCprofitNewUser?: boolean;
}

export interface DispatchProps {
  onCreateRequest: (request: UserRequestCreate) => void;
}
export interface LocalState {
  contactInfo?: WidgetContactDTO;
  applicationId: ApplicationEnum;
  cerberusRoleId: RoleEnum;
  popsComment: string;
  impulseId?: string;
  validationClass: string;
  disabledButton: boolean;
}
export type Props = StateProps & DispatchProps;

export class RequestCreatePage extends React.Component<Props, LocalState> {
  constructor(props) {
    super(props);
    this.state = {
      contactInfo: { detail: { contact: null } },
      applicationId: ApplicationEnum.C_PROFIT,
      popsComment: null,
      cerberusRoleId: RoleEnum.USER,
      impulseId: null,
      validationClass: "",
      disabledButton: false,
    };
  }

  public render() {
    const { contactInfo } = this.state;
    const { actualRole } = this.props;
    const contact = get(contactInfo, "detail.contact");

    return (
      <>
        <PageHeader title={"Add request"} isBackButtonShown={true} />
        <ContactsPicker onChange={this.handleOnContactChange} />
        {!contact ? (
          <BlankCanvas
            iconName={"account_circle"}
            title={"Please select a client"}
            description={"The user information will be displayed once you select client on contact picker"}
          />
        ) : (
          <>
            <ContactViewerContainer contactId={contact.id} />

            <FormField id={"impulseId"} label={"Impulse ticket number"}>
              <input
                className={`form-control ${this.state.validationClass}`}
                onChange={this.handleOnImpulseChange}
                required={true}
              />
              <div className="invalid-feedback ">Please provide an impulse number</div>
            </FormField>
            <FormField id={"pops-comment"} label={"Pops comment"}>
              <textarea
                className={"form-control"}
                rows={3}
                placeholder={"pops Comment"}
                onChange={this.handleOnCommentChange}
              />
            </FormField>
            <FormField id={"application"} label={"Application"} className="d-none">
              <ApplicationSelectContainer onSelectionChange={this.handleOnApplicationChange} />
            </FormField>
            <FormField id={"roles"} label={"Roles"}>
              <RoleSelectContainer onSelectionChange={this.handleOnRoleChange} selectedId={actualRole as RoleEnum} />
            </FormField>
            <div className={"pull-bottom"}>
              <button
                type="button"
                className={"btn btn-lg btn-primary"}
                disabled={this.state.disabledButton}
                onClick={this.handleCreateRequest}
              >
                Create
              </button>
            </div>
          </>
        )}
      </>
    );
  }

  private handleOnContactChange = (contactInfo: WidgetContactDTO) => {
    this.setState({ contactInfo });
    this.setState({ validationClass: "" });
    this.setState({ disabledButton: false });
  };

  private handleOnApplicationChange = (applicationId: ApplicationEnum) => {
    this.setState({ applicationId });
  };

  private handleOnRoleChange = (cerberusRoleId: RoleEnum) => {
    this.setState({ cerberusRoleId });
  };

  private handleOnCommentChange = event => {
    const value = event.target.value;
    this.setState({ popsComment: value });
  };

  private handleOnImpulseChange = event => {
    this.setState({ impulseId: event.target.value });
    this.setState({ validationClass: "" });
    this.setState({ disabledButton: false });
  };

  private handleCreateRequest = () => {
    const { contactInfo, applicationId, cerberusRoleId, popsComment, impulseId } = this.state;
    const contact = get(contactInfo, "detail.contact");

    if (this.props.isCprofitNewUser === true && !this.state.impulseId) {
      this.setState({ validationClass: "is-invalid" });
      return;
    }
    this.setState({ disabledButton: true });
    this.props.onCreateRequest({
      applicationId,
      cerberusRoleId,
      popsComment,
      contactId: contact.id,
      impulseId,
    });
  };
}
