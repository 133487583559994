import actionCreatorFactory from "typescript-fsa";
import { Application, Role, Status } from "containers/referential/referential.typings";

const actionCreator = actionCreatorFactory("referential");

export enum ReferentialActionName {
  FETCH_APPLICATIONS = "FETCH_APPLICATIONS",
  FETCH_STATUSES = "FETCH_STATUSES",
  FETCH_ROLES = "FETCH_ROLES",
}

export const fetchApplicationsAction = actionCreator.async<void, Application[], string>(
  ReferentialActionName.FETCH_APPLICATIONS
);

export const fetchStatusesAction = actionCreator.async<void, Status[], string>(ReferentialActionName.FETCH_STATUSES);
export const fetchRolesAction = actionCreator.async<void, Role[], string>(ReferentialActionName.FETCH_ROLES);
