import * as React from "react";

interface Props {
  iconName: string;
  title: string;
  description?: string;
  children?: React.ReactNode;
}

export const BlankCanvas: React.FunctionComponent<Props> = ({
  iconName,
  title,
  description,
  children = null,
}: Props) => {
  return (
    <div className="row">
      <div className="col my-3 text-center text-secondary">
        <i className="icon icon-xl d-inline-block mb-2">{iconName}</i>
        <div className="display-4">{title}</div>
        {description && <p>{description}</p>}
        {children}
      </div>
    </div>
  );
};
