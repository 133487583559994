import { ActionsObservable, Epic } from "redux-observable";
import { catchError, filter, switchMap, map } from "rxjs/operators";
import { fetchUserInfo } from "containers/userProfile/userProfile.action";
import { of } from "rxjs";
import { requestMe } from "api/contacts/contact.api";
import { GlobalState } from "store/store.typings";
import { Action } from "redux";
import { fetchUserById } from "api/user/user.api";
import { mapToUserProfile } from "containers/userProfile/userProfile.map";

export const getUserProfileEpic: Epic<Action, Action, GlobalState> = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchUserInfo.started.match),
    switchMap(({ payload, meta }) =>
      requestMe(payload, meta.headers).pipe(
        switchMap(userProfile =>
          fetchUserById(userProfile.userId, meta.headers).pipe(
            map(user => fetchUserInfo.done({ result: mapToUserProfile(userProfile, user), params: payload }))
          )
        ),
        catchError(({ message }) => of(fetchUserInfo.failed({ error: message, params: payload })))
      )
    )
  );

export const userProfileEpic: Epic<Action> = getUserProfileEpic; // use combineEpics if there's more than one epics
