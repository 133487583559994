import * as React from "react";

interface Props {
  id: string;
  label: string;
  value?: string;
  className?: string;
}

export const ReadOnlyField: React.FunctionComponent<Props> = ({ id, label, value, className = "" }: Props) => (
  <div className={`form-group ${className}`}>
    <label className="text-secondary mb-0" htmlFor={id}>
      {label}
    </label>
    <input className="form-control form-control-xl" id={id} readOnly={true} type="text" value={value || ""} />
  </div>
);
