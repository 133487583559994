import { Application, Role, Status } from "containers/referential/referential.typings";
import { Action, Reducer } from "redux";
import { isType } from "typescript-fsa";
import {
  fetchApplicationsAction,
  fetchRolesAction,
  fetchStatusesAction,
} from "containers/referential/referential.action";
import reduceReducer from "reduce-reducers";

export interface ReferentialState {
  applications: Application[];
  statuses: Status[];
  roles: Role[];
}

export const initialState: ReferentialState = {
  applications: [],
  statuses: [],
  roles: [],
};

export const applicationsReducer: Reducer<ReferentialState, Action> = (
  state: ReferentialState = initialState,
  action: Action
): ReferentialState => {
  if (isType(action, fetchApplicationsAction.done)) {
    return {
      ...state,
      applications: action.payload.result,
    };
  }
  if (isType(action, fetchApplicationsAction.failed)) {
    return {
      ...state,
      applications: [],
    };
  }
  return state;
};

export const statusesReducer: Reducer<ReferentialState, Action> = (
  state: ReferentialState = initialState,
  action: Action
): ReferentialState => {
  if (isType(action, fetchStatusesAction.done)) {
    return {
      ...state,
      statuses: action.payload.result,
    };
  }
  if (isType(action, fetchApplicationsAction.failed)) {
    return {
      ...state,
      statuses: [],
    };
  }
  return state;
};

export const rolesReducer: Reducer<ReferentialState, Action> = (
  state: ReferentialState = initialState,
  action: Action
): ReferentialState => {
  if (isType(action, fetchRolesAction.done)) {
    return {
      ...state,
      roles: action.payload.result,
    };
  }
  if (isType(action, fetchRolesAction.failed)) {
    return {
      ...state,
      roles: [],
    };
  }
  return state;
};

export const referentialReducer: Reducer<ReferentialState, Action> = reduceReducer<ReferentialState>(
  applicationsReducer,
  statusesReducer,
  rolesReducer
);
