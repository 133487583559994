import * as React from "react";
import { Modal } from "components/common/Modal/Modal";
import { ComponentSize } from "components/common/components.typings";
import { ContactsPicker, WidgetContactDTO } from "components/Contact/ContactPicker/ContactPicker";
import { BlankCanvas } from "components/common/BlankCanvas/BlankCanvas";
import { CloneContactViewContainer } from "containers/request/validate/requestVisibility/request.clone.visibility.container";
import { get } from "lodash";

export interface Props {
  size?: ComponentSize;
  title: string;
  show?: boolean;
  submitLabel?: string;
  backdropClose?: boolean;
  placeholder?: string;
  isSubmitValid?: boolean;
  labelRequired: boolean;
  userEmail?: string;
  onCancel?: () => void;
  onClose: () => void;
  onSuccess?: () => void;
  onChange?: (comment: string) => void;
  onSubmit?: (contactId: string) => void;
}

export const CloneModal: React.FunctionComponent<Props> = ({
  onClose,
  size,
  show,
  submitLabel,
  backdropClose,
  title,
  userEmail,
  onSubmit,
}: Props) => {
  const initialState = {
    detail: { contact: null },
  };
  const [contactInfo, setContactInfo] = React.useState<WidgetContactDTO>(initialState);
  const handleOnContactChange = (widgetContact: WidgetContactDTO) => setContactInfo(widgetContact);
  const contact = get(contactInfo, "detail.contact");

  const handleOnSubmit = () => {
    onSubmit(contact.id);
    setContactInfo(null);
  };

  const handleOnClose = () => {
    onClose();
    setContactInfo(null);
  };

  return (
    <Modal
      title={title}
      show={show}
      size={size}
      submitLabel={submitLabel}
      onCancel={handleOnClose}
      onClose={handleOnClose}
      backdropClose={backdropClose}
      onSubmit={handleOnSubmit}
      isSubmitValid={!!contact}
    >
      <ContactsPicker onChange={handleOnContactChange} />
      <br />
      <br />
      {!contact ? (
        <BlankCanvas
          iconName={"account_circle"}
          title={"Please select a client"}
          description={"The user information will be displayed once you select client on contact picker"}
        />
      ) : (
        <>
          <CloneContactViewContainer contactId={contact.id} />
        </>
      )}
    </Modal>
  );
};
