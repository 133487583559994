import * as React from "react";
import { NavItem } from "components/common/Navigation/NavItem";
import { NavPills } from "components/Navigation/NavPills";
import { ROUTES } from "common/routes";
import { generateUri } from "utils/uris/generateUri";
import { VisibilitySettings } from "containers/request/validate/requestVisibility/request.visibility.typings";

export interface Props {
  className?: string;
  requestId: string;
  canValidate: boolean;
}

export const VisibilityNavigator: React.FunctionComponent<Props> = ({
  requestId,
  canValidate,
  className = "col",
}: Props) => (
  <div className={className}>
    <NavPills className={"nav nav-pills border-bottom"}>
      <NavItem exact={true} to={generateUri(ROUTES.REQUEST_DETAIL_USER_INFO, requestId)} title="Request Info" />
      <NavItem
        hidden={!canValidate}
        exact={true}
        to={generateUri(ROUTES.REQUEST_DETAIL_MAIN_VISIBILITY, requestId, VisibilitySettings.mainData)}
        title="Main data settings"
      />
      <NavItem
        hidden={!canValidate}
        exact={true}
        to={generateUri(ROUTES.REQUEST_DETAIL_WEBMODULES_VISIBILITY, requestId, VisibilitySettings.webModules)}
        title="Webmodules settings"
      />
      <NavItem
        hidden={!canValidate}
        exact={true}
        to={generateUri(ROUTES.REQUEST_DETAIL_EXTENDED_VISIBILITY, requestId, VisibilitySettings.extendedData)}
        title="Extended data settings"
      />
      <NavItem
        hidden={!canValidate}
        exact={true}
        to={generateUri(ROUTES.REQUEST_DETAIL_DATAQUALITY_VISIBILITY, requestId, VisibilitySettings.dataQuality)}
        title="Data Quality settings"
      />
      <NavItem
        hidden={!canValidate}
        exact={true}
        to={generateUri(ROUTES.REQUEST_DETAIL_CLIENTCONTRIBUTION_VISIBILITY, requestId, VisibilitySettings.clientcontribution)}
        title="Client Contribution settings"
      />
    </NavPills>
  </div>
);
