import * as React from "react";
import { ComponentSize } from "components/common/components.typings";
import { Modal } from "components/common/Modal/Modal";
import { IdAndLabel } from "common/common.typings";
import { Select } from "components/Select/Select";
import { Application } from "containers/referential/referential.typings";

export interface Props {
  size?: ComponentSize;
  title: string;
  show?: boolean;
  submitLabel?: string;
  backdropClose?: boolean;
  placeholder?: string;
  isSubmitValid?: boolean;
  labelRequired: boolean;
  label?: string;
  options: Application[];
  onCancel?: () => void;
  onClose: () => void;
  onSuccess?: () => void;
  onChange?: (id: string) => void;
  onSubmit?: () => void;
}

export const TerminateAccessModal: React.FunctionComponent<Props> = ({
  onClose,
  onChange,
  size,
  show,
  submitLabel,
  backdropClose,
  title,
  onSubmit,
  isSubmitValid,
  options,
}: Props) => {
  return (
    <Modal
      title={title}
      show={show}
      size={size}
      submitLabel={submitLabel}
      onCancel={onClose}
      onClose={onClose}
      backdropClose={backdropClose}
      onSubmit={onSubmit}
      isSubmitValid={isSubmitValid}
    >
      <Select<IdAndLabel<string>, string> options={options} onSelectionChange={onChange} />
    </Modal>
  );
};
