import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { EmptyClaimId } from "components/Request/RequestValidate/Tabs/SelectAxis";
import { SetIdEnum } from "../request.visibility.typings";
import { mapProfilesToDimension } from "api/request/visibility/webModules/request.webmodules.mapper";
import { ProfilesDTO } from "api/request/visibility/webModules/request.webmodules.typings";
import { fetchClientContributionProfilesClaimValues } from "api/request/visibility/clientContribution/request.clientcontribution.api";
import { fetchClientContributionProfilesClaimValuesAction } from "./request.clientcontribution.action";

export const fetchClientContributioProfilesClaimValuesEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchClientContributionProfilesClaimValuesAction.started.match),
    switchMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchClientContributionProfilesClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ profiles: [] } as ProfilesDTO)
      ).pipe(
        map(dimensionDto =>
          fetchClientContributionProfilesClaimValuesAction.done({
            result: mapProfilesToDimension(SetIdEnum.One, payload.claimId, dimensionDto),
            params: undefined,
          })
        ),
        catchError(({ message }) =>
          of(fetchClientContributionProfilesClaimValuesAction.failed({ error: message, params: payload }))
        )
      )
    )
  );
