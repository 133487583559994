import * as React from "react";
import { Provider } from "react-redux";
import { MainLayout } from "components/layout/MainLayout/MainLayout";
import { AuthContainer } from "containers/userProfile/auth/auth.container";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { AppRoutes } from "./AppRoutes";
import { Store } from "redux";
import { History } from "history";
import { GlobalState } from "store/store.typings";
import { ErrorHandler } from "components/common/ErrorHandler/ErrorHandler";
import { WebComponents } from "components/WebComponent/WebComponents";

interface Props {
  store: Store<GlobalState>;
  history: History;
}

export const App: React.FunctionComponent<Props> = ({ store, history }) => (
  <Provider store={store}>
    <>
      <WebComponents />
      <ErrorHandler>
        <React.StrictMode>
          <AuthContainer>
            <BrowserRouter>
              <ConnectedRouter history={history}>
                <MainLayout>
                  <AppRoutes />
                </MainLayout>
              </ConnectedRouter>
            </BrowserRouter>
          </AuthContainer>
        </React.StrictMode>
      </ErrorHandler>
    </>
  </Provider>
);
