import * as React from "react";
import { Modal } from "components/common/Modal/Modal";
import { ComponentSize } from "components/common/components.typings";
import TextArea from "components/common/TextArea/TextArea";

export interface Props {
  size?: ComponentSize;
  title: string;
  show?: boolean;
  submitLabel?: string;
  backdropClose?: boolean;
  placeholder?: string;
  isSubmitValid?: boolean;
  labelRequired: boolean;
  label: string;
  onCancel?: () => void;
  onClose: () => void;
  onSuccess?: () => void;
  onChange?: (comment: string) => void;
  onSubmit?: () => void;
}

export const RequestModal: React.FunctionComponent<Props> = ({
  onClose,
  onChange,
  size,
  show,
  submitLabel,
  backdropClose,
  title,
  onSubmit,
  placeholder,
  isSubmitValid,
  labelRequired,
  label,
}: Props) => {
  return (
    <Modal
      title={title}
      show={show}
      size={size}
      submitLabel={submitLabel}
      onCancel={onClose}
      onClose={onClose}
      backdropClose={backdropClose}
      onSubmit={onSubmit}
      isSubmitValid={isSubmitValid}
    >
      <TextArea
        id="comment"
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        labelRequired={labelRequired}
      />
    </Modal>
  );
};
