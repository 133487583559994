import { isType } from "typescript-fsa";
import { fetchUserRequestsAction, fetchUserCoverageAction } from "./user.detail.action";
import { UserRequestDetail } from "containers/request/request.typings";
import reduceReducer from "reduce-reducers";
import { KeyValue } from "api/request/request.typings";

export interface UserDetailState {
  userId?: string;
  requests?: UserRequestDetail[];
  coverage: KeyValue[];
  isFetching: boolean;
  errorMessage: string;
}

export const initUserDetailState: UserDetailState = {
  requests: null,
  isFetching: false,
  errorMessage: null,
  coverage: null,
};

export const userRequestsReducer = (state: UserDetailState = initUserDetailState, action): UserDetailState => {
  if (isType(action, fetchUserRequestsAction.started)) {
    return { ...state, isFetching: true, requests: null, coverage: null, errorMessage: null };
  }
  if (isType(action, fetchUserRequestsAction.done)) {
    return { ...state, requests: action.payload.result, isFetching: false };
  }
  if (isType(action, fetchUserRequestsAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const userCoverageReducer = (state: UserDetailState = initUserDetailState, action): UserDetailState => {
  if (isType(action, fetchUserCoverageAction.started)) {
    return { ...state, isFetching: true, coverage: null, errorMessage: null };
  }
  if (isType(action, fetchUserCoverageAction.done)) {
    return { ...state, isFetching: false, coverage: action.payload.result };
  }
  if (isType(action, fetchUserCoverageAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const userDetailReducer = reduceReducer(userRequestsReducer, userCoverageReducer);
