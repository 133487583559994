import * as React from "react";
import { ViewSettingEnum, SetIds } from "./VisibilitySet";
import { map, size } from "lodash";
import { VisiblitySetContainer } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.container";
import { Axis, SetSettings } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { If } from "components/common/If/If";
import { AddButton } from "components/common/Buttons/AddButton";
import { DeleteButton } from "components/common/Buttons/DeleteButton";
export interface Props {
  setsSettings: SetSettings[];
  axises: Axis[];
  type: ViewSettingEnum;
  allowAccess: boolean;
  onClaimIdChange: ({ axisLabel, setId, viewSettingType }: SetIds, claimId: string, claimLabel: string, operator: string) => void;
  onClaimValueChange: ({ axisLabel, setId, viewSettingType }: SetIds, claimValues: string[]) => void;
  onClaimOperatorChange: ({ axisLabel, setId, viewSettingType }: SetIds, operator: string) => void;
  onAddVisibilitySet: (type: ViewSettingEnum) => void;
  onDeleteVisibilitySet: (type: ViewSettingEnum) => void;
}

export const VisibilitySetList: React.FunctionComponent<Props> = ({
  setsSettings,
  type,
  axises,
  allowAccess,
  onClaimIdChange,
  onClaimValueChange,
  onClaimOperatorChange,
  onAddVisibilitySet,
  onDeleteVisibilitySet,
}) => {
  const handleAddVisibilitySet = (e: any) => {
    onAddVisibilitySet(type);
    e.target.blur();
  };
  const handleDeleteVisibilitySet = () => onDeleteVisibilitySet(type);
  return (
    <>
      {map(setsSettings, setSettings => {
        return (
          <>
            <div className="mb-3 mt-4">
              <b>Visibility Set {setSettings.setId}</b>
            </div>
            <VisiblitySetContainer
              settings={setSettings.settings}
              viewSettingType={type}
              id={setSettings.setId}
              axises={axises}
              allowAccess={allowAccess}
              onClaimIdChange={onClaimIdChange}
              onClaimValueChange={onClaimValueChange}
              onClaimOperatorChange={onClaimOperatorChange}
            />
          </>
        );
      })}

      <If
        condition={
          type === ViewSettingEnum.EXTENDED_MARK ||
          type === ViewSettingEnum.EXTENDED_SG ||
          type === ViewSettingEnum.ASOF ||
          ((ViewSettingEnum.SG || ViewSettingEnum.MARK) && size(setsSettings) < 10)
        }
      >
        <AddButton className="col-12 mb-3" onClick={handleAddVisibilitySet} label="Add Visibility Set" />
      </If>
      <If condition={size(setsSettings) > 1}>
        <DeleteButton className="col-12" onClick={handleDeleteVisibilitySet} label="Delete Visibility Set" />
      </If>
    </>
  );
};
