import { ROUTES } from "common/routes";
import * as React from "react";
import { Link } from "react-router-dom";

export interface Props {
  isBackButtonShown?: boolean;
  title: string;
}

export const PageHeader: React.FunctionComponent<Props> = ({ isBackButtonShown = false, title }) => (
  <div className="pb-3 my-4 border-bottom">
    <>
      {isBackButtonShown && (
        <Link to={ROUTES.HOME}>
          <i className="icon pb-1">arrow_back</i> Back to homepage
        </Link>
      )}
      <h1 className="display-3 mt-2 mb-0 line-height-1 text-truncate">{title}</h1>
    </>
  </div>
);
