import * as React from "react";
import classNames from "classnames";

export interface Props {
  className?: string;
}

export const NavPills: React.FunctionComponent<Props> = ({ className, children }) => (
  <ul className={classNames("nav nav-pills", className ? className : "")}>{children}</ul>
);
