import { Status, StatusEnum } from "containers/referential/referential.typings";
import * as React from "react";
import { Badge } from "components/common/Badge/Badge";

interface Props {
  status: Status;
}

export const RequestStatus: React.FunctionComponent<Props> = ({ status }: Props) => {
  return status ? (
    <Badge type={getStatusTypeFromStatus(status.id)} discreet={false} prependSquare={true} value={status.label} />
  ) : (
    <Badge type={"secondary"} discreet={false} prependSquare={true} value={"unknown"} />
  );
};

const getStatusTypeFromStatus = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.PENDING:
      return "warning";
    case StatusEnum.VALIDATED:
      return "success";
    case StatusEnum.REJECTED:
      return "danger";
    case StatusEnum.DEACTIVATED:
      return "secondary";
    default:
      return "warning";
  }
};
