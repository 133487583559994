import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { Action } from "redux";
import { fetchClaimVluesAction } from "./request.visibility.set.action";
import { fetchVisibilityClaimValues } from "api/request/visibility/visibilitySet/request.visibility.set.api";
import { mapToDimension } from "api/request/visibility/visibilitySet/request.visibility.set.mapper";
import { EmptyClaimId } from "components/Request/RequestValidate/Tabs/SelectAxis";
import { DimensionDTO } from "api/request/visibility/visibilitySet/request.visibility.set.typings";

export const fetchVisibilityClaimValuesEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchClaimVluesAction.started.match),
    mergeMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchVisibilityClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ values: [] } as DimensionDTO)
      ).pipe(
        map(dimensionDto =>
          fetchClaimVluesAction.done({
            result: mapToDimension(payload.setId, payload.axisLabel, payload.viewSettingType, dimensionDto),
            params: undefined,
          })
        ),
        catchError(({ message }) => of(fetchClaimVluesAction.failed({ error: message, params: payload })))
      )
    )
  );
