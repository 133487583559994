import { isType } from "typescript-fsa";
import { createRequestAction } from "./request.create.action";

export interface RequestCreateState {
  isFetching: boolean;
}

export const initRequestCreateState: RequestCreateState = {
  isFetching: false,
};

export const requestCreateReducer = (
  state: RequestCreateState = initRequestCreateState,
  action
): RequestCreateState => {
  if (isType(action, createRequestAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, createRequestAction.done)) {
    return { ...state, isFetching: false };
  }
  if (isType(action, createRequestAction.failed)) {
    return { ...state, isFetching: false };
  }
  return { ...state };
};
