import * as React from "react";
import { UserRequest } from "containers/request/request.typings";
import { Loading } from "components/common/Loading/Loading";
import { map } from "lodash";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { RequestRow } from "./RequestRow";

export interface Props {
  userRight: ConnectedUserProfileRights;
  requests: UserRequest[];
  isFetching: boolean;
}

const Header: React.FunctionComponent = () => (
  <thead>
    <tr>
      <th>Name</th>
      <th>Department</th>
      <th>Country</th>
      <th>Status</th>
      <th>Latest Update</th>
      <th>Step</th>
      <th />
    </tr>
  </thead>
);

const Body: React.FunctionComponent<Pick<Props, "userRight" | "requests">> = ({ requests, userRight }) => (
  <tbody>
    {map(requests, request => (
      <RequestRow request={request} userRight={userRight} key={request.requestId} />
    ))}
  </tbody>
);

export const RequestList: React.FunctionComponent<Props> = ({ requests, isFetching, userRight }) => (
  <>
    <Loading isVisible={isFetching} />
    <table className="table table-responsive-md pb-5">
      <Header />
      <Body requests={requests} userRight={userRight} />
    </table>
  </>
);
