import * as React from "react";
import { Applications } from "containers/userProfile/userProfile.typings";
import { Badge } from "components/common/Badge/Badge";

export interface Props {
  applications: Applications;
}

export const UserDetailPageTagList: React.FunctionComponent<Props> = ({ applications }: Props) => {
  return (
    <>
      {!!applications && applications.hasAccessToCprofit && (
        <>
          <Badge value="C-profit" type="primary" /> <br /> <br />
        </>
      )}
      {applications === null && (
        <>
          <Badge value="No access" type="secondary" /> <br /> <br />
        </>
      )}
    </>
  );
};
