import * as React from "react";
import { Contact } from "containers/contact/contact.typings";
import { Loading } from "components/common/Loading/Loading";

export interface StateProps {
  contact: Contact;
  contactId: string;
  isFetching: boolean;
}

export interface DispatchProps {
  onFetchContactById: (contactId?: string) => void;
}

export type Props = StateProps & DispatchProps;

export const CloneContactViewer: React.FunctionComponent<Props> = ({
  contact,
  isFetching,
  contactId,
  onFetchContactById,
}: Props) => {
  React.useEffect(() => {
    onHandleFetchContactById(contactId);
  }, [contactId]);

  const [fetchHandled, setFetchHandled] = React.useState<boolean>(false);

  const onHandleFetchContactById = (contactId: string) => {
    onFetchContactById(contactId);
    setFetchHandled(true);
  };

  return (
    <>
      <Loading isVisible={isFetching} />
      {fetchHandled && contact && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Team</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{contact.fullName}</td>
              <td>{contact.department}</td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};
