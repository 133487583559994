import { IcHttpResquestHeader } from "api/api.typings";
import { Observable } from "rxjs";
import { createRepository } from "api/api";
import { DataCollectSupplierDTO, DataCollectSuppliersDTO, ProfilesDTO, SblDTO, SupplierDTO, SuppliersDTO } from "./request.webmodules.typings";
import { map } from "rxjs/operators";

export const fetchWebModulesProfilesClaimValues = (
  dimensionUrl: string,
  headers: IcHttpResquestHeader
): Observable<ProfilesDTO> => createRepository().get<ProfilesDTO>(dimensionUrl, headers);

export const fetchWebModulesSuppliersClaimValues = (
  webModulesUrl: string,
  headers: IcHttpResquestHeader
): Observable<SuppliersDTO> =>
  createRepository()
    .get<SupplierDTO[]>(webModulesUrl, headers)
    .pipe(map(result => ({ suppliers: result } as SuppliersDTO)));

export const fetchWebModulesSBLClaimValues = (
  webModulesUrl: string,
  headers: IcHttpResquestHeader
): Observable<SblDTO> => createRepository().get<SblDTO>(webModulesUrl, headers);

export const fetchDataCollectSuppliersClaimValues = (
  webModulesUrl: string,
  headers: IcHttpResquestHeader
): Observable<DataCollectSuppliersDTO> =>
  createRepository()
    .get<DataCollectSuppliersDTO>(webModulesUrl, headers);
