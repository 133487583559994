import { IcHttpResquestHeader } from "api/api.typings";
import { Observable } from "rxjs";
import { createRepository } from "api/api";
import {
  DimensionDTO,
  VisibilityRequestDTO,
  RejectRequestDTO,
  ValidateRequestDTO,
} from "./request.visibility.set.typings";
import { UserRequestDetailDTO } from "api/request/request.typings";
import { toastifySuccess } from "utils/toastify/toasterNotifyer";
import { tap } from "rxjs/operators";

export const fetchVisibilityClaimValues = (
  dimensionUrl: string,
  headers: IcHttpResquestHeader
): Observable<DimensionDTO> => createRepository().get<DimensionDTO>(dimensionUrl, headers);

const url = "/api/v1/requests";
export const createVisibilityRequest = (
  request: VisibilityRequestDTO,
  headers: IcHttpResquestHeader
): Observable<UserRequestDetailDTO> => {
  return createRepository()
    .put<UserRequestDetailDTO>(`${url}/${request.id}`, headers, { ...request })
    .pipe(tap(() => toastifySuccess("The visibility has been saved")));
};

export const validateVisibilityRequest = (
  request: ValidateRequestDTO,
  headers: IcHttpResquestHeader
): Observable<UserRequestDetailDTO> => {
  return createRepository()
    .post<UserRequestDetailDTO>(`${url}/${request.id}/validate`, headers, { ...request })
    .pipe(tap(() => toastifySuccess("The visibility has been validated")));
};

export const rejectVisibilityRequest = (
  request: RejectRequestDTO,
  headers: IcHttpResquestHeader
): Observable<UserRequestDetailDTO> => {
  return createRepository()
    .post<UserRequestDetailDTO>(`${url}/${request.id}/reject`, headers, { ...request })
    .pipe(tap(() => toastifySuccess("The visibility has been rejected")));
};
