import * as React from "react";
import { map, find, isEmpty, get } from "lodash";
import { DomainEnum } from "api/request/request.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { getClaimOptions } from "./VisibilityWebModules";
import { Select } from "components/Select/Select";
import { IdAndLabel } from "common/common.typings";
import { Claim, SetSettings } from "containers/request/validate/requestVisibility/request.visibility.typings";
import * as Styles from "./ModuleProfileSelect.scss";

export const EmptyProfileId = "";
export const ClaimProfileId = "profile";

export interface ProfileSelectProps {
  dimensions: Dimension[];
  onClaimValueChanged?: (domainId: DomainEnum, claimId: string, values: string[]) => void;
  domainSettings: SetSettings[];
  domainId: DomainEnum;
}

export const getClaimProfile = (domainSettings: SetSettings[]): Claim =>
  get(
    find(domainSettings[0].settings, x => x.claim.id === ClaimProfileId),
    "claim"
  );

export const ProfileSelect: React.FunctionComponent<ProfileSelectProps> = ({
    dimensions,
    onClaimValueChanged,
    domainSettings,
    domainId
}) => {
  const [options, setOptions] = React.useState([]);
  const onProfileValueChanged = (selectedId: string) => {
    onClaimValueChanged(domainId, ClaimProfileId, [selectedId]);
  };
  const claimProfile = !isEmpty(domainSettings) && getClaimProfile(domainSettings);
  const selectProfileValue = !isEmpty(domainSettings) && claimProfile !== undefined && claimProfile.values[0];
  React.useEffect(() => {
    let optionsClaimProfile = map(getClaimOptions(dimensions, ClaimProfileId), value => ({
      id: value.id,
      label: value.name,
    }));
    const emptyProfile = { id: EmptyProfileId, label: "Select a profile" };
    optionsClaimProfile = [emptyProfile, ...optionsClaimProfile];
    setOptions(optionsClaimProfile);
  }, [dimensions]);

  return (
    <div className="col-12">
      <div className="row align-items-center my-2">
        <label className="text-left"> Profile : </label>
        <div className="col-md-6 col-sm-12 ml-5">
          <div className="col-6 ml-1">
            <fieldset className={Styles.SetWidth}>
              <div className="row align-items-center my-2">
                <Select<IdAndLabel<string>, string>
                  options={options}
                  selectedId={selectProfileValue}
                  onSelectionChange={onProfileValueChanged}
                />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
