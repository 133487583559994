import * as React from "react";
import { get, map } from "lodash";
import { UserRequest } from "containers/request/request.typings";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { getNavigateToUrl } from "../Common/navigate";
import { RequestStatus } from "components/common/RequestStatus/RequestStatus";
import { JustificationField } from "components/common/JustificationField/JustificationField";
import { Link } from "react-router-dom";
import { ROUTES } from "common/routes";
import { Status, StatusEnum } from "containers/referential/referential.typings";

export interface Props {
  userRight: ConnectedUserProfileRights;
  request: UserRequest;
}

export const RequestRow: React.FunctionComponent<Props> = ({
  request: { requestId, status, lastModificationDate, user, application, validatorComment, endDate, validators },
  userRight,
}) => (
  <tr key={requestId}>
    <td>
      <Link to={ROUTES.USER_DETAIL.replace(":id", user.id.toString())}>
        {get(user, "fullName", get(user, "email", ""))}
      </Link>
    </td>
    <td className="text-truncate">{get(user, "internalHrTeam")}</td>
    <td>{get(user, "country", "")}</td>
    <td>
      <RequestStatus status={changeStatusToDeactivated(endDate, status)} />
    </td>
    <td>{lastModificationDate}</td>
    <td>
      {status.id === StatusEnum.PENDING ? (
        <span className="d-inline-block text-wrap" style={{ maxWidth: "200px" }}>
          {validators && validators.length > 0
            ? map(validators, (validator, index) => (
                <a key={user.id + "_" + index} href={`mailto:${validator}`}>
                  {validator}
                  {index < validators.length - 1 ? ", " : ""}
                </a>
              ))
            : ""}
        </span>
      ) : (
        <JustificationField comment={validatorComment} />
      )}
    </td>
    <td>{getNavigateToUrl(requestId, get(application, "id", ""), get(status, "id", ""), userRight)} </td>
  </tr>
);

export const changeStatusToDeactivated = (endDate: string, status: Status) => {
  if (endDate !== null) {
    status.id = StatusEnum.DEACTIVATED;
    status.label = "Deactivated";
  }
  return status;
};
