import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { DataCollectSuppliersDTO, ProfilesDTO, SblDTO, SuppliersDTO } from "./request.webmodules.typings";
import { map } from "lodash";
import { IdName } from "common/common.typings";
import { DomainEnum } from "api/request/request.typings";

export const mapProfilesToDimension = (setId: number, axisLabel: string, profilesDto: ProfilesDTO): Dimension => ({
  axisLabel,
  setId,
  claimId: "profile",
  values: map(
    profilesDto.profiles,
    (profile): IdName<string> => {
      return { id: profile.name, name: profile.name };
    }
  ),
});

export const mapSuppliersToDimension = (setId: number, axisLabel: string, suppliersDto: SuppliersDTO): Dimension => ({
  axisLabel,
  setId,
  claimId: "suppliers",
  domainId: DomainEnum.MASSUPLOAD,
  values: map(
    suppliersDto.suppliers,
    (supplier): IdName<string> => {
      return { id: supplier.applicationId, name: supplier.supplierName };
    }
  ),
});

export const mapSBLsToDimension = (setId: number, axisLabel: string, sblsDto: SblDTO): Dimension => ({
  axisLabel,
  setId,
  claimId: "sbl",
  values: map(
    sblsDto.sbls,
    (sbl): IdName<string> => {
      return { id: sbl.name, name: sbl.name };
    }
  ),
});

export const mapDataCollectSuppliersToDimension = (setId: number, axisLabel: string, suppliersDto: DataCollectSuppliersDTO, domainId: DomainEnum): Dimension => {
  return ({
    axisLabel,
    setId,
    claimId: "suppliers",
    domainId: domainId,
    values: map(
      suppliersDto.suppliers,
      (supplier): IdName<string> => {
        return { id: supplier.id, name: supplier.supplierMnemo };
      }
    ),
  });
}