import actionCreatorFactory from "typescript-fsa";

const enum ExportRequestActionName {
  FETCH_REQUESTS_To_EXPORT = "FETCH_REQUESTS_To_EXPORT",
}

const exportRequestActionFactory = actionCreatorFactory("ExportRequest");

export const fetchExportRequestAction = exportRequestActionFactory.async<void, boolean, string>(
  ExportRequestActionName.FETCH_REQUESTS_To_EXPORT
);
