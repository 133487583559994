import { createRepository } from "api/api";
import { Observable } from "rxjs";
import { IcHttpResquestHeader } from "../api.typings";
import {
  UserRequestDetailDTO,
  UserRequestCreateDTO,
  RequestPageDTO,
  UserRequestExportResponse,
} from "./request.typings";
import { RequestListFilter } from "containers/request/list/request.list.typings";
import { StatusEnum } from "containers/referential/referential.typings";
import { map } from "rxjs/operators";

const url = "/api/v1/requests";

export const fetchUserRequestByTerm = (
  { term, applicationId, statusId, page, pageSize }: RequestListFilter,
  headers: IcHttpResquestHeader
): Observable<RequestPageDTO> => {
  return createRepository().get<RequestPageDTO>(url, headers, {
    filter: term,
    applicationId,
    statusIds: statusId && statusId !== StatusEnum.ALL ? [statusId] : undefined,
    pageSize,
    page,
  });
};

export const createRequest = (request: UserRequestCreateDTO, headers: IcHttpResquestHeader): Observable<string> => {
  return createRepository()
    .post<{ requestId: string }>(url, headers, { ...request })
    .pipe(map(result => result.requestId));
};

export const fetchUserRequestById = (id: string, headers: IcHttpResquestHeader): Observable<UserRequestDetailDTO> =>
  createRepository().get<UserRequestDetailDTO>(`${url}/${id}`, headers);

export const fetchExportRequest = (headers: IcHttpResquestHeader): Observable<UserRequestExportResponse> =>
  createRepository().get<UserRequestExportResponse>(`${url}/activerequests`, headers);
