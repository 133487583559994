import * as React from "react";
import { RequestList } from "./RequestList";
import { UserRequest } from "containers/request/request.typings";
import { PageHeader } from "components/common/PageHeader/PageHeader";
import { RequestFilter } from "components/Request/RequestList/RequestFilter";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { RequestListFilter } from "containers/request/list/request.list.typings";
import { SubmitButton } from "components/common/Buttons/SubmitButton";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";
import { isUndefined } from "util";

export interface StateProps {
  userRight: ConnectedUserProfileRights;
  requests: UserRequest[];
  isFetching: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  page: number;
  applicationId: ApplicationEnum;
  statusId: StatusEnum;
  term: string;
}

export interface DispatchProps {
  onFetchRequests: (filter?: RequestListFilter) => void;
}

export type Props = StateProps & DispatchProps;

export const RequestListPage: React.FunctionComponent<Props> = ({
  requests,
  isFetching,
  userRight,
  hasNextPage,
  hasPreviousPage,
  onFetchRequests,
  page,
  applicationId,
  statusId,
  term,
}) => {
  const pageSize = 20;

  const handleOnPreviousClick = () => {
    handleOnFilterChange({ applicationId, statusId, term, pageSize, page: page - 1 });
  };

  const handleOnNextClick = () => {
    handleOnFilterChange({ applicationId, statusId, term, pageSize, page: page + 1 });
  };

  const handleOnFilterChange = (filter: RequestListFilter) => {
    onFetchRequests({ ...filter, page: isUndefined(filter.page) ? 1 : filter.page });
  };

  return (
    <>
      <PageHeader title="User requests" />
      <RequestFilter
        onFilterChange={handleOnFilterChange}
        currentFilterValues={{ term, applicationId, statusId }}
        canCrateRequest={userRight.canCreateRequest}
      />
      <RequestList requests={requests} isFetching={isFetching} userRight={userRight} />
      <div className="d-flex flex-row-reverse">
        <SubmitButton
          label="Older"
          onClick={handleOnNextClick}
          disabled={!hasNextPage}
          icon="keyboard_arrow_right"
          isIconBeforeLabel={false}
        />
        <SubmitButton
          label="Newer"
          onClick={handleOnPreviousClick}
          disabled={!hasPreviousPage}
          icon="keyboard_arrow_left"
          isIconBeforeLabel={true}
        />
      </div>
    </>
  );
};
