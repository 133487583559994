import * as React from "react";
import { IconRemove } from "../Icon/Icon";
import { ClickableButtonProps } from "./button.typings";

export const DeleteButton: React.FunctionComponent<ClickableButtonProps> = ({ onClick, label, className }) => (
  <div className={className}>
    <button type="button" className="btn btn-lg btn-default sgbs-btn-default btn-icon-text w-100" onClick={onClick}>
      <IconRemove />
      {label || "Delete"}
    </button>
  </div>
);
