import { ActionsObservable, combineEpics, Epic } from "redux-observable";
import { of, from } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { Action } from "redux";
import {
  fetchVisibilityDomainsAction,
  requestUserVisibilityAction,
  fetchVisibilityByContactIdAction,
  saveVisibilityAction,
  validateVisibilityAction,
  rejectVisibilityAction,
  validateVisibilityFromModalAction,
} from "./request.visibility.action";
import { fetchVisibilityAxises } from "api/request/visibility/request.visibility.api";
import { mapToDomains } from "api/request/visibility/request.visibility.mapper";
import { fetchVisibilityClaimValuesEpic } from "./requestVisibilitySet/request.visibility.set.epics";
import { fetchUserRequestById } from "api/request/request.api";
import { mapClaimsDtoToSettings } from "./request.visibility.mapper";
import { fetchUserById } from "api/user/user.api";
import { isEmpty } from "lodash";
import { toastifySuccess, toastifyErrorMessage } from "utils/toastify/toasterNotifyer";
import { ApplicationEnum } from "containers/referential/referential.typings";
import { VisibilityClone } from "./request.visibility.typings";
import { UserDTO } from "api/user/user.typing";
import { isNull } from "util";
import { ClaimDTO } from "api/request/request.typings";
import {
  createVisibilityRequest,
  validateVisibilityRequest,
  rejectVisibilityRequest,
} from "api/request/visibility/visibilitySet/request.visibility.set.api";
import { mapToRequestDetail } from "api/request/request.mapper";
import { push } from "connected-react-router";
import { ValidateRequestDTO } from "api/request/visibility/visibilitySet/request.visibility.set.typings";
import { ROUTES } from "common/routes";
import {
  fetchWebModulesSuppliersClaimValuesEpic,
  fetchWebModulesProfilesClaimValuesEpic,
  fetchWebModulesSBLClaimValuesEpic,
} from "./webModules/request.webmodules.epics";
import { fetchDataQualityProfilesClaimValuesEpic } from "./dataQuality/request.dataquality.epic";
import { fetchDataCollectSuppliersClaimValuesEpic } from "./dataCollect/request.datacollect.epic";
import { fetchClientContributioProfilesClaimValuesEpic } from "./clientContribution/request.clientcontribution.epic";

export const fetchVisibilityDomainsEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchVisibilityDomainsAction.started.match),
    switchMap(({ payload, meta }) =>
      fetchVisibilityAxises(meta.headers).pipe(
        map(refDomains =>
          fetchVisibilityDomainsAction.done({ result: mapToDomains(refDomains.items), params: undefined })
        ),
        catchError(({ message }) => of(fetchVisibilityDomainsAction.failed({ error: message, params: payload })))
      )
    )
  );

export const fetchVisibilityByRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(requestUserVisibilityAction.started.match),
    switchMap(({ payload, meta }) =>
      fetchUserRequestById(payload, meta.headers).pipe(
        map(requestDetails =>
          requestUserVisibilityAction.done({
            result: mapClaimsDtoToSettings(requestDetails.claims, requestDetails.applicationId),
            params: payload,
          })
        ),
        catchError(({ message }) => of(requestUserVisibilityAction.failed({ error: message, params: payload })))
      )
    )
  );

export const fetchVisibilityByContactIdEpic: Epic<Action> = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchVisibilityByContactIdAction.started.match),
    switchMap(({ payload, meta }) =>
      fetchUserById(payload.contactId, meta.headers).pipe(
        map(userDetail => getVisisiblity(payload, userDetail)),
        tap(({ error }) =>
          error === true
            ? toastifyErrorMessage("User Does not have access to Cprofit")
            : toastifySuccess("Visibility is cloned")
        ),
        catchError(({ message }) => of(fetchVisibilityByContactIdAction.failed({ error: message, params: payload })))
      )
    )
  );

export const getVisisiblity = (payload: VisibilityClone, userDetailDto: UserDTO) => {
  const claims = getClaims(payload, userDetailDto);
  return !isNull(claims)
    ? fetchVisibilityByContactIdAction.done({
        result: mapClaimsDtoToSettings(claims, payload.applicationId),
        params: payload,
      })
    : fetchVisibilityByContactIdAction.failed({
        error: "User Does not have access to Cprofit",
        params: payload,
      });
};

export const getClaims = (payload: VisibilityClone, userDetailDto: UserDTO): ClaimDTO[] =>
  !isEmpty(userDetailDto) &&
  payload.applicationId === ApplicationEnum.C_PROFIT &&
  !!userDetailDto.validatedRequestCProfit
    ? userDetailDto.validatedRequestCProfit.claims
    : null;

export const saveVisibilityRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(saveVisibilityAction.started.match),
    switchMap(({ payload, meta }) =>
      createVisibilityRequest(payload, meta.headers).pipe(
        map(request => mapToRequestDetail(request)),
        switchMap(requestDetail =>
          from([saveVisibilityAction.done({ result: requestDetail, params: payload }), push(ROUTES.HOME)])
        ),
        catchError(({ message }) => of(saveVisibilityAction.failed({ error: message, params: payload })))
      )
    )
  );

export const validateVisibilityRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(validateVisibilityAction.started.match),
    switchMap(({ payload, meta }) =>
      createVisibilityRequest(payload, meta.headers).pipe(
        map(request => mapToRequestDetail(request)),
        switchMap(savedRequest =>
          !savedRequest.isValidateCommentRequired
            ? validateVisibilityRequest(
                { id: savedRequest.requestId.toString() } as ValidateRequestDTO,
                meta.headers
              ).pipe(
                map(request => mapToRequestDetail(request)),
                switchMap(requestDetail =>
                  from([validateVisibilityAction.done({ result: requestDetail, params: payload }), push(ROUTES.HOME)])
                ),
                catchError(({ message }) => of(validateVisibilityAction.failed({ error: message, params: payload })))
              )
            : of(validateVisibilityAction.done({ result: savedRequest, params: payload }))
        ),
        catchError(({ message }) => of(validateVisibilityAction.failed({ error: message, params: payload })))
      )
    )
  );

export const rejectVisibilityRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(rejectVisibilityAction.started.match),
    switchMap(({ payload, meta }) =>
      rejectVisibilityRequest(payload, meta.headers).pipe(
        map(request => mapToRequestDetail(request)),
        switchMap(requestDetail =>
          from([rejectVisibilityAction.done({ result: requestDetail, params: payload }), push(ROUTES.HOME)])
        ),
        catchError(({ message }) => of(rejectVisibilityAction.failed({ error: message, params: payload })))
      )
    )
  );

export const validateVisibilityRequestFromModalEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(validateVisibilityFromModalAction.started.match),
    switchMap(({ payload, meta }) =>
      validateVisibilityRequest(payload, meta.headers).pipe(
        map(request => mapToRequestDetail(request)),
        switchMap(requestDetail =>
          from([validateVisibilityFromModalAction.done({ result: requestDetail, params: payload }), push(ROUTES.HOME)])
        ),
        catchError(({ message }) => of(validateVisibilityFromModalAction.failed({ error: message, params: payload })))
      )
    )
  );

export const requestVisibilityEpic = combineEpics(
  fetchVisibilityDomainsEpic,
  fetchVisibilityClaimValuesEpic,
  saveVisibilityRequestEpic,
  validateVisibilityRequestEpic,
  rejectVisibilityRequestEpic,
  fetchVisibilityByRequestEpic,
  validateVisibilityRequestFromModalEpic,
  fetchVisibilityByContactIdEpic,
  fetchWebModulesProfilesClaimValuesEpic,
  fetchWebModulesSuppliersClaimValuesEpic,
  fetchWebModulesSBLClaimValuesEpic,
  fetchDataQualityProfilesClaimValuesEpic,
  fetchDataCollectSuppliersClaimValuesEpic,
  fetchClientContributioProfilesClaimValuesEpic
);
