import * as React from "react";

export type Rating = "A" | "B" | "C" | "D" | "E";

export interface Props {
  title?: string;
  rating?: Rating;
}

export const Rating: React.FunctionComponent<Props> = ({ title, rating }: Props) => (
  <>
  <div className="d-flex flex-column align-items-center">
    <div className="font-weight-semibold">{title}</div>
    <div className="d-flex align-items-center">
      <div className={`score bg-green-50 score-first ${rating === "A" ? "active" : ""}`}>A</div>
      <div className={`score bg-green-30 ${rating === "B" ? "active" : ""}`}>B</div>
      <div className={`score bg-yellow-30 ${rating === "C" ? "active" : ""}`}>C</div>
      <div className={`score bg-orange-30 ${rating === "D" ? "active" : ""}`}>D</div>
      <div className={`score bg-red-50 score-last ${rating === "E" ? "active" : ""}`}>E</div>
    </div>
    </div>
  </>
);
