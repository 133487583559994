import * as React from "react";

export interface Props {
  condition: boolean;
  elseComponent?: React.ReactNode;
}

export const If: React.SFC<Props> = ({ condition, children, elseComponent }) => (
  <>{condition ? children : elseComponent || null}</>
);
