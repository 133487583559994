import { IcHttpResquestHeader } from "api/api.typings";
import { Observable, of } from "rxjs";
import { UserCoverageResponse, UserRequestsListResponse } from "api/request/request.typings";
import { createRepository } from "api/api";
import { catchError, tap } from "rxjs/operators";
import { UserDTO } from "api/user/user.typing";
import { ApplicationEnum } from "containers/referential/referential.typings";
import { toastifySuccess } from "utils/toastify/toasterNotifyer";

const url = "/api/v1/users";
const coverageUrl = "/2.0/attributes/nbi/clientgen_name/keyvalues";

export const fetchUserById = (
  userId: string,
  headers: IcHttpResquestHeader,
  noToastOnError?: boolean
): Observable<UserDTO | null> =>
  createRepository()
    .get<UserDTO>(`${url}/${userId}`, headers, undefined, noToastOnError)
    .pipe(catchError(() => of(null)));

export const fetchRequestsByUserId = (
  userId: string,
  headers: IcHttpResquestHeader
): Observable<UserRequestsListResponse> =>
  createRepository().get<UserRequestsListResponse>(`${url}/${userId}/requests`, headers);

export const terminateUserAccess = (
  user: { id: string; applicationId: ApplicationEnum },
  headers: IcHttpResquestHeader
): Observable<UserDTO> => {
  return createRepository()
    .post<UserDTO>(`${url}/${user.id}/accessterminate?applicationId=${user.applicationId}`, headers, { ...user })
    .pipe(tap(() => toastifySuccess("The deactivate of the account has been done")));
};

export const updateUserEmail = (user: { id: string }, headers: IcHttpResquestHeader): Observable<UserDTO> => {
  return createRepository()
    .post<UserDTO>(`${url}/${user.id}/refresh`, headers, { ...user })
    .pipe(tap(() => toastifySuccess("The user's email adress refresh is done.")));
};

export const fetchCoverageByUserId = (
  userEmail: string,
  headers: IcHttpResquestHeader
): Observable<UserCoverageResponse> =>
  createRepository().get<UserCoverageResponse>(`${coverageUrl}`, { ...headers, impersonatingAs: userEmail });
