import { IdAndLabel } from "common/common.typings";

export enum ApplicationEnum {
  C_PROFIT = "cprofit",
}
export enum StatusEnum {
  PENDING = "pending",
  REJECTED = "rejected",
  VALIDATED = "validated",
  DEACTIVATED = "deactivated",
  ALL = "all",
}
export enum RoleEnum {
  VALIDATOR = "validator",
  USER = "user",
}

export interface ReferentialDTO<T> {
  items: T[];
}
export type Application = IdAndLabel<ApplicationEnum>;
export type Status = IdAndLabel<StatusEnum>;
export type Role = IdAndLabel<RoleEnum>;
