import { isType } from "typescript-fsa";
import { UserVisibility, Domain } from "./request.visibility.typings";
import {
  fetchVisibilityDomainsAction,
  requestUserVisibilityAction,
  fetchVisibilityByContactIdAction,
  validateVisibilityAction,
  saveVisibilityAction,
  rejectVisibilityAction,
} from "./request.visibility.action";
import { Dimension } from "./requestVisibilitySet/request.visibility.set.typings";
import reduceReducer from "reduce-reducers";
import { UserRequestDetail } from "containers/request/request.typings";
import { requestVisibilitySetReducer } from "./requestVisibilitySet/request.visibility.set.reducer";
import { requestWebModulesReducer } from "./webModules/request.webmodules.reducer";
import { requestDataQualityReducer } from "./dataQuality/request.dataquality.reducer";
import { requesDataCollectReducer } from "./dataCollect/request.datacollect.reducer";
import { requestClientContributionReducer } from "./clientContribution/request.clientcontribution.reducer";

export interface RequestVisibilityState {
  isFetching: boolean;
  isClaimValuesFetching: boolean;
  isVisibilityFecthing?: boolean;
  errorMessage: string;
  dimensions: Dimension[];
  visibility: UserVisibility;
  domains: Domain[];
  request: UserRequestDetail;
  requestId?: string;
}

export const initState: RequestVisibilityState = {
  errorMessage: null,
  isClaimValuesFetching: false,
  isFetching: null,
  isVisibilityFecthing: null,
  dimensions: [],
  visibility: {
    sgViewSettings: [],
    markViewSettings: [],
    extendedSgViewSettings: [],
    extendedMarkViewSettings: [],
    asofViewSettings: [],
    facetViewSettings: [],
    webModulesSettings: [],
    requestId: null,
    dataQualitySettings: [],
    clientContributionSettings: []
  },
  domains: [],
  request: null,
};

export const fetchVisibilityDomainsReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchVisibilityDomainsAction.started)) {
    return { ...state, isFetching: true, domains: [], errorMessage: null };
  }
  if (isType(action, fetchVisibilityDomainsAction.done)) {
    return { ...state, domains: action.payload.result, isFetching: false };
  }
  if (isType(action, fetchVisibilityDomainsAction.failed)) {
    return { ...state, isFetching: false, domains: [], errorMessage: action.payload.error };
  }
  return state;
};

export const fetchDefaultRequestVisibilityReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, requestUserVisibilityAction.started) || isType(action, saveVisibilityAction.started)) {
    return {
      ...state,
      visibility: {
        sgViewSettings: [],
        markViewSettings: [],
        extendedSgViewSettings: [],
        extendedMarkViewSettings: [],
        webModulesSettings: [],
        asofViewSettings: [],
        facetViewSettings: [],
        dataQualitySettings: [],
        clientContributionSettings: []
      },
      isVisibilityFecthing: true,
      errorMessage: null,
    };
  }
  if (isType(action, requestUserVisibilityAction.done)) {
    return {
      ...state,
      visibility: { ...action.payload.result, requestId: action.payload.params },
      isVisibilityFecthing: false,
    };
  }
  if (isType(action, requestUserVisibilityAction.failed)) {
    return { ...state, isVisibilityFecthing: false, domains: [], errorMessage: action.payload.error };
  }
  return state;
};

export const fetchRequestVisibilityByContactIdReducer = (
  state: RequestVisibilityState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchVisibilityByContactIdAction.started)) {
    return {
      ...state,
      isVisibilityFecthing: true,
      errorMessage: null,
    };
  }
  if (isType(action, fetchVisibilityByContactIdAction.done)) {
    return {
      ...state,
      visibility: {
        ...state.visibility,
        sgViewSettings: action.payload.result.sgViewSettings,
        markViewSettings: action.payload.result.markViewSettings,
        extendedSgViewSettings: action.payload.result.extendedSgViewSettings,
        extendedMarkViewSettings: action.payload.result.extendedMarkViewSettings,
        webModulesSettings: action.payload.result.webModulesSettings,
        asofViewSettings: action.payload.result.asofViewSettings,
        facetViewSettings: action.payload.result.facetViewSettings,
        dataQualitySettings: action.payload.result.dataQualitySettings,
        clientContributionSettings: action.payload.result.clientContributionSettings
      },
      isVisibilityFecthing: false,
    };
  }
  if (isType(action, fetchVisibilityByContactIdAction.failed)) {
    return { ...state, isVisibilityFecthing: false, errorMessage: action.payload.error };
  }
  return state;
};

export const saveVisibilityReducer = (state: RequestVisibilityState = initState, action): RequestVisibilityState => {
  if (isType(action, saveVisibilityAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, saveVisibilityAction.done)) {
    return { ...state, request: action.payload.result, requestId: action.payload.params.id, isFetching: false };
  }
  if (isType(action, saveVisibilityAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const validateVisibilityReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, validateVisibilityAction.started)) {
    return { ...state, isFetching: true, request: { ...state.request, isValidateCommentRequired: false } };
  }
  if (isType(action, validateVisibilityAction.done)) {
    return { ...state, request: action.payload.result, requestId: action.payload.params.id, isFetching: false };
  }
  if (isType(action, validateVisibilityAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const rejectVisibilityReducer = (state: RequestVisibilityState = initState, action): RequestVisibilityState => {
  if (isType(action, rejectVisibilityAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, rejectVisibilityAction.done)) {
    return { ...state, request: action.payload.result, requestId: action.payload.params.id, isFetching: false };
  }
  if (isType(action, rejectVisibilityAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const requestVisbilityReducer = reduceReducer(
  fetchVisibilityDomainsReducer,
  fetchDefaultRequestVisibilityReducer,
  fetchRequestVisibilityByContactIdReducer,
  saveVisibilityReducer,
  validateVisibilityReducer,
  rejectVisibilityReducer,
  requestVisibilitySetReducer,
  requestWebModulesReducer,
  requestDataQualityReducer,
  requesDataCollectReducer,
  requestClientContributionReducer
);
