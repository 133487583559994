import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { Dispatch } from "redux";
import { fetchApplicationsAction } from "containers/referential/referential.action";
import { ApplicationSelect, DispatchProps, StateProps } from "components/Select/ApplicationSelect";
import { noop } from "lodash";
import { ApplicationEnum } from "containers/referential/referential.typings";

export interface OwnProps {
  selectedId?: ApplicationEnum;
  onSelectionChange?: (applicationId: ApplicationEnum) => void;
}

const mapStateToProps = ({ referential }: GlobalState, props: OwnProps): StateProps => ({
  applications: referential.applications,
  selectedId: props.selectedId,
});

const mapDispatchToProps = (dispatch: Dispatch, { onSelectionChange = noop }: OwnProps): DispatchProps => ({
  onFetchApplications: () => dispatch(fetchApplicationsAction.started(null)),
  onSelectionChange: (applicationId: ApplicationEnum) => onSelectionChange(applicationId),
});

export const ApplicationSelectContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSelect);
