import { IdNames } from "common/common.typings";
import { isStringEmpty } from "utils/strings/stringUtils";
import { filter } from "lodash";

/**
 * filters an array of IdName according to a search term
 * @param items : array of IdName
 * @param filterText : search filter
 */
export const getFilteredItems = (items: IdNames<string>, filterText: string): IdNames<string> =>
  isStringEmpty(filterText)
    ? items
    : filter(items, item => item.name && item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >= 0);
