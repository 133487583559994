import { Action, Reducer } from "redux";
import { isType } from "typescript-fsa";
import { Contact } from "containers/contact/contact.typings";
import { fetchContactByIdAction } from "containers/contact/contact.action";
import { terminateUserAccessAction, updateUserEmailAction } from "containers/user/detail/user.detail.action";
import reduceReducer from "reduce-reducers";

export const initialState: ContactState = {
  contact: {
    applications: {
      hasAccessToCprofit: false,
    },
  },
  isFetching: false,
  errorMessage: "",
};

export interface ContactState {
  contact: Contact;
  isFetching: boolean;
  errorMessage: string;
}

export const fetchContactByIdReducer: Reducer<ContactState, Action> = (
  state: ContactState = initialState,
  action: Action
): ContactState => {
  if (isType(action, fetchContactByIdAction.started)) {
    return { ...state, contact: null, isFetching: true };
  }
  if (isType(action, fetchContactByIdAction.done)) {
    return { ...state, contact: action.payload.result, isFetching: false };
  }
  if (isType(action, fetchContactByIdAction.failed)) {
    return { ...state, errorMessage: action.payload.error, contact: null, isFetching: false };
  }
  return state;
};

export const terminateUserAccessReducer = (state: ContactState, action): ContactState => {
  if (isType(action, terminateUserAccessAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, terminateUserAccessAction.done)) {
    return { ...state, contact: { ...state.contact, applications: action.payload.result }, isFetching: false };
  }
  if (isType(action, terminateUserAccessAction.failed)) {
    return {
      ...state,
      errorMessage: action.payload.error,
      contact: { ...state.contact, applications: null },
      isFetching: false,
    };
  }
  return state;
};

export const updateUserEmailReducer = (state: ContactState, action): ContactState => {
  if (isType(action, updateUserEmailAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, updateUserEmailAction.done)) {
    return { ...state, contact: { ...state.contact, cerberusUserEmail: action.payload.result }, isFetching: false };
  }
  if (isType(action, updateUserEmailAction.failed)) {
    return {
      ...state,
      errorMessage: action.payload.error,
      contact: { ...state.contact, cerberusUserEmail: null },
      isFetching: false,
    };
  }
  return state;
};

export const contactReducer = reduceReducer(
  fetchContactByIdReducer,
  terminateUserAccessReducer,
  updateUserEmailReducer
);
