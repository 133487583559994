import { NavLink, NavLinkProps } from "react-router-dom";
import * as React from "react";

export interface Props extends NavLinkProps {
  containerClass?: string;
}

export const NavItem: React.FunctionComponent<Props> = ({
  to,
  title,
  exact,
  containerClass,
  children,
  hidden = false,
}) => {
  if (hidden) {
    return <></>;
  }
  if (children) {
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {title}
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          {children}
        </div>
      </li>
    );
  }

  return (
    <li className={containerClass ? containerClass : "nav-item"}>
      <NavLink exact={exact} to={to} className="nav-link" activeClassName="active">
        {title}
      </NavLink>
    </li>
  );
};
