import actionCreatorFactory from "typescript-fsa";
import { UserRequestClaims } from "../request.typings";

const enum RequestValidateActionName {
  VALIDATE_REQUEST = "VALIDATE_REQUEST",
}

const requestActionFactory = actionCreatorFactory("RequestValidate");

export const validateRequestAction = requestActionFactory.async<UserRequestClaims, void, string>(
  RequestValidateActionName.VALIDATE_REQUEST
);
