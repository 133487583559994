import * as React from "react";

export interface ContactsPickerProps {
  selectedId?: string;
  onChange: (items: WidgetContactDTO) => void;
}

export interface WidgetContactDTO {
  detail: {
    contact: {
      id: string;
      firstname: string;
      lastname: string;
      name: string;
      email: string;
      icId: string;
      bdrCommercialId: string;
      bdrLegalId: string;
    };
  };
}

export const ContactsPicker: React.FunctionComponent<ContactsPickerProps> = ({
  selectedId,
  onChange,
}: ContactsPickerProps) => {
  const elementRef = React.useRef<any>(null);

  const callbackRef = React.useRef((value: any) => onChange(value));

  React.useLayoutEffect(() => {
    callbackRef.current = (value: any) => onChange(value);
  });
  React.useEffect(() => {
    const handleSelect = (event: WidgetContactDTO) => {
      callbackRef.current(event);
    };
    const contactPicker = elementRef.current;
    if (contactPicker) {
      contactPicker.addEventListener("ic-contact-single-picker--changed", handleSelect);
    }

    return () => {
      contactPicker.removeEventListener("ic-contact-single-picker--changed", handleSelect);
    };
  }, []);
  return (
    <ic-contact-single-picker ref={elementRef} selected-id={selectedId} internal-only={true} display-option="email" />
  );
};
