import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { fetchExportRequest } from "api/request/request.api";
import { fetchAllContactsByIds } from "api/contacts/contact.api";
import { mapToExportUserRequests } from "api/request/request.mapper";
import { fetchExportRequestAction } from "./request.export.action";
import { IcHttpResquestHeader } from "api/api.typings";
import { UserRequestExportDTO } from "api/request/request.typings";
import { exportToExcel } from "./request.export.helper";

const fetchUserByIds = (requests: UserRequestExportDTO[], header: IcHttpResquestHeader) =>
  fetchAllContactsByIds(requests, header).pipe(
    map(contacts => mapToExportUserRequests(requests, contacts)),
    map(finalResult => exportToExcel(finalResult)),
    map(exportResult => fetchExportRequestAction.done({ result: exportResult, params: null })),
    catchError(({ message }) => of(fetchExportRequestAction.failed({ error: message, params: null })))
  );
export const fetchExportRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchExportRequestAction.started.match),
    switchMap(({ meta }) =>
      fetchExportRequest(meta.headers).pipe(
        switchMap(requestsResponse => fetchUserByIds(requestsResponse.requests, meta.headers))
      )
    ),
    catchError(({ message }) => of(fetchExportRequestAction.failed({ error: message, params: null })))
  );

export const exportRequestEpic = fetchExportRequestEpic;
