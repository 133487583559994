import { connect } from "react-redux";
import { DispatchProps, RequestListPage, StateProps } from "components/Request/RequestList/RequestListPage";
import { GlobalState } from "store/store.typings";
import { fetchRequestsAction } from "containers/request/list/request.list.action";
import { RequestListFilter } from "containers/request/list/request.list.typings";

const mapStateToProps = ({ requestState, connectedUserProfile: userProfile }: GlobalState): StateProps => ({
  userRight: userProfile.userInfo.userRight,
  requests: requestState.requestList.requestPage.requests,
  isFetching: requestState.requestList.isFetching,
  hasNextPage: requestState.requestList.requestPage.hasNextPage,
  hasPreviousPage: requestState.requestList.requestPage.hasPreviousPage,
  applicationId: requestState.requestList.applicationId,
  statusId: requestState.requestList.statusId,
  term: requestState.requestList.term,
  page: requestState.requestList.page,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onFetchRequests: (filter: RequestListFilter) => dispatch(fetchRequestsAction.started(filter)),
});

export const RequestListContainer = connect(mapStateToProps, mapDispatchToProps)(RequestListPage);
