import * as React from "react";
import { Badge } from "components/common/Badge/Badge";

interface Props {
  comment: string;
}

export const JustificationField: React.FunctionComponent<Props> = ({ comment }: Props) => {
  return comment ? (
    <Badge
      type={"warning"}
      prependSquare={true}
      value={"justification"}
      outline={true}
      dataToggle={"tooltip"}
      dataPlacement={"top"}
      title={comment}
    />
  ) : (
    <span />
  );
};
