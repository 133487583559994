import * as React from "react";

export interface CheckboxProps {
  /**
   * this dictates action to dispatch on change
   */
  onChange: (checked: boolean) => void;
  /**
   * this dictates if checkbox is checked at init
   */
  checked?: boolean;
  /**
   * checkbox id
   */
  id: string;
  /**
   * label aside the checkbox
   */
  label?: string;
  /**
   * this dictates if checkbox is disabled
   */
  disabled?: boolean;

  /**
   * custom boostrap classes applied to the root node
   */
  classNames?: string;
}

export interface State {
  previousChecked: boolean;
  checked: boolean;
}

export class Checkbox extends React.Component<CheckboxProps, State> {
  public static getDerivedStateFromProps(props: CheckboxProps, state: State): State {
    if (props.checked !== state.previousChecked) {
      return { previousChecked: props.checked, checked: props.checked };
    }
    return null;
  }
  constructor(props: CheckboxProps) {
    super(props);
    this.state = { previousChecked: false, checked: this.props.checked || false };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  public render() {
    const { label, id, disabled, classNames } = this.props;
    return (
      <div className={`custom-control custom-checkbox ${classNames || "d-flex align-items-center"}`}>
        <input
          id={id}
          name={label}
          className="custom-control-input"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleOnChange}
          disabled={disabled || false}
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }

  private handleOnChange(event: any) {
    const checked = event.target.checked;
    this.setState(
      state => ({ ...state, checked }),
      () => this.props.onChange(this.state.checked)
    );
  }
}
