import { ClaimDTO, DomainEnum } from "api/request/request.typings";
import { find, filter, map, groupBy, uniq, flatMap, indexOf } from "lodash";
import { UserVisibility, SetSettings, AxisSettings } from "./request.visibility.typings";
import { ApplicationEnum } from "containers/referential/referential.typings";
import {
  VisibilityRequestDTO,
  RejectRequestDTO,
  ValidateRequestDTO,
} from "api/request/visibility/visibilitySet/request.visibility.set.typings";

const webModulesDomains: DomainEnum[] = [
  DomainEnum.MASSUPLOAD,
  DomainEnum.CORRECTION,
  DomainEnum.PSM,
  DomainEnum.FORECAST,
  DomainEnum.WEBMODULES,
  DomainEnum.DATACOLLECT
];

export const mapClaimsDtoToSettings = (claimsDTO: ClaimDTO[], applicationId?: ApplicationEnum): UserVisibility => {
  return {
    sgViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.SGVIEW]),
    markViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.MARKVIEW]),
    extendedSgViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.EXTENDEDSGVIEW]),
    extendedMarkViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.EXTENDEDMARKVIEW]),
    asofViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.ASOFVIEW]),
    facetViewSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.FACETVIEW]),
    webModulesSettings: mapClaimsToAxis(claimsDTO, webModulesDomains),
    dataQualitySettings: mapClaimsToAxis(claimsDTO, [DomainEnum.DATAQUALITY]),
    clientContributionSettings: mapClaimsToAxis(claimsDTO, [DomainEnum.CLIENTCONTRIBUTION]),
    applicationId,
  };
};

const mapClaimsToAxis = (claimsDTO: ClaimDTO[], domains: DomainEnum[]): SetSettings[] =>
  mapClaimsDtoToSetSettings(
    filter(claimsDTO, elem => domains.indexOf(elem.domainId) !== -1),
    find(domains, item => indexOf(webModulesDomains, item) !== -1) !== undefined
  );

const mapClaimsDtoToSetSettings = (claims: ClaimDTO[], areWebmodulesClaims: boolean): SetSettings[] => {
  const setIds = uniq(map(claims, ({ claimSet }) => claimSet));
  return map(setIds, setId =>
    mapToSetSettings(
      filter(claims, claim => claim.claimSet === setId),
      setId,
      areWebmodulesClaims ? "domainId" : "axisId"
    )
  );
};

const mapToSetSettings = (claims: ClaimDTO[], claimSet: number, toGroupWith: string): SetSettings => ({
  setId: claimSet,
  settings: map(mapClaimDtoToAxisSettings(groupBy(claims, toGroupWith))),
});

const mapClaimDtoToAxisSettings = (claims: object): AxisSettings[] => {
  return flatMap(claims, (claimList: ClaimDTO[], key) =>
    map(groupBy(claimList, "claimId"), (groupedClaims: ClaimDTO[], claimId) => ({
      axisName: indexOf(webModulesDomains, key) === -1 ? key : undefined,
      claim: {
        id: claimId,
        values: map(groupedClaims, ({ claimValue }) => claimValue),
        label: groupedClaims[0].claim,
        domainId: groupedClaims[0].domainId,
        operator: groupedClaims[0].operator
      },
    }))
  );
};

const mapToClaimsDTO = (settings: SetSettings[]) =>
  flatMap(settings, setSetting =>
    flatMap(setSetting.settings, ({ claim }) =>
      map(claim.values, value => ({
        claimId: claim.id,
        claimValue: value,
        domainId: claim.domainId,
        claimSet: setSetting.setId,
        operator: claim.operator ?? "equal"
      }))
    )
  );

export const mapTovisibilityRequestDTO = (allSettings: SetSettings[], requestId: string, koreanDataFlag: boolean | null, koreanDataComment: string | null): VisibilityRequestDTO => {
  const claims = mapToClaimsDTO(allSettings);
  return { userComment: "", claims, id: requestId, needKoreanData: koreanDataFlag, koreanDataComment: koreanDataFlag ? koreanDataComment : null };
};

export const mapToRejectRequestDTO = (id: string, rejectionComment: string): RejectRequestDTO => ({
  id,
  rejectionComment,
});

export const mapToValidateRequestDTO = (id: string, validatorComment: string): ValidateRequestDTO => ({
  id,
  validatorComment,
});
