import { fetchUserInfo } from "./userProfile.action";
import { Action, Reducer } from "redux";
import { DefaultUserProfile, UserProfile } from "containers/userProfile/userProfile.typings";
import { isType } from "typescript-fsa";

const initialState: UserProfileState = {
  userInfo: DefaultUserProfile,
  loggedIn: false,
  error: null,
};

export interface UserProfileState {
  userInfo: UserProfile;
  loggedIn: boolean;
  error: string;
}

export const userProfileReducer: Reducer<UserProfileState, Action> = (
  state = initialState,
  action: Action
): UserProfileState => {
  if (isType(action, fetchUserInfo.started)) {
    return { ...state, error: null, userInfo: DefaultUserProfile, loggedIn: false };
  }
  if (isType(action, fetchUserInfo.done)) {
    return { ...state, userInfo: action.payload.result, loggedIn: true };
  }
  if (isType(action, fetchUserInfo.failed)) {
    return { ...state, error: action.payload.error, loggedIn: false };
  }
  return state;
};
