import { DimensionDTO } from "./request.visibility.set.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { get, map } from "lodash";
import { IdName } from "common/common.typings";
import { ViewSettingEnum } from "components/Request/RequestValidate/Tabs/VisibilitySet";

export const mapToDimension = (
  setId: number,
  axisLabel: string,
  viewSettingType: ViewSettingEnum,
  dimensionDto: DimensionDTO
): Dimension => ({
  axisLabel,
  setId,
  claimId: get(dimensionDto, "attribute.id"),
  values: map(
    dimensionDto.values,
    (value): IdName<string> => {
      return { id: value, name: value };
    }
  ),
  type: viewSettingType,
});
