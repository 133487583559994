import * as React from "react";
import { Status, StatusEnum } from "containers/referential/referential.typings";
import { noop } from "lodash";
import { Select } from "components/Select/Select";

export interface StateProps {
  selectedId?: StatusEnum;
  isAllShown?: boolean;
  statuses: Status[];
}

export interface DispatchProps {
  onFetchStatuses: () => void;
  onSelectionChange?: (selectionId: StatusEnum) => void;
}

export type Props = StateProps & DispatchProps;

export class StatusSelect extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    this.props.onFetchStatuses();
  }

  public render() {
    const { selectedId = null, statuses, onSelectionChange = noop, isAllShown = false } = this.props;
    const options = isAllShown ? [{ id: StatusEnum.ALL, label: "All" }, ...statuses] : statuses;
    return (
      <Select<Status, StatusEnum> options={options} selectedId={selectedId} onSelectionChange={onSelectionChange} />
    );
  }
}
