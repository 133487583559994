import * as React from "react";
import { SubmitButton } from "components/common/Buttons/SubmitButton";
import { CancelButton } from "components/common/Buttons/CancelButton";

export interface Props {
  cloneLabel?: string;
  submitLabel?: string;
  validateLabel?: string;
  rejectLabel?: string;
  onSubmit?: () => void;
  onClone?: () => void;
  onValidate?: () => void;
  onReject?: () => void;
}
export const FormActions: React.FunctionComponent<Props> = ({
  submitLabel,
  onSubmit,
  cloneLabel,
  onClone,
  validateLabel,
  onValidate,
  onReject,
  rejectLabel,
}) => (
  <div className="card-body col pt-0">
    <div className="col">
      <div className="form-group">
        <div className="btn-group float-right" role="group">
          <CancelButton onClick={onClone} label={cloneLabel} className="btn btn-lg btn-outline-primary mr-2" />
          <SubmitButton onClick={onSubmit} label={submitLabel} />
          <SubmitButton onClick={onValidate} label={validateLabel} className="btn btn-lg btn-secondary mr-2" />
          <SubmitButton onClick={onReject} label={rejectLabel} className="btn btn-lg btn-danger" />
        </div>
      </div>
    </div>
  </div>
);
