import * as React from "react";

export interface Props {
  iconName?: string;
  iconClass?: string;
}

export const InputPrefix: React.FunctionComponent<Props> = ({ children, iconClass, iconName }) => (
  <div className="input-group-prepend input-group-merged">
    <i className={`icon text-secondary ${iconClass || ""}`}>{iconName || children}</i>
  </div>
);
