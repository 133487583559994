import { mapToContact, mapToExtendedContact, mapToUserProfile } from "api/contacts/contact.mapper";
import { UserProfile } from "containers/userProfile/userProfile.typings";
import { createRepository } from "api/api";
import { Observable, of, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { map as lodashMap, uniq, chunk, flatten, get, first } from "lodash";
import { IcHttpResquestHeader } from "api/api.typings";
import { stringifyLists } from "utils/strings/stringify";
import { Contact } from "containers/contact/contact.typings";
import { ContactProperty, ContactListDTO, ContactDTO } from "api/contacts/contact.typings";
import { isStringEmpty } from "utils/strings/stringUtils";
import { UserRequestExportDTO } from "api/request/request.typings";

const defaultExtendedContactProperties: ContactProperty[] = ["addresses", "internalHrTeam", "country"];
const defaultContactByIdProperties = [
  "emails",
  "internalHrTeam",
  "organizationteam",
  "addresses",
  "civility",
  "jobTitle",
] as ContactProperty[];

export const requestMe = (email: string, headers: IcHttpResquestHeader): Observable<UserProfile> => {
  return createRepository()
    .get(`/sg/contacts`, { ...headers }, stringifyLists({ email }))
    .pipe(
      map(contactDto => mapToUserProfile(first(get(contactDto, "contacts")))),
      map(userProfile => ({ ...userProfile, email, userId: userProfile.id }))
    );
};

export const fetchContactsByIds = (ids: string[], header: IcHttpResquestHeader): Observable<Contact[]> =>
  fetchExtendedContactsByIds(ids, header);

export const fetchContactById = (contactId: string, headers: IcHttpResquestHeader): Observable<Contact> =>
  isStringEmpty(contactId)
    ? of(null)
    : createRepository()
        .get<ContactDTO>(`/sg/contacts/${contactId}`, headers, stringifyLists({ fields: defaultContactByIdProperties }))
        .pipe(map(contact => mapToContact(contact)));

export function fetchAllContactsByIds(
  requests: UserRequestExportDTO[],
  header: IcHttpResquestHeader
): Observable<Contact[]> {
  const contactIds = uniq(lodashMap(requests, ({ contactId }) => contactId));
  const chunksArray = chunk(contactIds, 50);
  const functionArray: Array<Observable<Contact[]>> = [];
  chunksArray.forEach(ids => functionArray.push(fetchExtendedContactsByIds(ids, header)));
  return forkJoin(functionArray).pipe<Contact[]>(map(x => flatten(x)));
}

export const fetchExtendedContactsByIds = (ids: string[], header: IcHttpResquestHeader): Observable<Contact[]> =>
  createRepository()
    .get<ContactListDTO>(`/sg/contacts`, header, {
      ids: uniq(ids),
      fields: defaultExtendedContactProperties.join(","),
      onlyActive: false,
    })
    .pipe<Contact[]>(map(contacts => lodashMap(get(contacts, "contacts"), mapToExtendedContact)));
