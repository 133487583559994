import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import {
  DispatchProps,
  StateProps,
  CloneContactViewer,
} from "components/Request/RequestValidate/Tabs/CloneContactViewer";
import { fetchContactByIdAction } from "containers/contact/contact.action";

interface OwnProps {
  contactId: string;
}

const mapStateToProps = ({ contactState }: GlobalState, ownProps: OwnProps): StateProps => ({
  contactId: ownProps.contactId,
  contact: contactState.contact,
  isFetching: contactState.isFetching,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onFetchContactById: (contactId: string) => dispatch(fetchContactByIdAction.started(contactId)),
});

export const CloneContactViewContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(CloneContactViewer);
