import { ActionsObservable, combineEpics, Epic, StateObservable } from "redux-observable";
import { Action } from "redux";
import { GlobalState } from "store/store.typings";
import { of } from "rxjs";
import { filter } from "rxjs/internal/operators/filter";
import {
  fetchApplicationsAction,
  fetchRolesAction,
  fetchStatusesAction,
} from "containers/referential/referential.action";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { isEmpty } from "lodash";
import { fetchApplications, fetchRoles, fetchStatuses } from "api/referential/referential.api";

export const fetchApplicationsEpic: Epic<Action> = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<GlobalState>
) =>
  action$.pipe(
    filter(fetchApplicationsAction.started.match),
    withLatestFrom(state$),
    switchMap(([{ meta }, { referential }]) =>
      isEmpty(referential.applications)
        ? fetchApplications(meta.headers).pipe(
            map(refApplication => fetchApplicationsAction.done({ result: refApplication.items, params: null })),
            catchError(({ message }) => of(fetchApplicationsAction.failed({ error: message, params: null })))
          )
        : of(fetchApplicationsAction.done({ result: referential.applications, params: null }))
    )
  );

export const fetchStatusesEpic: Epic<Action> = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<GlobalState>
) =>
  action$.pipe(
    filter(fetchStatusesAction.started.match),
    withLatestFrom(state$),
    switchMap(([{ meta }, { referential }]) =>
      isEmpty(referential.statuses)
        ? fetchStatuses(meta.headers).pipe(
            map(refStatuses => fetchStatusesAction.done({ result: refStatuses.items, params: null })),
            catchError(({ message }) => of(fetchStatusesAction.failed({ error: message, params: null })))
          )
        : of(fetchStatusesAction.done({ result: referential.statuses, params: null }))
    )
  );

export const fetchRolesEpic: Epic<Action> = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<GlobalState>
) =>
  action$.pipe(
    filter(fetchRolesAction.started.match),
    withLatestFrom(state$),
    switchMap(([{ meta }, { referential }]) =>
      isEmpty(referential.roles)
        ? fetchRoles(meta.headers).pipe(
            map(refRoles => fetchRolesAction.done({ result: refRoles.items, params: null })),
            catchError(({ message }) => of(fetchRolesAction.failed({ error: message, params: null })))
          )
        : of(fetchRolesAction.done({ result: referential.roles, params: null }))
    )
  );

export const referentialEpic = combineEpics(fetchApplicationsEpic, fetchStatusesEpic, fetchRolesEpic);
