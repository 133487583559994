import * as React from "react";
import { map } from "lodash";

export const WebComponents: React.FunctionComponent = () => {
  const widgets = ["ic-contact-single-picker"];

  React.useEffect(() => {
    map(widgets, widget => {
      const script = document.createElement("script");
      script.src = `https://shared.sgmarkets.com/ic-widgets/v1/${widget}.js`;
      document.body.appendChild(script);
    });
  }, []);
  return null;
};
