import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { Dispatch } from "redux";
import { fetchStatusesAction } from "containers/referential/referential.action";
import { noop } from "lodash";
import { DispatchProps, StateProps, StatusSelect } from "components/Select/StatusSelect";
import { Status, StatusEnum } from "containers/referential/referential.typings";

export interface OwnProps {
  selectedId?: StatusEnum;
  isAllShown?: boolean;
  onSelectionChange?: (selectionId: StatusEnum) => void;
}

const mapStateToProps = ({ referential }: GlobalState, props: OwnProps): StateProps => ({
  statuses: referential.statuses,
  selectedId: props.selectedId,
  isAllShown: props.isAllShown,
});

const mapDispatchToProps = (dispatch: Dispatch, { onSelectionChange = noop }: OwnProps): DispatchProps => ({
  onFetchStatuses: () => dispatch(fetchStatusesAction.started(null)),
  onSelectionChange: (selectionId: StatusEnum) => onSelectionChange(selectionId),
});

export const StatusSelectContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(StatusSelect);
