import { Epic, ActionsObservable, StateObservable } from "redux-observable";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { Observable, of, forkJoin } from "rxjs";
import { fetchContactById } from "api/contacts/contact.api";
import { fetchContactByIdAction } from "containers/contact/contact.action";
import { fetchUserById } from "api/user/user.api";
import { mapUserToContact } from "api/contacts/contact.mapper";

export const fetchContactByIdEpic: Epic<Action> = (action$: Observable<Action>, state$: StateObservable<any>) =>
  action$.pipe(
    filter(fetchContactByIdAction.started.match),
    switchMap(({ payload, meta }) =>
      forkJoin(
        fetchContactById(payload, meta.headers).pipe(catchError(() => of(null))),
        fetchUserById(payload, meta.headers, state$.value.router.location.pathname === "/request/create").pipe(
          catchError(() => of(null))
        )
      ).pipe(
        map(([contact, user]) => {
          return fetchContactByIdAction.done({
            params: payload,
            result: mapUserToContact(contact, user),
          });
        }),
        catchError(({ message }) => of(fetchContactByIdAction.failed({ error: message, params: payload })))
      )
    )
  );

export const contactEpic = fetchContactByIdEpic;
