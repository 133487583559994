import actionCreatorFactory from "typescript-fsa";
import { UserRequestCreate, UserRequest } from "../request.typings";
import { UserRequestDTO } from "api/request/request.typings";

const enum RequestActionName {
  CREATE_REQUEST = "CREATE_REQUEST",
}

const requestActionFactory = actionCreatorFactory("requestCreate");

export const createRequestAction = requestActionFactory.async<UserRequestCreate, UserRequest, string>(
  RequestActionName.CREATE_REQUEST
);
