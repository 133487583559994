import actionCreatorFactory from "typescript-fsa";
import { Contact } from "containers/contact/contact.typings";

const actionCreator = actionCreatorFactory("contact");

export enum ContactActionName {
  FETCH_CONTACT_BY_ID = "FETCH_CONTACT_BY_ID",
}

export const fetchContactByIdAction = actionCreator.async<string, Contact, string>(
  ContactActionName.FETCH_CONTACT_BY_ID
);
