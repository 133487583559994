import * as React from "react";
import { ROUTES } from "common/routes";
import { ButtonLink } from "components/common/Buttons/ButtonLink";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";
import { generateUri } from "utils/uris/generateUri";

export const getNavigateToUrl = (
  requestId: number,
  applicationId: string,
  statusId: string,
  { canValidateCprofitRequest }: ConnectedUserProfileRights
) => {
  const canValidate =
    StatusEnum.PENDING === statusId && applicationId === ApplicationEnum.C_PROFIT && canValidateCprofitRequest;
  const route = generateUri(ROUTES.REQUEST_DETAIL, requestId);
  return <ButtonLink to={route} label={canValidate ? "Validate" : "Detail"} className="btn btn-md btn-primary" />;
};
