import * as React from "react";
import { map, find, isEmpty, get } from "lodash";
import { DomainEnum } from "api/request/request.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { getClaimOptions } from "./VisibilityWebModules";
import { Select } from "components/Select/Select";
import { IdAndLabel } from "common/common.typings";
import { Domain, SetSettings } from "containers/request/validate/requestVisibility/request.visibility.typings";
import * as Styles from "./ModuleProfileSelect.scss";
import { getClaimUrl } from "./visibility.state.logic";

export interface WebModuleProfileSelectProps {
  dimensions: Dimension[];
  onClaimValueChanged?: (domainId: DomainEnum, claimId: string, values: string[]) => void;
  allCheckboxesUnchecked: () => boolean;
  domainsConfig: Domain[];
  fetchClaimValues: (claimId: string, claimServiceUrl: string) => void;
  webModulesSettings: SetSettings[];
}

export const WebModuleProfileSelect: React.FunctionComponent<WebModuleProfileSelectProps> = ({
  dimensions,
  onClaimValueChanged,
  allCheckboxesUnchecked,
  domainsConfig,
  fetchClaimValues,
  webModulesSettings,
}) => {
  const onWebModulesProfileValueChanged = (selectedId: string) => {
    const profileClaimUrl = getClaimUrl(domainsConfig, DomainEnum.WEBMODULES, "profile");
    fetchClaimValues("profile", profileClaimUrl);
    onClaimValueChanged(DomainEnum.WEBMODULES, "profile", [selectedId]);
  };

  const checkClaimProfileExists = () => {
    if (!isEmpty(webModulesSettings) && !isEmpty(webModulesSettings[0].settings)) {
      return find(webModulesSettings[0].settings, setting => setting.claim.id === "profile");
    }
  };

  const selectProfileValue =
    !isEmpty(webModulesSettings) &&
    get(
      find(webModulesSettings[0].settings, x => x.claim.id === "profile"),
      "claim"
    ) !== undefined &&
    get(
      find(webModulesSettings[0].settings, x => x.claim.id === "profile"),
      "claim"
    ).values[0];

  return (
    <div className="col-12">
      <div className="row align-items-center my-2">
        <label className="text-left"> Profile : </label>
        <div className="col-md-6 col-sm-12 ml-5">
          <div className="col-6 ml-1">
            <fieldset disabled={allCheckboxesUnchecked()} className={Styles.SetWidth}>
              <div className="row align-items-center my-2">
                <Select<IdAndLabel<string>, string>
                  options={map(getClaimOptions(dimensions, "profile"), value => ({
                    id: value.id,
                    label: value.name,
                  }))}
                  selectedId={!allCheckboxesUnchecked() && !checkClaimProfileExists() ? "" : selectProfileValue}
                  onSelectionChange={onWebModulesProfileValueChanged}
                />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};
