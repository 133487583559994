import { RequestVisibilityState, initState } from "../request.visibility.reducer";
import { isType } from "typescript-fsa";
import { filter } from "lodash";
import reduceReducer from "reduce-reducers";
import { fetchClientContributionProfilesClaimValuesAction } from "./request.clientcontribution.action";

export const fetchClientContributionVisibilityClaimValuesReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchClientContributionProfilesClaimValuesAction.started)) {
    return { ...state, isClaimValuesFetching: true, errorMessage: null };
  }
  if (isType(action, fetchClientContributionProfilesClaimValuesAction.done)) {
    const rest = filter(state.dimensions, ({ axisLabel }) => axisLabel !== action.payload.result.axisLabel);
    return { ...state, dimensions: [...rest, action.payload.result], isClaimValuesFetching: false };
  }
  if (isType(action, fetchClientContributionProfilesClaimValuesAction.failed)) {
    return { ...state, isClaimValuesFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const requestClientContributionReducer = reduceReducer(fetchClientContributionVisibilityClaimValuesReducer);
