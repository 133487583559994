import { connect } from "react-redux";
import { SgHeader, StateProps } from "components/layout/Header/SgHeader";
import { Environment } from "common/environment";

interface OwnProps {
  environment?: Environment;
}

const mapStateToProps = (ownProps: OwnProps): StateProps => ({
  environment: ownProps.environment,
});

export const HeaderContainer = connect<StateProps, any, OwnProps>(mapStateToProps)(SgHeader);
