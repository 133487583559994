import { ActionsObservable } from "redux-observable";
import { fetchRequestByIdAction } from "./request.detail.action";
import { forkJoin, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { fetchUserRequestById } from "api/request/request.api";
import { fetchContactById, fetchContactsByIds } from "api/contacts/contact.api";
import { mapToRequestDetail } from "api/request/request.mapper";

export const fetchRequestDetailEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchRequestByIdAction.started.match),
    switchMap(({ payload, meta }) =>
      fetchUserRequestById(payload, meta.headers).pipe(
        switchMap(userRequestDetail =>
          forkJoin(
            fetchContactById(userRequestDetail.userId, meta.headers).pipe(catchError(() => of(null))),
            fetchContactsByIds([userRequestDetail.validatorId, userRequestDetail.modifiedUserId, userRequestDetail.requestorId], meta.headers).pipe(
              catchError(() => of(null))
            )
          ).pipe(
            map(([user, contacts]) =>
              fetchRequestByIdAction.done({
                result: mapToRequestDetail(userRequestDetail, user, contacts),
                params: payload,
              })
            ),
            catchError(({ message }) => of(fetchRequestByIdAction.failed({ error: message, params: payload })))
          )
        ),
        catchError(({ message }) => of(fetchRequestByIdAction.failed({ error: message, params: payload })))
      )
    )
  );

export const requestDetailEpic = fetchRequestDetailEpic;
