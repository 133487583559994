import * as React from "react";
import { VisibilityClaim } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { ViewSettingsTagList } from "./ViewSettingsTagList";
import { SectionTitle } from "components/common/Title/SectionTitle";
import { Rating } from "components/common/Rating/Rating";
import { WarningAlertMessage } from "components/common/WarningAlertMessage";

export interface VisibilityTagListProps {
  sgViewClaims?: VisibilityClaim[];
  markViewClaims?: VisibilityClaim[];
  webModulesClaims?: VisibilityClaim[];
  extendedSgViewClaims?: VisibilityClaim[];
  extendedMarkViewClaims?: VisibilityClaim[];
  asOfViewClaims?: VisibilityClaim[];
  facetViewClaims?: VisibilityClaim[];
  showTitle?: boolean;
  dataQualityClaims?: VisibilityClaim[];
  clientContributionClaims?: VisibilityClaim[];
}

export class VisibilityTagList extends React.Component<VisibilityTagListProps> {
  public constructor(props: VisibilityTagListProps) {
    super(props);
  }

  public render() {
    const {
      sgViewClaims,
      markViewClaims,
      webModulesClaims,
      extendedSgViewClaims,
      extendedMarkViewClaims,
      asOfViewClaims,
      facetViewClaims,
      showTitle,
      dataQualityClaims,
      clientContributionClaims,
    } = this.props;
    return (
      <>
        {(sgViewClaims || markViewClaims) && (
          <>
            {showTitle && (
              <div className="row">
                <div className="d-inline pb-4 pl-4">
                  <span>
                    <SectionTitle size="5" title="Main data" />
                  </span>
                </div>
              </div>
            )}
            <>
              {!showTitle && (
                <div className="pl-4 pb-4">
                  <WarningAlertMessage />
                </div>
              )}
            </>
            <div className="pl-4 pb-4">
              <div style={{ display: "inline-grid", gridTemplateColumns: "auto auto", rowGap: "1rem" }}>
                <div className="pt-4">
                  <ViewSettingsTagList
                    key="sgviewtaglist"
                    label="SG view"
                    settings={sgViewClaims}
                    displayOperator={true}
                  />
                </div>
                {this.showRating(sgViewClaims) ? (
                  <div className="ml-5">
                    <Rating title="Time response" rating={this.calculateRating(sgViewClaims)}></Rating>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="pt-4">
                  <ViewSettingsTagList
                    key="markviewtaglist"
                    label="Mark view"
                    settings={markViewClaims}
                    displayOperator={true}
                  />
                </div>
                {this.showRating(markViewClaims) ? (
                  <div className="ml-5">
                    <Rating title="Time response" rating={this.calculateRating(markViewClaims)}></Rating>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </>
        )}

        {webModulesClaims && (
          <div className="pl-4 pb-4">
            {showTitle && (
              <div className="row">
                <div className="d-inline pb-4">
                  <span>
                    <SectionTitle size="5" title="Webmodules" />
                  </span>
                </div>
              </div>
            )}
            <ViewSettingsTagList
              key="webmodulestaglist"
              label="Webmodules"
              settings={webModulesClaims}
              displayOperator={false}
            />
          </div>
        )}

        {(extendedSgViewClaims || extendedMarkViewClaims || asOfViewClaims || facetViewClaims) && (
          <>
            {showTitle && (
              <div className="row">
                <div className="d-inline pb-4 pl-4">
                  <span>
                    <SectionTitle size="5" title="Extended data" />
                  </span>
                </div>
              </div>
            )}
            <>
              {!showTitle && (
                <div className="pl-4 pb-4">
                  <WarningAlertMessage />
                </div>
              )}
            </>
            <div className="pl-4 pb-4">
              <div style={{ display: "inline-grid", gridTemplateColumns: "auto auto", rowGap: "1rem" }}>
                <div className="pt-4">
                  <ViewSettingsTagList
                    key="sgextendedviewtaglist"
                    label="SG extended view"
                    settings={extendedSgViewClaims}
                    displayOperator={true}
                  />
                </div>
                {this.showRating(extendedSgViewClaims) ? (
                  <div className="ml-5">
                    <Rating title="Time response" rating={this.calculateRating(extendedSgViewClaims)}></Rating>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="pt-4">
                  <ViewSettingsTagList
                    key="markextendedviewtaglist"
                    label="Mark extended view"
                    settings={extendedMarkViewClaims}
                    displayOperator={true}
                  />
                </div>
                {this.showRating(extendedMarkViewClaims) ? (
                  <div className="ml-5">
                    <Rating title="Time response" rating={this.calculateRating(extendedMarkViewClaims)}></Rating>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div className="pl-4 pb-4">
              <ViewSettingsTagList
                key="asofviewtaglist"
                label="As of view"
                settings={asOfViewClaims}
                displayOperator={true}
              />
            </div>
            <div className="pl-4 pb-4">
              <ViewSettingsTagList
                key="facetviewtaglist"
                label="Facet view"
                settings={facetViewClaims}
                displayOperator={false}
              />
            </div>
          </>
        )}

        {dataQualityClaims && (
          <>
            <div className="pl-4 pb-4">
              {showTitle && (
                <div className="row">
                  <div className="d-inline pb-4">
                    <span>
                      <SectionTitle size="5" title="Data Quality" />
                    </span>
                  </div>
                </div>
              )}
              <ViewSettingsTagList
                key="dataqualitytaglist"
                label="Data Quality"
                settings={dataQualityClaims}
                displayOperator={false}
              />
            </div>
          </>
        )}
        {clientContributionClaims && (
          <>
            <div className="pl-4 pb-4">
              {showTitle && (
                <div className="row">
                  <div className="d-inline pb-4">
                    <span>
                      <SectionTitle size="5" title="Client Contribution" />
                    </span>
                  </div>
                </div>
              )}
              <ViewSettingsTagList
                key="clientcontributiontaglist"
                label="Client Contribution"
                settings={clientContributionClaims}
                displayOperator={false}
              />
            </div>
          </>
        )}
      </>
    );
  }

  private readonly showRating = (claims: VisibilityClaim[]): boolean => {
    return claims[0]?.claims[0]?.label !== "No access" ?? false;
  };

  private readonly calculateRating = (settings: VisibilityClaim[]): Rating | undefined => {
    const claims = settings
      .map(s => s.claims)
      .reduce((a, b) => a.concat(b), [])
      .filter(ct => ct.values.length > 0);

    if (claims.findIndex(c => c.label === "No access") !== -1) {
      return undefined;
    }

    const setIdCount = settings.map(s => s.setId).length;
    const andCount = claims.map(c => c.label).length - setIdCount;
    const orCount = claims.map(c => c.values).reduce((a, b) => a.concat(b), []).length - andCount - 1;

    if (orCount > 3) {
      return "E";
    }

    if (orCount >= 1) {
      if (andCount >= 1) {
        return "D";
      }
      return "C";
    }

    if (andCount >= 1) {
      return "B";
    }

    return "A";
  };
}
