import { Switch, Route } from "react-router";
import { NotFound } from "../../components/layout/NotFound/NotFound";
import { ROUTES } from "../../common/routes";
import * as React from "react";
import { UserDetailContainer } from "./detail/user.detail.container";

export const UserRoute = () => (
  <Switch>
    <Route path={ROUTES.USER_DETAIL} component={UserDetailContainer} />
    <Route component={NotFound} />
  </Switch>
);
