import { AxisDTO, DomainDTO } from "./request.visibility.typings";
import { Axis, Domain } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { map, filter } from "lodash";

export const mapToDomains = (x: DomainDTO[]): Domain[] => map(x, domain => mapDomainDtoToView(domain));

export const mapDomainDtoToView = ({ domainId, domainValues, domainLabel }: DomainDTO): Domain => ({
  domainId,
  domainLabel,
  domainValues: mapDtosToView(domainValues),
});

export const mapDtosToView = (axises: AxisDTO[]) => map(axises, mapDtoToView);

export const mapDtoToView = ({ axisLabel, axisValues, axisId }: AxisDTO): Axis => ({
  axisId,
  axisLabel,
  values: axisValues,
});
