import { IdAndLabel } from "common/common.typings";
import { Select } from "components/Select/Select";
import TextArea from "components/common/TextArea/TextArea";
import * as React from "react";

export interface Props {
  koreanDataFlag: boolean | null;
  koreanDataComment: string | null;
  onKoreanDataFlagChange?: (flag: string) => void;
  onKoreanDataCommentChange?: (comment: string) => void;
}

export default class KoreanDataSetting extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {
      koreanDataFlag,
      koreanDataComment,
      onKoreanDataFlagChange: onKoreanDataFlagClick,
      onKoreanDataCommentChange: handleOnKoreanDataComment,
    } = this.props;
    return (
      <>
        <div className="row pl-4 pb-1">
          <b className="col-md-auto">Need access to Korean data</b>
          <div className="col-1">
            <Select<IdAndLabel<string>, string>
              options={[
                { id: "null", label: "" },
                { id: "true", label: "Yes" },
                { id: "false", label: "No" },
              ]}
              onSelectionChange={id => onKoreanDataFlagClick(id)}
              selectedId={koreanDataFlag === null ? "null" : koreanDataFlag === true ? "true" : "false"}
            />
          </div>
          {koreanDataFlag ? (
            <div className="col-4">
              <TextArea
                id="korean_data_comment"
                onChange={handleOnKoreanDataComment}
                placeholder={"Comment on Korean Data"}
                value={koreanDataComment}
                rows={2}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="pl-4">
          <b className="pr-1">Warning:</b> To meet the Regulatory requirements visibility to Korean data should have a
          valid justification. If not this can lead to Regulatory actions. Korean data refers to transaction information
          with client name, product information and SG entity name.
        </div>
      </>
    );
  }
}
