import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { EmptyClaimId } from "components/Request/RequestValidate/Tabs/SelectAxis";
import {
  fetchWebModulesSuppliersClaimValuesAction,
  fetchWebModulesProfilesClaimValuesAction,
  fetchWebModulesSBLClaimValuesAction
} from "./request.webmodules.action";
import { SetIdEnum } from "../request.visibility.typings";
import {
  fetchWebModulesProfilesClaimValues,
  fetchWebModulesSBLClaimValues,
  fetchWebModulesSuppliersClaimValues,
} from "api/request/visibility/webModules/request.webmodules.api";
import { ProfilesDTO, SblDTO, SuppliersDTO } from "api/request/visibility/webModules/request.webmodules.typings";
import {
  mapProfilesToDimension,
  mapSBLsToDimension,
  mapSuppliersToDimension,
} from "api/request/visibility/webModules/request.webmodules.mapper";

export const fetchWebModulesProfilesClaimValuesEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchWebModulesProfilesClaimValuesAction.started.match),
    switchMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchWebModulesProfilesClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ profiles: [] } as ProfilesDTO)
      ).pipe(
        map(dimensionDto =>
          fetchWebModulesProfilesClaimValuesAction.done({
            result: mapProfilesToDimension(SetIdEnum.One, payload.claimId, dimensionDto),
            params: undefined,
          })
        ),
        catchError(({ message }) =>
          of(fetchWebModulesProfilesClaimValuesAction.failed({ error: message, params: payload }))
        )
      )
    )
  );

export const fetchWebModulesSuppliersClaimValuesEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(fetchWebModulesSuppliersClaimValuesAction.started.match),
    switchMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchWebModulesSuppliersClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ suppliers: [] } as SuppliersDTO)
      ).pipe(
        map(suppliersDto =>
          fetchWebModulesSuppliersClaimValuesAction.done({
            result: mapSuppliersToDimension(SetIdEnum.One, payload.claimId, suppliersDto),
            params: undefined,
          })
        ),
        catchError(({ message }) =>
          of(fetchWebModulesSuppliersClaimValuesAction.failed({ error: message, params: payload }))
        )
      )
    )
  );
}

export const fetchWebModulesSBLClaimValuesEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchWebModulesSBLClaimValuesAction.started.match),
    switchMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchWebModulesSBLClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ sbls: [] } as SblDTO)
      ).pipe(
        map(sblsDto =>
          fetchWebModulesSBLClaimValuesAction.done({
            result: mapSBLsToDimension(SetIdEnum.One, payload.claimId, sblsDto),
            params: undefined,
          })
        ),
        catchError(({ message }) => of(fetchWebModulesSBLClaimValuesAction.failed({ error: message, params: payload })))
      )
    )
  );
