import { combineReducers } from "redux";
import { requestListReducer, RequestListState } from "./list/request.list.reducer";
import { requestCreateReducer, RequestCreateState } from "./create/request.create.reducer";
import { RequestDetailState, requestDetailReducer } from "./detail/request.detail.reducer";
import { RequestValidateState, requestValidateReducer } from "./validate/request.validate.reducer";
import { RequestInfoState, requestInfoReducer } from "./validate/requestInfo/request.info.reducer";
import {
  requestVisbilityReducer,
  RequestVisibilityState,
} from "./validate/requestVisibility/request.visibility.reducer";

import { exportRequestReducer, ExportRequestState } from "./export/request.export.reducer";

export interface RequestState {
  requestList: RequestListState;
  requestCreate: RequestCreateState;
  requestDetail: RequestDetailState;
  requestValidate: RequestValidateState;
  requestInfo: RequestInfoState;
  requestVisibility: RequestVisibilityState;
  exportRequest: ExportRequestState;
}

export const requestReducer = combineReducers<RequestState>({
  requestList: requestListReducer,
  requestCreate: requestCreateReducer,
  requestDetail: requestDetailReducer,
  requestValidate: requestValidateReducer,
  requestInfo: requestInfoReducer,
  requestVisibility: requestVisbilityReducer,
  exportRequest: exportRequestReducer,
});
