import { Route, Switch } from "react-router";
import { NotFound } from "components/layout/NotFound/NotFound";
import { ROUTES } from "common/routes";
import * as React from "react";
import { RequestCreateContainer } from "./create/request.create.container";
import { RequestListContainer } from "./list/request.list.container";
import { RequestValidateContainer } from "./validate/request.validate.container";

export const RequestRoute = () => (
  <Switch>
    <Route exact={true} path={ROUTES.REQUEST_LIST} component={RequestListContainer} />
    <Route exact={true} path={ROUTES.REQUEST_CREATE} component={RequestCreateContainer} />
    <Route path={ROUTES.REQUEST_DETAIL} component={RequestValidateContainer} />
    <Route component={NotFound} />
  </Switch>
);
