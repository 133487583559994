import actionCreatorFactory from "typescript-fsa";
import { UserRequestDetail } from "containers/request/request.typings";
import { TerminateUserAccess, UpdateUserEmail } from "./user.detail.typings";
import { Applications } from "containers/userProfile/userProfile.typings";
import { KeyValue } from "api/request/request.typings";

export enum UserActionName {
  FETCH_USER_REQUESTS = "FETCH_USER_REQUESTS",
  TERMINATE_USER_ACCESS = "TERMINATE_USER_ACCESS",
  UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL",
  FETCH_USER_COVERAGE = "FETCH_USER_COVERAGE",
}

const userDetailActionCreator = actionCreatorFactory("user_details");

export const fetchUserRequestsAction = userDetailActionCreator.async<string, UserRequestDetail[], string>(
  UserActionName.FETCH_USER_REQUESTS
);

export const terminateUserAccessAction = userDetailActionCreator.async<TerminateUserAccess, Applications, string>(
  UserActionName.TERMINATE_USER_ACCESS
);

export const updateUserEmailAction = userDetailActionCreator.async<UpdateUserEmail, string, string>(
  UserActionName.UPDATE_USER_EMAIL
);

export const fetchUserCoverageAction = userDetailActionCreator.async<string, KeyValue[], string>(
  UserActionName.FETCH_USER_COVERAGE
);
