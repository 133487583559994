import { ApplicationEnum } from "containers/referential/referential.typings";
import { DomainEnum } from "api/request/request.typings";

export interface AxisValue {
  claimId: string;
  claimLabel: string;
  claimValuesUrl: string;
}
export interface Axis {
  axisId: string;
  axisLabel: string;
  values: AxisValue[];
}

export interface Domain {
  domainId: string;
  domainLabel: string;
  domainValues: Axis[];
}

export interface UserVisibility {
  sgViewSettings: SetSettings[];
  markViewSettings: SetSettings[];
  extendedSgViewSettings: SetSettings[];
  extendedMarkViewSettings: SetSettings[];
  facetViewSettings: SetSettings[];
  asofViewSettings: SetSettings[];
  webModulesSettings: SetSettings[];
  requestId?: string;
  applicationId?: ApplicationEnum;
  dataQualitySettings: SetSettings[];
  clientContributionSettings: SetSettings[];
}

export interface Claim {
  id: string;
  label: string;
  values: string[];
  domainId: DomainEnum;
  operator: string;
}

export interface ClaimTag extends Claim {
  color?: string;
}
export interface AxisSettings {
  axisName: string;
  claim: Claim;
}
export interface SetSettings {
  setId: SetIdEnum;
  settings: AxisSettings[];
}

export interface VisibilityClone {
  applicationId: ApplicationEnum;
  contactId: string;
}

export interface VisibilityClaim {
  setId: SetIdEnum;
  claims: ClaimTag[];
}

export enum SetIdEnum {
  One = 1,
  Two = 2,
}

export enum SgViewTagsColorEnum {
  success,
  primary,
  secondary,
  danger,
}

export enum MarkViewTagsColorEnum {
  warning,
  info,
  dark,
  light,
}

export enum VisibilitySettings {
  mainData = "main",
  webModules = "webmodules",
  extendedData = "extended",
  dataQuality = "dataquality",
  clientcontribution = "clientcontribution",
}

export const AlertMessage =
  "The access requests you are about to validate concern sensitive C2 classified data. Please ensure that the end-users have a legitimate need to access this information before validating their request.";
