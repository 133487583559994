import { isType } from "typescript-fsa";
import { fetchRequestsAction } from "./request.list.action";
import { RequestPage } from "../request.typings";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";

export interface RequestListState {
  requestPage: RequestPage;
  isFetching: boolean;
  page: number;
  applicationId?: ApplicationEnum;
  statusId?: StatusEnum;
  term?: string;
}

export const initRequestPageState: RequestListState = {
  requestPage: { requests: [], hasNextPage: false, hasPreviousPage: false },
  isFetching: false,
  page: 1,
};

export const fetchRequestsReducer = (state: RequestListState = initRequestPageState, action): RequestListState => {
  if (isType(action, fetchRequestsAction.started)) {
    return { ...state, isFetching: true };
  }
  if (isType(action, fetchRequestsAction.done)) {
    return {
      ...state,
      requestPage: action.payload.result,
      isFetching: false,
      applicationId: action.payload.params.applicationId,
      statusId: action.payload.params.statusId,
      term: action.payload.params.term,
      page: action.payload.params.page,
    };
  }
  if (isType(action, fetchRequestsAction.failed)) {
    return { ...state, isFetching: false, requestPage: { requests: [], hasNextPage: false, hasPreviousPage: false } };
  }
  return { ...state };
};

export const requestListReducer = fetchRequestsReducer;
