import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { RouteComponentProps } from "react-router";
import { RequestValidatePage, StateProps, DispatchProps } from "components/Request/RequestValidate/RequestValidatePage";
import { UserRequestClaims } from "../request.typings";
import { validateRequestAction } from "./request.validate.action";
import { isRequestReadOnly } from "components/Request/RequestValidate/requestValidateRights";

type OwnProps = RouteComponentProps<{ id: string; type: string }>;

const mapStateToProps = (
  { requestState, connectedUserProfile: userProfile }: GlobalState,
  props: OwnProps
): StateProps => ({
  requestId: props.match.params.id,
  errorMessage: requestState.requestValidate.errorMessage,
  request: requestState.requestDetail.request,
  isFetching: requestState.requestValidate.isFetching,
  userRight: userProfile.userInfo.userRight,
  isReadOnly: isRequestReadOnly(userProfile.userInfo.userRight, requestState.requestDetail.request),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onValidateRequest: (requestWithClaims: UserRequestClaims) =>
    dispatch(validateRequestAction.started(requestWithClaims)),
});

export const RequestValidateContainer = connect(mapStateToProps, mapDispatchToProps)(RequestValidatePage);
