import { RequestVisibilityState, initState } from "../request.visibility.reducer";
import { isType } from "typescript-fsa";
import { fetchClaimVluesAction } from "./request.visibility.set.action";
import { filter } from "lodash";
import reduceReducer from "reduce-reducers";

export const fetchVisibilityClaimValuesReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchClaimVluesAction.started)) {
    return { ...state, isClaimValuesFetching: true, errorMessage: null };
  }
  if (isType(action, fetchClaimVluesAction.done)) {
    const rest = filter(
      state.dimensions,
      ({ axisLabel, setId, type }) =>
        axisLabel !== action.payload.result.axisLabel ||
        setId !== action.payload.result.setId ||
        type !== action.payload.result.type
    );
    return { ...state, dimensions: [...rest, action.payload.result], isClaimValuesFetching: false };
  }
  if (isType(action, fetchClaimVluesAction.failed)) {
    return { ...state, isClaimValuesFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const requestVisibilitySetReducer = reduceReducer(fetchVisibilityClaimValuesReducer);
