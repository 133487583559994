import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { RequestCreatePage, StateProps, DispatchProps } from "components/Request/RequestCreate/RequestCreatePage";
import { createRequestAction } from "./request.create.action";
import { UserRequestCreate } from "../request.typings";

const mapStateToProps = ({
  requestState,
  connectedUserProfile: userProfile,
  contactState,
}: GlobalState): StateProps => ({
  userEmail: userProfile.userInfo.userEmail,
  isFetching: requestState.requestCreate.isFetching,
  actualRole: contactState.contact ? contactState.contact.actualRole : null,
  isCprofitNewUser: contactState.contact ? contactState.contact.isCprofitNewUser : null,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onCreateRequest: (request: UserRequestCreate) => dispatch(createRequestAction.started(request)),
});

export const RequestCreateContainer = connect(mapStateToProps, mapDispatchToProps)(RequestCreatePage);
