import * as React from "react";

export interface Props {
  id: string;
  label?: string;
  labelRequired?: boolean;
  className?: string;
  placeholder?: string;
  containerClassName?: string;
  labelClassName?: string;
  readonly?: boolean;
  value?: string;
  rows?: number;
  onChange?: (comment: string) => void;
}

interface State {
  comment: string;
}

export default class TextArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = { comment: "" };
  }

  public render() {
    const { id, label, className, containerClassName, labelClassName, placeholder, readonly, value, rows, labelRequired } = this.props;
    return (
      <>
        <div className={`form-group ${labelRequired ? "required" : ""} ${containerClassName?? ""}`}>
          {label ?
            <label className={`text-secondary ${labelClassName ?? ""}`} htmlFor={id}>
              {label}
            </label>
            : <></>}
          <textarea
            className={`form-control ${className ?? ""}`}
            rows={rows ?? 3}
            onChange={this.handleOnChange}
            placeholder={placeholder}
            readOnly={readonly}
            value={value}
          ></textarea>
        </div>
      </>
    );
  }

  private handleOnChange(event: any) {
    const comment = event.target.value;
    this.setState({ comment }, () => this.props.onChange(comment));
  }
}
