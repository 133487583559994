import actionCreatorFactory from "typescript-fsa";
import { UserVisibility, VisibilityClone, Domain } from "./request.visibility.typings";
import {
  VisibilityRequestDTO,
  RejectRequestDTO,
  ValidateRequestDTO,
} from "api/request/visibility/visibilitySet/request.visibility.set.typings";
import { UserRequestDetail } from "containers/request/request.typings";

const enum RequestVisibilityActionName {
  FETCH_DOMAINS = "FETCH_DOMAINS",
  FETCH_VISIBILITY_BY_REQUEST = "FETCH_VISIBILITY_BY_REQUEST",
  FETCH_CONTACT_VISIBILITY_BY_CONTACT_ID = "FETCH_CONTACT_VISIBILITY_BY_CONTACT_ID",
  SAVE_VISIBILITY_REQUEST = "SAVE_VISIBILITY_REQUEST",
  VALIDATE_VISIBILITY_REQUEST = "VALIDATE_VISIBILITY_REQUEST",
  REJECT_VISIBILITY_REQUEST = "REJECT_VISIBILITY_REQUEST",
  VALIDATE_VISIBILITY_REQUEST_FROM_MODAL = "VALIDATE_VISIBILITY_REQUEST_FROM_MODAL",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchVisibilityDomainsAction = actionCreator.async<null, Domain[], string>(
  RequestVisibilityActionName.FETCH_DOMAINS
);

export const requestUserVisibilityAction = actionCreator.async<string, UserVisibility, string>(
  RequestVisibilityActionName.FETCH_VISIBILITY_BY_REQUEST
);

export const fetchVisibilityByContactIdAction = actionCreator.async<VisibilityClone, UserVisibility, string>(
  RequestVisibilityActionName.FETCH_CONTACT_VISIBILITY_BY_CONTACT_ID
);

export const saveVisibilityAction = actionCreator.async<VisibilityRequestDTO, UserRequestDetail, string>(
  RequestVisibilityActionName.SAVE_VISIBILITY_REQUEST
);

export const validateVisibilityAction = actionCreator.async<VisibilityRequestDTO, UserRequestDetail, string>(
  RequestVisibilityActionName.VALIDATE_VISIBILITY_REQUEST
);

export const rejectVisibilityAction = actionCreator.async<RejectRequestDTO, UserRequestDetail, string>(
  RequestVisibilityActionName.REJECT_VISIBILITY_REQUEST
);

export const validateVisibilityFromModalAction = actionCreator.async<ValidateRequestDTO, UserRequestDetail, string>(
  RequestVisibilityActionName.VALIDATE_VISIBILITY_REQUEST_FROM_MODAL
);
