import * as React from "react";
import { Environment } from "common/environment";

export interface StateProps {
  environment?: Environment;
}

export interface State {
  accountCenter: any;
}

export class SgHeader extends React.Component<StateProps, State> {
  public static getDerivedStateFromProps(props: StateProps, state: State): State | null {
    if (!state.accountCenter) {
      return null;
    }
    return state;
  }

  private readonly id = "cpr-account-center";

  constructor(props) {
    super(props);
    this.state = { accountCenter: null };
  }

  public render() {
    const { environment } = this.props;
    const env = environment && environment !== "production" ? environment : null;
    return (
      <>
        <sgwt-account-center
          id={this.id}
          environment={env}
          mode="sg-markets"
          language="en"
          hide-current-language={true}
          authentication="sg-connect-v2"
        />
      </>
    );
  }
}
