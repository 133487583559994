import * as React from "react";
import classNames from "classnames";
import { ComponentColor } from "components/common/components.typings";

export interface BadgeProps {
  value: string;
  type: ComponentColor;
  discreet?: boolean;
  outline?: boolean;
  prependSquare?: boolean;
  className?: string;
  children?: React.ReactNode;
  dataToggle?: string;
  dataPlacement?: string;
  title?: string;
}

export const Badge: React.FunctionComponent<BadgeProps> = ({
  value,
  type,
  prependSquare,
  className,
  children,
  outline,
  dataToggle,
  dataPlacement,
  title,
}: BadgeProps) => {
  return (
    <span
      data-toggle={dataToggle}
      data-placement={dataPlacement}
      title={title}
      className={classNames(
        `badge badge${outline ? "-outline" : ""}-${type} ${prependSquare ? "badge-prepend-square" : ""}`,
        className ? className : ""
      )}
    >
      {value}
      {children || null}
    </span>
  );
};
