import { combineEpics } from "redux-observable";
import { createRequestEpic } from "./create/request.create.epic";
import { requestListEpic } from "./list/request.list.epics";
import { requestDetailEpic } from "./detail/request.detail.epics";
import { requestVisibilityEpic } from "./validate/requestVisibility/request.visibility.epics";
import { exportRequestEpic } from "./export/request.export.epic";

export const requestEpic = combineEpics(
  createRequestEpic,
  requestListEpic,
  requestDetailEpic,
  requestVisibilityEpic,
  exportRequestEpic
);
