import QuickSearch from "components/common/QuickSearch/QuickSearch";
import { Link } from "react-router-dom";
import { ROUTES } from "common/routes";
import * as React from "react";
import { ApplicationSelectContainer } from "containers/referential/application/application.container";
import { StatusSelectContainer } from "containers/referential/status/status.container";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";
import { RequestListFilter } from "containers/request/list/request.list.typings";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { ExportContainer } from "containers/request/export/request.export.container";

export interface Props {
  canCrateRequest: boolean;
  onFilterChange: (filter?: RequestListFilter) => void;
  currentFilterValues: RequestListFilter;
}
export interface State {
  term: string;
  applicationId: ApplicationEnum;
  statusId: StatusEnum;
}

export class RequestFilter extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const { term, applicationId, statusId } = this.props.currentFilterValues;
    this.state = {
      term: term || "",
      applicationId: applicationId || ApplicationEnum.C_PROFIT,
      statusId: statusId || StatusEnum.ALL,
    };
  }

  public componentDidMount(): void {
    this.handleOnFilterChange();
  }

  public render() {
    const { canCrateRequest = false } = this.props;
    const { statusId, applicationId } = this.state;

    return (
      <>
        <div className="row  mb-2 ">
          {canCrateRequest && (
            <div className=" col text-right">
              <div className={"nav-item"}>
                <Link to={ROUTES.REQUEST_CREATE}>
                  <button type="button" className={"btn btn-lg btn-primary"}>
                    <i className={"icon mr-1"}>add</i> Create request
                  </button>
                </Link>
              </div>
            </div>
          )}
          <div>
            <ExportContainer />
          </div>
        </div>

        <div className={"row  mb-2"}>
          <FormField id={"filter"} label={"Filter"} className={"col"}>
            <QuickSearch
              placeholder="Search for a user"
              size={"lg"}
              term={this.state.term}
              onChange={this.handleOnTermChange}
            />
          </FormField>
          <FormField id={"application"} label={"Application"} className={"col-3 d-none"}>
            <ApplicationSelectContainer selectedId={applicationId} onSelectionChange={this.handleOnApplicationChange} />
          </FormField>
          <FormField id={"status"} label={"Status"} className={"col-3"}>
            <StatusSelectContainer
              selectedId={statusId}
              isAllShown={true}
              onSelectionChange={this.handleOnStatusChange}
            />
          </FormField>
        </div>
      </>
    );
  }

  private handleOnApplicationChange = (applicationId: ApplicationEnum) => {
    this.setState({ applicationId }, () => this.handleOnFilterChange());
  };
  private handleOnStatusChange = (statusId: StatusEnum) => {
    this.setState({ statusId }, () => this.handleOnFilterChange());
  };
  private handleOnTermChange = (term: string) => {
    this.setState({ term }, () => this.handleOnFilterChange());
  };
  private handleOnFilterChange = () => {
    const { term, applicationId, statusId } = this.state;
    this.props.onFilterChange({ term, applicationId, statusId });
  };
}
