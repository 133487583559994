import actionCreatorFactory from "typescript-fsa";
import { Dimension } from "../requestVisibilitySet/request.visibility.set.typings";

const enum ClientContributionVisibilityActionName {
  FETCH_CLIENTCONTRIBUTION_PROFILES_CLAIM_VALUES = "FETCH_CLIENTCONTRIBUTION_PROFILES_CLAIM_VALUES",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchClientContributionProfilesClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string },
  Dimension,
  string
>(ClientContributionVisibilityActionName.FETCH_CLIENTCONTRIBUTION_PROFILES_CLAIM_VALUES);
