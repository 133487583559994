import * as React from "react";
import { ContactViewerContainer } from "containers/contact/contact.container";
import { UserRequestDetail } from "containers/request/request.typings";
import { ConnectedUserProfileRights, Applications } from "containers/userProfile/userProfile.typings";
import { isStringEmpty } from "utils/strings/stringUtils";
import { ApplicationEnum, Application, StatusEnum } from "containers/referential/referential.typings";
import { get } from "lodash";
import { UserDetailNavigator } from "components/Request/Navigator/UserDetailNavigator";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "common/routes";
import { UserRequestsContainer } from "containers/user/requests/user.requests.container";
import { UserCoverageContainer } from "containers/user/coverage/user.coverage.container";
import { EmailDiscrepancyContainer } from "containers/header/emailDiscrepancy.container";
import { UserDetailPageHeader } from "./UserDetailPageHeader";
import { TerminateAccessModal } from "./TerminateAccessModal";
import { UserDetailPageTagList } from "./UserDetailPageTagList";
import { Loading } from "components/common/Loading/Loading";
export interface StateProps {
  userRight: ConnectedUserProfileRights;
  userId: string;
  requests: UserRequestDetail[];
  applications: Applications;
  isFetching: boolean;
  errorMessage: string;
}

export interface DispatchProps {
  onFetchUserRequests: (userId: string) => void;
  terminateAccess: (contactId: string, applicationId: string) => void;
}

export type Props = StateProps & DispatchProps;
export interface State {
  showDeactivateModal: boolean;
}
export const UserDetailPage: React.FunctionComponent<Props> = ({
  isFetching,
  userId,
  requests,
  userRight,
  applications,
  errorMessage,
  onFetchUserRequests,
  terminateAccess,
}) => {
  const [canAccessCoverage, setCanAccessCoverage] = React.useState<boolean>(false);

  React.useEffect(() => {
    onFetchUserRequests(userId);
  }, []);

  React.useEffect(() => {
    setCanAccessCoverage(
      requests && !requests[0].endDate && requests[0].status.id === StatusEnum.VALIDATED && userRight.canAccessCoverage
    );
  }, [requests]);

  const [isDeactivateModalShown, setDeactivateModal] = React.useState<boolean>(false);
  const [applicationId, setApplicationId] = React.useState<string>();

  const { canValidateCprofitRequest } = userRight;
  let canValidateCprofit = false;

  const showDeactivateModal = () => {
    setDeactivateModal(true);
    if (get(userApplications, "length") === 1) {
      setApplicationId(get(userApplications[0], "id"));
    }
  };
  const closeDeactivateModal = () => {
    setDeactivateModal(false);
  };
  const handleOnConfirm = () => {
    setDeactivateModal(false);
    terminateAccess(userId, applicationId);
  };
  const handleOnApplicationChange = (id: string) => {
    setApplicationId(id);
  };
  const hasError = !isFetching && (isStringEmpty(userId) || !isStringEmpty(errorMessage));
  const userApplications: Application[] = [];
  if (get(applications, "hasAccessToCprofit")) {
    userApplications.push({ id: ApplicationEnum.C_PROFIT, label: "Cprofit" });
    canValidateCprofit = canValidateCprofitRequest;
  }

  if (!!requests && requests[0].endDate) {
    applications = null;
  }
  return (
    <div>
      <EmailDiscrepancyContainer />
      <UserDetailPageHeader
        label="Deactivate Account"
        showTerminateAccessButton={canValidateCprofit}
        onTerminateAccessClick={showDeactivateModal}
      />
      <UserDetailPageTagList applications={applications} />
      <Loading isVisible={isFetching} />
      {!hasError && !isFetching && (
        <>
          <div className="row mt-4">
            <UserDetailNavigator userId={userId} canAccessCoverage={canAccessCoverage} />
          </div>
          <Switch>
            <Route
              exact={true}
              path={ROUTES.USER_DETAIL_INFO}
              render={() => <ContactViewerContainer contactId={userId} />}
            />
            <Route exact={true} path={ROUTES.USER_REQUESTS_LIST} component={UserRequestsContainer} />
            {canAccessCoverage && <Route exact={true} path={ROUTES.USER_COVERAGE} component={UserCoverageContainer} />}
            <Redirect to={ROUTES.USER_DETAIL_INFO} />
          </Switch>
          <TerminateAccessModal
            title={"Are you sure to deactivate this account"}
            show={isDeactivateModalShown}
            size={"lg"}
            submitLabel={"Confirm"}
            onCancel={closeDeactivateModal}
            onClose={closeDeactivateModal}
            onChange={handleOnApplicationChange}
            backdropClose={false}
            onSubmit={handleOnConfirm}
            isSubmitValid={true}
            labelRequired={true}
            options={userApplications}
          />
        </>
      )}
    </div>
  );
};
