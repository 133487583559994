import {
  VisibilitySet,
  StateProps,
  ViewSettingEnum,
  SetIds,
} from "components/Request/RequestValidate/Tabs/VisibilitySet";
import { fetchClaimVluesAction } from "./request.visibility.set.action";
import { Axis, AxisSettings } from "../request.visibility.typings";
import { GlobalState } from "store/store.typings";
import { connect } from "react-redux";
import { get } from "lodash";

interface OwnProps {
  viewSettingType: ViewSettingEnum;
  axises: Axis[];
  settings: AxisSettings[];
  id: number;
  onClaimIdChange: ({ axisLabel, setId, viewSettingType }: SetIds, claimId: string, claimLabel: string, operator: string) => void;
  allowAccess: boolean;
}

interface DispatchProps {
  fetchClaimValues: (
    setId: number,
    axisLabel: string,
    claimId: string,
    claimServiceUrl: string,
    viewSettingType: ViewSettingEnum
  ) => void;
}

const mapStateToProps = (
  state: GlobalState,
  { axises, id, viewSettingType, settings, allowAccess }: OwnProps
): StateProps => {
  return {
    axises,
    id,
    viewSettingType,
    settings,
    dimensions: get(state, "requestState.requestVisibility.dimensions"),
    allowAccess,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchClaimValues: (
    setId: number,
    axisLabel: string,
    claimId: string,
    claimServiceUrl: string,
    viewSettingType: ViewSettingEnum
  ) => dispatch(fetchClaimVluesAction.started({ setId, axisLabel, claimId, claimServiceUrl, viewSettingType })),
});

export const VisiblitySetContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(VisibilitySet);
