import * as React from "react";

export interface Props {
  title: string;
  size: "1" | "2" | "3" | "4" | "5";
}

export const SectionTitle: React.FunctionComponent<Props> = ({ title, size = "5" }: Props) => (
  <div className="row">
    <div className="col">
      <div className={`display-${size}`}>{title}</div>
    </div>
  </div>
);
