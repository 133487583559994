import { ViewSettingEnum, SetIds } from "./VisibilitySet";
import { State, Props } from "./Visibility";
import { filter, find, take, orderBy, isEmpty, get, map, flatMap, uniqBy, difference, uniq, some } from "lodash";
import {
  SetSettings,
  SetIdEnum,
  VisibilityClaim,
  ClaimTag,
  Claim,
  Domain,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import { DomainEnum } from "api/request/request.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { EmptyProfileId } from "./ProfileSelect";

export const newVisibilitySet = (setId?: number) => ({ setId, settings: [] });

const getSettingsFromType = (currentState: State, viewSettingType: ViewSettingEnum) => {
  switch (viewSettingType) {
    case ViewSettingEnum.MARK:
      return currentState.markViewSettings;
    case ViewSettingEnum.SG:
      return currentState.sgViewSettings;
    case ViewSettingEnum.EXTENDED_MARK:
      return currentState.extendedMarkViewSettings;
    case ViewSettingEnum.EXTENDED_SG:
      return currentState.extendedSgViewSettings;
    case ViewSettingEnum.ASOF:
      return currentState.asofViewSettings;
  }
};

const getSettingsPropName = (viewSettingType: ViewSettingEnum) => {
  switch (viewSettingType) {
    case ViewSettingEnum.MARK:
      return "markViewSettings";
    case ViewSettingEnum.SG:
      return "sgViewSettings";
    case ViewSettingEnum.EXTENDED_MARK:
      return "extendedMarkViewSettings";
    case ViewSettingEnum.EXTENDED_SG:
      return "extendedSgViewSettings";
    case ViewSettingEnum.ASOF:
      return "asofViewSettings";
  }
};

export const getIndicator = (viewSettingType: ViewSettingEnum) => {
  switch (viewSettingType) {
    case ViewSettingEnum.MARK:
      return "sc";
    case ViewSettingEnum.SG:
      return "nbi";
    case ViewSettingEnum.EXTENDED_MARK:
      return "cprofit_second_db_mark";
    case ViewSettingEnum.EXTENDED_SG:
      return "nbi";
    case ViewSettingEnum.ASOF:
      return "asof";
  }
};

const getDomainId = (viewSettingType: ViewSettingEnum) => {
  switch (viewSettingType) {
    case ViewSettingEnum.MARK:
      return "markview";
    case ViewSettingEnum.SG:
      return "sgview";
    case ViewSettingEnum.EXTENDED_MARK:
      return "extendedmarkview";
    case ViewSettingEnum.EXTENDED_SG:
      return "extendedsgview";
    case ViewSettingEnum.ASOF:
      return "asofview";
  }
};

export const addVisibilitySet = (currentState: State, viewSettingType: ViewSettingEnum): State => {
  let nextSetId = SetIdEnum.Two;
  if (viewSettingType === ViewSettingEnum.MARK) {
    nextSetId = currentState.markViewSettings.length + 1;
  }
  if (viewSettingType === ViewSettingEnum.SG) {
    nextSetId = currentState.sgViewSettings.length + 1;
  }
  if (viewSettingType === ViewSettingEnum.EXTENDED_SG) {
    nextSetId = currentState.extendedSgViewSettings.length + 1;
  }
  if (viewSettingType === ViewSettingEnum.EXTENDED_MARK) {
    nextSetId = currentState.extendedMarkViewSettings.length + 1;
  }
  if (viewSettingType === ViewSettingEnum.ASOF) {
    nextSetId = currentState.asofViewSettings.length + 1;
  }

  return {
    ...currentState,
    [getSettingsPropName(viewSettingType)]: [
      ...getSettingsFromType(currentState, viewSettingType),
      newVisibilitySet(nextSetId),
    ],
  };
};

export const deleteVisibilitySet = (currentState: State, viewSettingType: ViewSettingEnum): State => {
  const settings = getSettingsFromType(currentState, viewSettingType);
  return {
    ...currentState,
    [getSettingsPropName(viewSettingType)]: take(settings, settings.length - 1),
  };
};

const newAccessAllVisibilitySet = (domainId: DomainEnum): SetSettings => ({
  setId: SetIdEnum.One,
  settings: [{ axisName: undefined, claim: createAllowAccessClaim(domainId) }],
});

const hasAllowAccessClaim = (setSettings: SetSettings[]): boolean => {
  return some(setSettings, setting => setting.settings.some(s => s.claim.id === "allowaccess"));
};

export const getSetSettings = (setSettings: SetSettings[], access: boolean, domainId: DomainEnum): SetSettings[] => {
  if (!access) {
    return [];
  }
  // if access is granted, add allowaccess claim if not present
  if (!hasAllowAccessClaim(setSettings)) {
    const firstSet = setSettings.filter(s => s.setId === SetIdEnum.One);
    const allowaccesVisibilitySet = newAccessAllVisibilitySet(domainId);
    if (firstSet && firstSet.length) {
      firstSet[0].settings.push(allowaccesVisibilitySet.settings[0]);
    } else {
      setSettings.push(allowaccesVisibilitySet);
    }
  }
  return setSettings;
};

const createAllowAccessClaim = (domainId: DomainEnum): Claim => {
  return { id: "allowaccess", values: ["Y"], label: "Allow access", domainId, operator: "equal" };
};

export const updateClaimId = (
  currentState: State,
  { axisLabel, viewSettingType, setId }: SetIds,
  claimId: string,
  claimLabel: string,
  operator: string
): State => {
  const currentSetSettings = getSettingsFromType(currentState, viewSettingType);
  const restSets = filter(currentSetSettings, setting => setting.setId !== setId);
  const currentSet = find(currentSetSettings, setting => setting.setId === setId);
  const currentAxis = find(currentSet.settings, setting => setting.axisName === axisLabel);
  const updatedAxises = currentAxis
    ? [
        ...filter(currentSet.settings, setting => setting.axisName !== axisLabel),
        {
          ...currentAxis,
          claim: {
            id: claimId,
            values: !!currentAxis.claim.id && currentAxis.claim.id === claimId ? currentAxis.claim.values : [],
            label: claimLabel,
            operator: operator,
            domainId: getDomainId(viewSettingType),
          },
        },
      ]
    : [
        ...currentSet.settings,
        {
          axisName: axisLabel,
          claim: { id: claimId, values: [], label: claimLabel, operator: operator, domainId: getDomainId(viewSettingType) },
        },
      ];

  const updatedSet = { ...currentSet, settings: updatedAxises };
  const updatedSettings = orderBy([...restSets, updatedSet], ({ setId: id }) => id);
  return {
    ...currentState,
    [getSettingsPropName(viewSettingType)]: updatedSettings,
  };
};

export const updateClaimValues = (
  currentState: State,
  { axisLabel, setId, viewSettingType }: SetIds,
  claimValues: string[]
) => {
  const currentSetSettings = getSettingsFromType(currentState, viewSettingType);
  const restSets = filter(currentSetSettings, setting => setting.setId !== setId);
  const currentSet = find(currentSetSettings, setting => setting.setId === setId);
  const currentAxis = find(currentSet.settings, setting => setting.axisName === axisLabel);
  const updatedAxises = currentAxis
    ? [
        ...filter(currentSet.settings, setting => setting.axisName !== axisLabel),
        { ...currentAxis, claim: { ...currentAxis.claim, values: currentAxis.claim.id ? claimValues : [] } },
      ]
    : currentSet.settings;

  const updatedSet = { ...currentSet, settings: updatedAxises };
  const updatedSettings = orderBy([...restSets, updatedSet], ({ setId: id }) => id);

  return {
    ...currentState,
    [getSettingsPropName(viewSettingType)]: updatedSettings,
  };
};


export const updateClaimOperator = (
  currentState: State,
  { axisLabel, setId, viewSettingType }: SetIds,
  operator: string
) => {
  const currentSetSettings = getSettingsFromType(currentState, viewSettingType);
  const restSets = filter(currentSetSettings, setting => setting.setId !== setId);
  const currentSet = find(currentSetSettings, setting => setting.setId === setId);
  const currentAxis = find(currentSet.settings, setting => setting.axisName === axisLabel);
  const updatedAxises = currentAxis
    ? [
        ...filter(currentSet.settings, setting => setting.axisName !== axisLabel),
        { ...currentAxis, claim: { ...currentAxis.claim, operator: operator } },
      ]
    : currentSet.settings;

  const updatedSet = { ...currentSet, settings: updatedAxises };
  const updatedSettings = orderBy([...restSets, updatedSet], ({ setId: id }) => id);

  return {
    ...currentState,
    [getSettingsPropName(viewSettingType)]: updatedSettings,
  };
};

export const getClaims = (setSettings: SetSettings[]): VisibilityClaim[] =>
  flatMap(setSettings, ({ setId, settings }) => ({
    setId,
    claims: flatMap(settings, ({ claim }) => ({
      id: claim.id,
      label: claim.label,
      values: claim.values,
      domainId: claim.domainId,
      operator: claim.operator
    })),
  }));

const getVisibilityClaimsWhenEmpty = (allowAccess: boolean, displayNoAccessIfEmpty: boolean): VisibilityClaim[] => {
  return [
    {
      setId: 1,
      claims: displayNoAccessIfEmpty
        ? [
            {
              label: allowAccess ? "Allow access" : "No access",
              color: allowAccess ? "success" : "danger",
              values: allowAccess ? ["Y"] : [],
            },
          ]
        : [],
    } as VisibilityClaim,
  ];
};

const getFilteredVisibilityClaims = (
  visibilityClaims: VisibilityClaim[],
  filterAllowAccessClaims: boolean,
  isWebModules = false
): VisibilityClaim[] => {
  return filterAllowAccessClaims
    ? map(
        visibilityClaims,
        visibilityClaim =>
          ({
            ...visibilityClaim,
            claims: filter(
              visibilityClaim.claims,
              claim =>
                claim.id !== "allowaccess" ||
                (claim.id === "allowaccess" && visibilityClaim.claims.length === 1) ||
                (isWebModules && claim.id === "allowaccess" && claim.domainId !== "webmodules")
            ),
          } as VisibilityClaim)
      )
    : visibilityClaims;
};

export const fillVisibilityClaimsColors = (
  visibilityClaims: VisibilityClaim[],
  colorEnum: object,
  allowAccess: boolean,
  filterAllowAccessClaims = true,
  displayNoAccessIfEmpty = true,
  isWebModules = false
): VisibilityClaim[] => {
  if (checkVisibilityClaimsIsEmpty(visibilityClaims)) {
    return getVisibilityClaimsWhenEmpty(allowAccess, displayNoAccessIfEmpty);
  }

  // remove all allowaccess claims
  const filteredVisibilityClaims = getFilteredVisibilityClaims(visibilityClaims, filterAllowAccessClaims, isWebModules);

  const allVisibilityclaims = flatMap(filteredVisibilityClaims, visibilityClaim => visibilityClaim.claims);

  const duplicateClaims = getDuplicateClaims(allVisibilityclaims);
  if (!!duplicateClaims && duplicateClaims.length > 0) {
    const coloredCommonClaims = fillDuplicateClaimsColors(duplicateClaims, colorEnum);
    const result = map(filteredVisibilityClaims, visibilityClaim =>
      fillClaimColors(visibilityClaim, colorEnum, coloredCommonClaims)
    );
    return sanitizeVisibilityClaims(result);
  } else {
    const result = map(filteredVisibilityClaims, visibilityClaim => fillClaimColors(visibilityClaim, colorEnum));
    return sanitizeVisibilityClaims(result);
  }
};

const checkVisibilityClaimsIsEmpty = (visibilityClaims: VisibilityClaim[]): boolean =>
  isEmpty(visibilityClaims) ||
  (visibilityClaims.length === 1 && isEmpty(get(visibilityClaims[0], "claims"))) ||
  checkVisibilityClaimsContainsEmtyAxis(visibilityClaims);

const checkVisibilityClaimsContainsEmtyAxis = (visibilityClaims: VisibilityClaim[]): boolean =>
  !isEmpty(get(visibilityClaims[0], "claims")) &&
  isEmpty(filter(get(visibilityClaims[0], "claims"), ({ id }) => id !== "emptyAxis"));

const getDuplicateClaims = (visibilityClaim: ClaimTag[]): ClaimTag[] =>
  uniqBy(
    filter(
      visibilityClaim,
      claim => filter(visibilityClaim, duplicateClaim => claim.id === duplicateClaim.id).length > 1
    ),
    "id"
  );

const fillDuplicateClaimsColors = (visibilityClaim: ClaimTag[], colorEnum: object): ClaimTag[] =>
  map(visibilityClaim, (claim, index) => ({ ...claim, color: colorEnum[index % 4] }));

const fillClaimColors = (
  visibilityClaim: VisibilityClaim,
  colorEnum: object,
  commonClaims?: ClaimTag[]
): VisibilityClaim => {
  let firstAvailableColorIndex = -1;
  const updatedClaims = map(visibilityClaim.claims, (claim, index) => {
    let color = "";
    if (commonClaims) {
      const duplicateClaim = find(commonClaims, ({ id }) => claim.id === id);
      if (duplicateClaim) {
        color = get(duplicateClaim, "color");
      } else {
        const usedColorsIndexes = map(commonClaims, element => colorEnum[element.color]);
        const allColorsIndexes = [0, 1, 2, 3];
        const availableColorsIndexes = difference(allColorsIndexes, usedColorsIndexes);

        firstAvailableColorIndex++;
        color = colorEnum[availableColorsIndexes[firstAvailableColorIndex]];
        if (firstAvailableColorIndex >= availableColorsIndexes.length - 1) {
          firstAvailableColorIndex = -1;
        }
      }
    } else {
      color = colorEnum[index % 4];
    }
    return { ...claim, color };
  });
  return { ...visibilityClaim, claims: updatedClaims };
};

const sanitizeVisibilityClaims = (visibilityClaims: VisibilityClaim[]): VisibilityClaim[] =>
  map(visibilityClaims, visibilityClaim => {
    const updatedClaims = sanitizeClaims(visibilityClaim.claims);
    return { ...visibilityClaim, claims: updatedClaims };
  });

const sanitizeClaims = (claims: ClaimTag[]): ClaimTag[] => filter(claims, ({ id }) => id !== "emptyAxis");

export const addNewVisibilitySet = (settings: SetSettings[]): SetSettings[] => {
  if (isEmpty(settings)) {
    return [newVisibilitySet(SetIdEnum.One)];
  }
  return orderBy(settings, ({ setId }) => setId);
};

export const getViewAccess = (setSettings: SetSettings[]): boolean => {
  if (isEmpty(setSettings)) {
    return false;
  }
  const allowAccessArray = map(setSettings, setting => find(setting.settings, p => p.claim.id === "allowaccess"));
  const filteredAllowAccessArray = filter(allowAccessArray, element => element !== undefined);

  if (filteredAllowAccessArray.length > 0) {
    return true;
  }

  if (filteredAllowAccessArray.length < 1 && !isEmpty(setSettings)) {
    return true;
  }

  return false;
};

export const shouldRenderVisibility = (props: Props, state: State) =>
  props.visibility.requestId === props.requestId &&
  !props.isVisibilityFecthing &&
  !props.isFetching &&
  !state.isLoaded &&
  !state.showCloneRequestModal;

export const updateEnabledWebModules = (currentState: State, domainId: DomainEnum, checked: boolean): State => {
  const currentSet = isEmpty(currentState.webModulesSettings)
    ? { setId: SetIdEnum.One, settings: [] }
    : currentState.webModulesSettings[0];

  let otherDomains = currentSet.settings.filter(item => item.claim.domainId !== domainId);
  if (!checked) {
    // Remove all claims for that domain.

    if (otherDomains.length === 1 && otherDomains[0].claim.id === "profile") {
      otherDomains = [];
    }
    const newSet = { ...currentSet, settings: otherDomains };
    return { ...currentState, webModulesSettings: [newSet] };
  } else {
    // Set AllowAccess for that domain
    if (checked && find(otherDomains, domain => domain.claim.id === "profile") === undefined) {
      // When selecting a webmodule, Profile claim should be applied
      otherDomains.push({
        axisName: undefined,
        claim: { domainId: "webmodules", id: "profile", label: "Profile", values: ["User"] } as Claim,
      });
    }
    const newSet = {
      ...currentSet,
      settings: [
        ...otherDomains,
        {
          axisName: undefined,
          claim: createAllowAccessClaim(domainId),
        },
      ],
    };
    return { ...currentState, webModulesSettings: [newSet] };
  }
};

export const updateKoreanDataFlag = (
  currentState: State,
  flag: boolean | null
): State => {
  return { ...currentState, koreanDataFlag: flag };
};

export const updateWebModulesClaimValues = (
  currentState: State,
  domainsConfig: Domain[],
  domainId: DomainEnum,
  claimId: string,
  values: string[]
): State => {
  const currentSet = isEmpty(currentState.webModulesSettings)
    ? { setId: SetIdEnum.One, settings: [] }
    : currentState.webModulesSettings[0];

  const currentSettings = filter(
    currentSet.settings,
    claimSetting => claimSetting.claim.domainId === domainId && claimSetting.claim.id !== claimId
  );
  const otherSettings = filter(currentSet.settings, claimSetting => claimSetting.claim.domainId !== domainId);
  const newSet = {
    ...currentSet,
    settings: [
      ...otherSettings,
      ...(!isEmpty(values)
        ? [
            ...currentSettings,
            {
              axisName: undefined,
              claim: {
                domainId,
                id: claimId,
                values,
                label: getClaimLabel(domainsConfig, domainId, claimId),
                operator: "equal"
              },
            },
          ]
        : [
            {
              axisName: undefined,
              claim: {
                domainId,
                id: "allowaccess",
                values: ["Y"],
                label: getClaimLabel(domainsConfig, domainId, "allowaccess"),
                operator: "equal"
              },
            },
          ]),
    ],
  } as SetSettings;
  return { ...currentState, webModulesSettings: [newSet] };
};

export const updateDataQualityClaimValues = (
  currentState: State,
  domainsConfig: Domain[],
  domainId: DomainEnum,
  claimId: string,
  values: string[]
): State => {
  const currentSet = isEmpty(currentState.dataQualitySettings)
    ? { setId: SetIdEnum.One, settings: [] }
    : currentState.dataQualitySettings[0];

  if (!isEmpty(values) && values[0] === EmptyProfileId) {
    return { ...currentState, dataQualitySettings: [] };
  }

  const newSet = updateModuleClaimValues(domainsConfig, domainId, claimId, values, currentSet);

  return { ...currentState, dataQualitySettings: [newSet] };
};

export const updateModuleClaimValues = (
  domainsConfig: Domain[],
  domainId: DomainEnum,
  claimId: string,
  values: string[],
  currentSet: SetSettings
): SetSettings => {
  const currentSettings = filter(
    currentSet.settings,
    claimSetting => claimSetting.claim.domainId === domainId && claimSetting.claim.id !== claimId
  );
  const otherSettings = filter(currentSet.settings, claimSetting => claimSetting.claim.domainId !== domainId);

  let claim = [
    ...currentSettings,
    {
      axisName: undefined,
      claim: {
        domainId,
        id: claimId,
        values,
        label: getClaimLabel(domainsConfig, domainId, claimId),
        operator: "equal"
      },
    },
  ];
  if (domainId === DomainEnum.DATAQUALITY || domainId === DomainEnum.CLIENTCONTRIBUTION) {
    claim = [
      {
        axisName: undefined,
        claim: {
          domainId,
          id: claimId,
          values,
          label: getClaimLabel(domainsConfig, domainId, claimId),
          operator: "equal"
        },
      },
    ];
  }
  return {
    ...currentSet,
    settings: [
      ...otherSettings,
      ...(!isEmpty(values)
        ? claim
        : [
            {
              axisName: undefined,
              claim: {
                domainId,
                id: "allowaccess",
                values: ["Y"],
                label: getClaimLabel(domainsConfig, domainId, "allowaccess"),
                operator: "equal"
              },
            },
          ]),
    ],
  } as SetSettings;
};
export const getActiveWebModules = (webModulesSettings: SetSettings[]): DomainEnum[] => {
  return !isEmpty(webModulesSettings)
    ? uniq(webModulesSettings[0].settings.map(axisSettings => axisSettings.claim.domainId))
    : [];
};

const getClaimLabel = (domainsConfig: Domain[], domainId: DomainEnum, claimId: string) => {
  const domainConfig = filter(domainsConfig, domain => domain.domainId === domainId);
  const claims = flatMap(domainConfig, domain =>
    flatMap(domain.domainValues, axis => filter(axis.values, claim => claim.claimId === claimId))
  );

  if (!claims || isEmpty(claims)) {
    return claimId;
  }
  return claims[0] ? claims[0].claimLabel : "";
};

export const getTagListForWebmodules = (claims: ClaimTag[], dimensions: Dimension[]): ClaimTag[] => {
  const tagListForMassUpload = getTagListByDomainId(claims, dimensions, DomainEnum.MASSUPLOAD);
  const webModuleClaimsWithoutSuppliers = filter(claims, claim => claim.id !== "suppliers");
  const tagListForDataCollect = getTagListByDomainId(claims, dimensions, DomainEnum.DATACOLLECT);
  
  return [...webModuleClaimsWithoutSuppliers, ...tagListForMassUpload, ...tagListForDataCollect];
};

export const getTagListByDomainId= (claims: ClaimTag[], dimensions: Dimension[],domainId: DomainEnum): ClaimTag[] => {
  const suppliers = filter(claims, claim => claim.id === "suppliers" && claim.domainId === domainId);  
  const suppliersIds = suppliers && suppliers.length > 0 ? suppliers[0].values : [];
  const suppliersDefinition = filter(dimensions, dimension => dimension.claimId === "suppliers" && dimension.domainId === domainId);
  const suppliersDefinitionValues =
    suppliersDefinition && suppliersDefinition.length > 0 ? suppliersDefinition[0].values : [];
  const suppliersObjects =
    suppliersDefinitionValues && suppliersDefinitionValues.length > 0
      ? map(
          map(suppliersIds, supplierId => parseInt(supplierId)),
          id => {
            const supplier = find(suppliersDefinition[0].values, value => value.id.toString() === id.toString());
            if (supplier) {
              return supplier;
            } else {
              console.log("MISSING SUPPLIER ID IN REFERENTIAL", id);
            }
          }
        )
      : [];
  const suppliersLabels =
    suppliersObjects && suppliersObjects.length > 0 ? map(suppliersObjects, supplier => supplier?.name) : [];
  const webModuleClaimsSuppliers = find(claims, claim => claim.id === "suppliers" && claim.domainId === domainId);
  
  return [{ ...webModuleClaimsSuppliers, values: suppliersLabels }];
};

export const getClaimUrl = (domainsConfig: Domain[], domainId: DomainEnum, claimId: string): string => {
  const domainConfig = find(domainsConfig, domain => domain.domainId === domainId);
  if (!domainConfig || isEmpty(domainConfig.domainValues)) {
    return "";
  }

  const claim = find(domainConfig.domainValues[0].values, c => c.claimId === claimId);
  return claim ? claim.claimValuesUrl : "";
};

export const updateClientContributionClaimValues = (
  currentState: State,
  domainsConfig: Domain[],
  domainId: DomainEnum,
  claimId: string,
  values: string[]
): State => {
  const currentSet = isEmpty(currentState.clientContributionSettings)
    ? { setId: SetIdEnum.One, settings: [] }
    : currentState.clientContributionSettings[0];

  if (!isEmpty(values) && values[0] === EmptyProfileId) {
    return { ...currentState, clientContributionSettings: [] };
  }

  const newSet = updateModuleClaimValues(domainsConfig, domainId, claimId, values, currentSet);

  return { ...currentState, clientContributionSettings: [newSet] };
};
