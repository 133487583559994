import { fetchExportRequestAction } from "./request.export.action";
import { isType } from "typescript-fsa";

export interface ExportRequestState {
  isFetching: boolean;
  errorMessage: string;
}

export const initUserState: ExportRequestState = {
  isFetching: false,
  errorMessage: null,
};

export const fetchExportRequestReducer = (state: ExportRequestState = initUserState, action): ExportRequestState => {
  if (isType(action, fetchExportRequestAction.started)) {
    return { ...state, isFetching: true, errorMessage: null };
  }
  if (isType(action, fetchExportRequestAction.done)) {
    return { ...state, isFetching: false };
  }
  if (isType(action, fetchExportRequestAction.failed)) {
    return { ...state, isFetching: false, errorMessage: action.payload.error };
  }
  return { ...state };
};

export const exportRequestReducer = fetchExportRequestReducer;
