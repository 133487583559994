import { userProfileReducer } from "containers/userProfile/userProfile.reducer";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { GlobalState } from "store/store.typings";
import { requestReducer } from "containers/request/request.reducer";
import { userReducer } from "containers/user/user.reducer";
import { contactReducer } from "containers/contact/contact.reducer";
import { referentialReducer } from "containers/referential/referential.reducer";

const appReducers = combineReducers<GlobalState>({
  connectedUserProfile: userProfileReducer,
  referential: referentialReducer,
  userState: userReducer,
  requestState: requestReducer,
  contactState: contactReducer
});

export const rootReducer = history => connectRouter(history)(appReducers);
