import { ROUTES } from "common/routes";
import { NavItem } from "components/Navigation/NavItem";
import { NavPills } from "components/Navigation/NavPills";
import React = require("react");
import { generateUri } from "utils/uris/generateUri";

export interface Props {
  className?: string;
  userId: string;
  canAccessCoverage: boolean;
}

export const UserDetailNavigator: React.FunctionComponent<Props> = ({
  userId,
  className = "col",
  canAccessCoverage,
}: Props) => {
  return (
    <div className={className}>
      <NavPills className={"nav nav-pills border-bottom"}>
        <NavItem exact={true} to={generateUri(ROUTES.USER_DETAIL_INFO, userId)} title="User infos" />
        <NavItem exact={true} to={generateUri(ROUTES.USER_REQUESTS_LIST, userId)} title="Requests" />
        {canAccessCoverage && <NavItem exact={true} to={generateUri(ROUTES.USER_COVERAGE, userId)} title="Coverage" />}
      </NavPills>
    </div>
  );
};
