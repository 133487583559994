import { startsWith } from "lodash";
import { isContainSubString } from "utils/strings/stringUtils";

export const getApiServiceUrl = (url: string, usingMock = false): string => {
  if (usingMock) {
    return "/";
  }
  const isRootUrl = startsWith(url, "http://") || startsWith(url, "https://");

  if (isRootUrl) {
    return "";
  }

  const isContactsApiCall = isContainSubString(url, "/sg/contacts");
  if (isContactsApiCall) {
    return getIcServiceForExtendedContactUrl();
  }

  if (isContainSubString(url, "/2.0/attributes")) {
    return getBiApiAttributesUrl();
  }

  return getCerberusApiUrl();
};

export const getIcServiceForExtendedContactUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return window["globalConfiguration"].sgConnectConfig.widgetConfiguration.environment === "production"
        ? "https://contacts-api.fr.world.socgen/1.0"
        : "https://contacts-api-hom.fr.world.socgen/1.0";
    case "DEV":
      return "https://contacts-api-hom.fr.world.socgen/1.0";
    case "UAT":
      return "https://api-s.sgmarkets.com/thirdparties/contacts/v1";
    case "ISO":
      return "https://api-s.sgmarkets.com/thirdparties/contacts/v1";
    case "PROD":
      return "https://api-s.sgmarkets.com/thirdparties/contacts/v1";
    default:
      throw Error("Cannot get current environment from configuration");
  }
};

export const getCerberusApiUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return window["globalConfiguration"].sgConnectConfig.widgetConfiguration.environment === "production"
        ? "https://cerberus-api-uat.fr.world.socgen"
        : "https://cerberus-api-dev.fr.world.socgen";
    case "DEV":
      return "https://cerberus-api-dev.fr.world.socgen";
    case "UAT":
      return "https://cerberus-api-uat.fr.world.socgen";
    case "ISO":
      return "https://cerberus-api-iso.fr.world.socgen";
    case "PROD":
      return "https://cerberus-api.fr.world.socgen";
    default:
      throw Error("Cannot get current environment from configuration");
  }
};

export const getBiApiAttributesUrl = (): string => {
  switch (window["globalConfiguration"].env) {
    case "local":
      return window["globalConfiguration"].sgConnectConfig.widgetConfiguration.environment === "production"
        ? "https://cpr-bi-api-iso.fr.world.socgen"
        : "https://cpr-bi-api-dev.fr.world.socgen";
    case "DEV":
      return "https://cpr-bi-api-dev.fr.world.socgen";
    case "UAT":
      return "https://cpr-bi-api-iso.fr.world.socgen";
    case "ISO":
      return "https://cpr-bi-api-iso.fr.world.socgen";
    case "PROD":
      return "https://cpr-bi-api.fr.world.socgen";
    default:
      throw Error("Cannot get current environment from configuration");
  }
};
