import { SetSettings, Domain } from "../request.visibility.typings";
import { GlobalState } from "store/store.typings";
import { connect } from "react-redux";
import { StateProps, VisibilityWebModules } from "components/Request/RequestValidate/Tabs/VisibilityWebModules";
import {
  fetchWebModulesSuppliersClaimValuesAction,
  fetchWebModulesProfilesClaimValuesAction,
  fetchWebModulesSBLClaimValuesAction,
} from "./request.webmodules.action";
import { get } from "lodash";
import { DomainEnum } from "api/request/request.typings";
import { fetchDataCollectSuppliersClaimValuesAction } from "../dataCollect/request.datacollect.action";

interface OwnProps {
  domainsConfig: Domain[];
  webModulesSettings: SetSettings[];
}

interface DispatchProps {
  fetchClaimValues: (claimId: string, claimServiceUrl: string, domain: DomainEnum) => void;
}

const mapStateToProps = (state: GlobalState, { domainsConfig, webModulesSettings }: OwnProps): StateProps => {
  
  return {
    domainsConfig,
    webModulesSettings,
    dimensions: get(state, "requestState.requestVisibility.dimensions"),
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchClaimValues: (claimId: string, claimServiceUrl: string, domain: DomainEnum) => {    
    dispatch(performFetchClaimValues(claimId, claimServiceUrl, domain))
  },
});

export const performFetchClaimValues = (claimId: string, claimServiceUrl: string, domainId: DomainEnum) => {
  switch (claimId) {
    case "suppliers":
      if (domainId === DomainEnum.DATACOLLECT)
      {
        return fetchDataCollectSuppliersClaimValuesAction.started({ claimId, claimServiceUrl});
      }
      return fetchWebModulesSuppliersClaimValuesAction.started({ claimId, claimServiceUrl, domainId });
    case "sbl":
      return fetchWebModulesSBLClaimValuesAction.started({ claimId, claimServiceUrl });
    case "profile":
      return fetchWebModulesProfilesClaimValuesAction.started({ claimId, claimServiceUrl });
    default:
      throw new Error(`Cannot handle the claimId ${claimId}`);
  }
};

export const VisibilityWebModulesContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(VisibilityWebModules);
