import * as React from "react";
import { map, size, head, isEmpty, includes, find, get, difference } from "lodash";
import { IdNames, IdName } from "common/common.typings";
import { SelectDropDown } from "../../Select/SelectDropDown";
import { useToggleList } from "hooks/useToggleList";
import { Checkbox } from "../Checkbox/Checkbox";
import QuickSearch from "../QuickSearch/QuickSearch";
import { isStringEmpty } from "utils/strings/stringUtils";
import { getFilteredItems } from "./MultiSelector.helpers";

export interface Props {
  items: IdNames<string>;
  selectedItems?: string[];
  onChanged: (selectedIds: string[]) => void;
  placeHolder?: string;
  capacity?: number;
  closeOnSelect?: boolean;
  iconName?: string;
  disabled?: boolean;
}

export const MultiSelector: React.FunctionComponent<Props> = ({
  items,
  selectedItems,
  onChanged,
  placeHolder = "Select",
  capacity,
  closeOnSelect,
  iconName,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [isDropDownShown, toggleDropDown] = React.useState<boolean>(false);
  const [selectedItemsState, updateList, setSelectedItemsState] = useToggleList<string>(selectedItems);
  const [filterText, setFilterText] = React.useState<string>("");

  React.useEffect(() => {
    setSelectedItemsState(selectedItems);
    setInputValue(getInputValue(selectedItems));
  }, [selectedItems, items]);

  React.useEffect(() => {
    if (!isDropDownShown) {
      onChanged(selectedItemsState);
    }
  }, [isDropDownShown]);

  React.useEffect(() => {
    setInputValue(getInputValue(selectedItemsState));
    if (!!closeOnSelect && !isEmpty(selectedItemsState)) {
      toggleDropDown(!isDropDownShown);
    }
    setSelectedItemsState(selectedItemsState);
  }, [selectedItemsState]);

  const handleSelection = (elem: IdName<string>, event?: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (isValidSelection(elem)) {
      updateList(elem.id);
    }
  };

  const handleQuickSearchChange = (terms: string): void => {
    setFilterText(terms);
  };

  const handleOnSelectAll = (event?: any) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!isDropDownShown) {
      return;
    }
    isEverythingVisibleSelected()
      ? setSelectedItemsState([])
      : setSelectedItemsState(map(getFilteredItems(items, filterText), item => item.id));
  };

  const isEverythingVisibleSelected = (): boolean => {
    return isEmpty(
      difference(
        map(getFilteredItems(items, filterText), item => item.id),
        selectedItemsState
      )
    );
  };

  const handleOnClearClick = () => {
    setSelectedItemsState([]);
    if (!isDropDownShown) {
      onChanged([]);
    }
  };

  const getInputValue = (ids: string[]): string => {
    const count = size(ids);
    return count === 0
      ? ""
      : (capacity && capacity === 1) || count === 1
      ? get(
          find(items, ({ id }) => id === head(ids)),
          "name"
        )
      : `${count} selected`;
  };

  const isValidSelection = (item: IdName<string>): boolean =>
    !capacity || includes(selectedItemsState, item.id) || size(selectedItemsState) < capacity;

  return (
    <>
      <SelectDropDown
        size={"lg"}
        displayedText={inputValue}
        onToggleDropDown={toggleDropDown}
        isDropDownShown={isDropDownShown}
        withOverflow={true}
        iconName={iconName}
        placeHolder={placeHolder}
        disabled={disabled}
        onClearClick={() => handleOnClearClick()}
      >
        {isDropDownShown && (
          <>
            <div hidden={!isDropDownShown} className="position-sticky bg-white w-100" style={{ zIndex: 2000, top: 0 }}>
              <QuickSearch size="lg" placeholder="search" term={filterText} onChange={handleQuickSearchChange} />
            </div>

            {getFilteredItems(items, filterText) && getFilteredItems(items, filterText).length > 1 && (
              <div
                key={-1}
                className={`list-group-item list-group-item-action ${
                  !capacity || size(selectedItemsState) < capacity ? "action" : ""
                }`}
                onClick={e => handleOnSelectAll(e)}
              >
                <Checkbox
                  id={"-1"}
                  checked={isEverythingVisibleSelected()}
                  label={`(Select All ${isStringEmpty(filterText) ? ")" : "in results)"}`}
                  onChange={() => handleOnSelectAll()}
                />
              </div>
            )}
          </>
        )}

        {map(getFilteredItems(items, filterText), (item, index) => {
          return (
            <div
              key={index}
              className={`list-group-item list-group-item-action ${
                !capacity || size(selectedItemsState) < capacity ? "action" : ""
              }`}
              onClick={e => handleSelection(item, e)}
            >
              <Checkbox
                id={item.id}
                checked={includes(selectedItemsState, item.id)}
                label={item.name}
                onChange={() => handleSelection(item)}
                disabled={!isValidSelection(item)}
              />
            </div>
          );
        })}
      </SelectDropDown>
    </>
  );
};
