import * as React from "react";
import { Icon } from "components/common/Icon/Icon";
import * as styles from "./SelectDropDown.scss";
import classNames from "classnames";
import { ComponentSize } from "../common/components.typings";
import { ClearButton } from "components/common/Buttons/ClearButton";
import { noop } from "lodash";

export interface Props {
  isDropDownShown: boolean;
  size?: ComponentSize;
  enableClear?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClearClick?: () => void;
}

export const ToggleDropDownButton: React.FunctionComponent<Props> = ({
  isDropDownShown,
  onClick,
  enableClear = false,
  onClearClick = noop,
  size = "md",
}: Props) => (
  <div className={classNames("input-group-append input-group-merged", styles.SelectChevronDropDown)}>
    {enableClear && <ClearButton onClear={onClearClick} />}

    <button type="button" className="btn btn-link px-0" onClick={onClick}>
      <Icon iconName={isDropDownShown ? "arrow_drop_up" : "arrow_drop_down"} size={size} className={"pointer"} />
    </button>
  </div>
);
