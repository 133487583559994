import * as React from "react";
import { PageHeader } from "components/common/PageHeader/PageHeader";
import { SubmitButton } from "components/common/Buttons/SubmitButton";

export interface Props {
  label: string;
  showTerminateAccessButton: boolean;
  onTerminateAccessClick: () => void;
}
export const UserDetailPageHeader: React.FunctionComponent<Props> = ({
  showTerminateAccessButton,
  onTerminateAccessClick,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex align-items-start">
        <PageHeader title="User Detail" isBackButtonShown={true} />
      </div>
      <div className="d-flex align-items-center">
        {showTerminateAccessButton && (
          <SubmitButton
            label={"Deactivate Account"}
            className="btn btn-lg btn-secondary mr-2"
            onClick={onTerminateAccessClick}
          />
        )}
      </div>
    </div>
  );
};
