import { UserRequestDetail } from "../request.typings";
import { fetchRequestByIdAction } from "./request.detail.action";
import { isType } from "typescript-fsa";

export interface RequestDetailState {
  request: UserRequestDetail;
  isFetching: boolean;
  requestId?: string;
  errorMessage: string;
}

export const initUserState: RequestDetailState = {
  request: null,
  errorMessage: null,
  isFetching: false,
};

export const fetchRequestDetailReducer = (state: RequestDetailState = initUserState, action): RequestDetailState => {
  if (isType(action, fetchRequestByIdAction.started)) {
    return { ...state, isFetching: true, request: null, errorMessage: null };
  }
  if (isType(action, fetchRequestByIdAction.done)) {
    return { ...state, request: action.payload.result, isFetching: false };
  }
  if (isType(action, fetchRequestByIdAction.failed)) {
    return { ...state, isFetching: false, request: null, errorMessage: action.payload.error };
  }
  return { ...state };
};

export const requestDetailReducer = fetchRequestDetailReducer;
