import { connect } from "react-redux";
import { fetchUserInfo } from "containers/userProfile/userProfile.action";
import { GlobalState } from "store/store.typings";
import { Authentication, DispatchProps, StateProps } from "components/Authentication/Authentication";
import { Dispatch } from "redux";

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchUserInfo: (userEmail: string) => { dispatch(fetchUserInfo.started(userEmail))},
});

const mapStateToProps = ({ connectedUserProfile: { loggedIn } }: GlobalState): StateProps => ({ loggedIn });

// eslint-disable-next-line @typescript-eslint/ban-types
export const AuthContainer = connect<StateProps, DispatchProps, {}, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(Authentication);
