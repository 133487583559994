import * as XLSX from "xlsx";
import { startCase } from "lodash";

export const exportToExcel = (data: any[]): boolean => {
  try {
    const fields = Object.keys(data[0]);
    const convertedWords = fields.map(word => splitCamelCasing(startCase(word)));
    const csv: any[][] = [];
    csv.push(convertedWords);
    for (const row of data) {
      csv.push(
        fields.map(fieldName => {
          return row[fieldName];
        })
      );
    }
    const fileName = "User Requests";
    const workSheet = XLSX.utils.aoa_to_sheet(csv);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, fileName);
    XLSX.writeFile(workBook, fileName + ".xlsx");
    return true;
  } catch (err) {
    return false;
  }
};

function splitCamelCasing(camelCasing: string): string {
  return camelCasing.replace(/([a-z])([A-Z])/g, "$1 $2");
}
