import { trim, upperFirst } from "lodash";
import { diacriticsMap } from "../strings/diacriticsMap.const";

export const capitalizeFirstLetter = (value: string) => upperFirst(value.toLowerCase());

export const isStringEqual = (value?: string, other?: string, trimSpaces = true, insensitive = false): boolean => {
  if (!value && !other) {
    return true;
  }
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return insensitive ? value1 === value2 : value1.toLowerCase() === value2.toLowerCase();
};

export const removeDiacritics = (value: string) =>
  diacriticsMap.reduce((acc, current) => acc.replace(current.letters, current.base), value);

/**
 * Remove characters forbidden by IIS (default value of RequestPathInvalidCharacters)
 * @param value to sanitize
 */
export const sanitizeRequestPath = (value: string) => value.replace(/[<>]/g, "");

export const sanitizeSearchTerm = (value: string) =>
  sanitizeRequestPath(removeDiacritics(trim(value || "").toLowerCase()));

export const compareStringWithoutEmpty = (value1: string, value2: string): boolean => {
  if (!value1 || !value2) {
    return true;
  }
  return isStringEqual(value1, value2);
};

export const isContainSubString = (value: string, other: string, trimSpaces = true, ignoreCase = true): boolean => {
  const { value1, value2 } = trimSpaces
    ? { value1: trim(value), value2: trim(other) }
    : { value1: value, value2: other };
  return ignoreCase ? value1.toLowerCase().indexOf(value2.toLowerCase()) >= 0 : value1.indexOf(value2) >= 0;
};

export const isStringEmpty = (value?: string): boolean => value === undefined || value === null || trim(value) === "";

export const stringComparator = (value?: string, other?: string): number =>
  (isStringEmpty(value) && isStringEmpty(other)) || isStringEqual(value, other)
    ? 0
    : trim(value).toLowerCase() > trim(other).toLowerCase()
    ? 1
    : -1;

export const plurialize = (word: string, n: number) => `${word}${n > 1 ? "s" : ""}`;
