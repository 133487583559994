import * as React from "react";
import { SetSettings, Domain } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { DomainEnum } from "api/request/request.typings";
import { isEmpty } from "lodash";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { getClaimUrl } from "./visibility.state.logic";
import { ProfileSelect } from "./ProfileSelect";

export interface StateProps {
  domainsConfig: Domain[];
  domainSettings: SetSettings[];
  dimensions: Dimension[];
  domainId: DomainEnum;
}

export interface DispatchProps {
  onClaimValueChanged?: (domainId: DomainEnum, claimId: string, values: string[]) => void;
  fetchClaimValues: (claimId: string, claimServiceUrl: string) => void;
}

export type Props = StateProps & DispatchProps;

export const VisibilityModule: React.FunctionComponent<Props> = ({
  domainsConfig,
  dimensions,
  domainSettings,
  domainId,
  fetchClaimValues,
  onClaimValueChanged,
}) => {
  React.useEffect(() => {
    if (!isEmpty(domainsConfig)) {
      const profileClaimUrl = getClaimUrl(domainsConfig, domainId, "profile");
      fetchClaimValues("profile", profileClaimUrl);
    }
  }, []);

  return (
    <div className="form-group my-2 mx-3">
      <div className="row justify-content-start">
        <ProfileSelect
          dimensions={dimensions}
          onClaimValueChanged={onClaimValueChanged}
          domainSettings={domainSettings}
          domainId={domainId}
        />
      </div>
    </div>
  );
};
