import { ActionsObservable, Epic } from "redux-observable";
import { catchError, filter, flatMap, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { of } from "rxjs";
import { createRequestAction } from "./request.create.action";
import { createRequest } from "api/request/request.api";
import { mapToUserRequestCreateDTO } from "api/request/request.mapper";
import { push } from "connected-react-router";
import { ROUTES } from "common/routes";
import { generateUri } from "utils/uris/generateUri";

export const createRequestEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(createRequestAction.started.match),
    switchMap(({ payload, meta }) =>
      createRequest(mapToUserRequestCreateDTO(payload), meta.headers).pipe(
        flatMap(request => of(push(generateUri(ROUTES.REQUEST_DETAIL, request)))),
        catchError(({ message }) => of(createRequestAction.failed({ error: message, params: payload })))
      )
    )
  );
