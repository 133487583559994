import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { RouteComponentProps } from "react-router";
import { get } from "lodash";
import { DispatchProps, StateProps, UserCoverage } from "components/User/UserDetail/UserCoverage";
import { Dispatch } from "redux";
import { fetchUserCoverageAction } from "../detail/user.detail.action";

type OwnProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (
  { userState, connectedUserProfile, contactState }: GlobalState,
  props: OwnProps
): StateProps => ({
  userId: props.match.params.id,
  userRight: connectedUserProfile.userInfo.userRight,
  userEmail: userState.userDetail.requests?.[0].user?.email,
  coverage: userState.userDetail.coverage,
  isFetching: userState.userDetail.isFetching,
  errorMessage: userState.userDetail.errorMessage,
  applications: get(contactState.contact, "applications"),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onFetchUserCoverage: (userEmail: string) => dispatch(fetchUserCoverageAction.started(userEmail)),
});

export const UserCoverageContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(UserCoverage);
