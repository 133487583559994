import { ActionsObservable, combineEpics } from "redux-observable";
import { catchError, debounceTime, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { of } from "rxjs";
import { fetchUserRequestByTerm } from "api/request/request.api";
import { fetchRequestsAction } from "./request.list.action";
import { fetchExtendedContactsByIds } from "api/contacts/contact.api";
import { isEmpty, map as lodashMap } from "lodash";
import { RequestPageDTO } from "api/request/request.typings";
import { IcHttpResquestHeader } from "api/api.typings";
import { mapToUserRequestsPage } from "api/request/request.mapper";
import { sanitizeSearchTerm } from "utils/strings/stringUtils";
import { RequestListFilter } from "./request.list.typings";

const fetchUserByIds = (payload: RequestListFilter, requestPage: RequestPageDTO, header: IcHttpResquestHeader) =>
  fetchExtendedContactsByIds(
    lodashMap(requestPage.requests, ({ userId }) => userId),
    header
  ).pipe(
    map(contacts => mapToUserRequestsPage(requestPage, contacts)),
    map(finalResult => fetchRequestsAction.done({ result: finalResult, params: payload })),
    catchError(({ message }) => of(fetchRequestsAction.failed({ error: message, params: null })))
  );

export const fetchRequestsEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchRequestsAction.started.match),
    debounceTime(300),
    map(({ payload, meta }) => ({
      payload: {
        term: sanitizeSearchTerm(payload.term),
        applicationId: payload.applicationId,
        statusId: payload.statusId,
        page: payload.page,
        pageSize: payload.pageSize,
      },
      meta,
    })),
    switchMap(({ payload, meta }) =>
      fetchUserRequestByTerm(payload, meta.headers).pipe(
        switchMap(requestPage =>
          !isEmpty(requestPage)
            ? fetchUserByIds(payload, requestPage, meta.headers)
            : of(
                fetchRequestsAction.done({
                  result: { requests: [], hasNextPage: false, hasPreviousPage: false },
                  params: payload,
                })
              )
        ),
        catchError(({ message }) => of(fetchRequestsAction.failed({ error: message, params: null })))
      )
    )
  );

export const requestListEpic = combineEpics(fetchRequestsEpic);
