import * as React from "react";
import { getWidgetBus } from "common/auth/getWidgetBus";
import { Loading } from "components/common/Loading/Loading";
import { If } from "components/common/If/If";

export interface DispatchProps {
  fetchUserInfo: (userEmail: string) => void;
}

export interface StateProps {
  loggedIn: boolean;
}

export type Props = StateProps & DispatchProps;

export class Authentication extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    const bus = getWidgetBus();

    if (bus) {
      bus.subscribe("sg-connect.user-connection", userConnection => {
        if (userConnection) {
          this.props.fetchUserInfo(userConnection.mail);
        }
      });
    }
  }

  public render() {
    const { loggedIn, children } = this.props;
    // eslint-disable-next-line react/no-children-prop
    return <If condition={loggedIn} children={children} elseComponent={<Loading />} />;
  }
}
