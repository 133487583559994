import actionCreatorFactory from "typescript-fsa";
import { Dimension } from "../requestVisibilitySet/request.visibility.set.typings";

const enum DataQualityVisibilityActionName {
  FETCH_DATAQUALITY_PROFILES_CLAIM_VALUES = "FETCH_DATAQUALITY_PROFILES_CLAIM_VALUES",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchDataQualityProfilesClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string },
  Dimension,
  string
>(DataQualityVisibilityActionName.FETCH_DATAQUALITY_PROFILES_CLAIM_VALUES);
