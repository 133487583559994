import { connect } from "react-redux";
import { Dispatch } from "redux";
import { fetchExportRequestAction } from "./request.export.action";
import { GlobalState } from "store/store.typings";
import { Export, StateProps, DispatchProps } from "components/Request/ExportToExcel/ExportToExcel";

const mapStateToProps = ({ requestState }: GlobalState): StateProps => ({
  isFetching: requestState.exportRequest.isFetching,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onFetchExportRequest: () => dispatch(fetchExportRequestAction.started(null)),
});

export const ExportContainer = connect(mapStateToProps, mapDispatchToProps)(Export);
