import * as React from "react";
import { ClickableButtonProps } from "components/common/Buttons/button.typings";
import { noop } from "lodash";

export const CancelButton: React.FunctionComponent<ClickableButtonProps> = ({ label, onClick, className }) => {
  const handleOnclick = onClick || noop;
  return (
    <button type="button" className={className ? className : "btn btn-lg btn-outline-primary"} onClick={handleOnclick}>
      {label || "Cancel"}
    </button>
  );
};
