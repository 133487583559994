import { getApiServiceUrl } from "common/getApiServiceUrl";
import { ajax } from "rxjs/ajax";
import { expand, map, scan, toArray } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { flatten } from "lodash";
import { IcHttpResquestHeader, PaginatedItems } from "api/api.typings";
import { toastifyError } from "utils/toastify/toasterNotifyer";
import { stringifyParams, stringifyLists } from "utils/strings/stringify";

export const DefaultHeader = { clientApp: "cerberus", "Content-Type": "application/json" };

export const createRepository = (usingMock = false, ajaxObservable = ajax) => ({
  // eslint-disable-next-line @typescript-eslint/ban-types
  getMock: <T>(url: string, headers?: Object) => get<T>(ajaxObservable, url, { ...DefaultHeader, ...headers }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  get: <T>(url: string, headers: IcHttpResquestHeader, params?: Object, noToastOnError?: boolean) =>
    get<T>(
      ajaxObservable,
      `${getApiServiceUrl(url, usingMock)}${url}${stringifyParams(params)}`,
      {
        ...DefaultHeader,
        ...headers,
      },
      noToastOnError
    ),
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPaginatedList: <T>(url: string, headers: IcHttpResquestHeader, params?: Object, dataFieldName = "items") =>
    getPaginatedList<T>(
      ajaxObservable,
      `${getApiServiceUrl(url)}${url}${stringifyParams(stringifyLists(params))}`,
      {
        ...DefaultHeader,
        ...headers,
      },
      dataFieldName
    ),
  post: <T>(url: string, headers: IcHttpResquestHeader, body: any) =>
    ajaxObservable.post(`${getApiServiceUrl(url)}${url}`, body, { ...DefaultHeader, ...headers }).pipe<T, T>(
      map(item => item.response as T),
      toastifyError<T>()
    ),
  put: <T>(url: string, headers: IcHttpResquestHeader, body: any) =>
    ajaxObservable.put(`${getApiServiceUrl(url)}${url}`, body, { ...DefaultHeader, ...headers }).pipe<T, T>(
      map(item => item.response as T),
      toastifyError<T>()
    ),
  delete: <T>(url: string, headers: IcHttpResquestHeader) =>
    ajaxObservable.delete(`${getApiServiceUrl(url)}${url}`, { ...DefaultHeader, ...headers }).pipe<T, T>(
      map(item => item.response as T),
      toastifyError<T>()
    ),
});

// eslint-disable-next-line @typescript-eslint/ban-types
const get = <T>(ajaxObservable = ajax, url: string, headers?: Object, noToastOnError?: boolean) => {
  if (noToastOnError) {
    return ajaxObservable.getJSON<T>(url, { ...headers });
  }
  return ajaxObservable
    .getJSON<T>(url, { ...headers })
    .pipe<T>(toastifyError());
};

// eslint-disable-next-line @typescript-eslint/ban-types
const getPaginatedList = <T>(ajaxObservable, url: string, headers: Object, dataFieldName: string) =>
  get<PaginatedItems<T>>(ajaxObservable, url, headers).pipe<
    PaginatedItems<T>,
    { items: T[] },
    Array<{ items: T[] }>,
    T[]
  >(
    expand<PaginatedItems<T>>(response =>
      response.nextPageUri ? get<PaginatedItems<T>>(ajaxObservable, response.nextPageUri, headers) : EMPTY
    ),
    scan<PaginatedItems<T>, { items: T[] }>((_, paginatedList) => ({ items: [...paginatedList[dataFieldName]] }), {
      items: [],
    }),
    toArray(),
    map(resultList => flatten<T>(resultList.map(({ items }) => items)))
  );
