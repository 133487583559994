import * as React from "react";
import { ROUTES } from "common/routes";
import { ButtonLink } from "components/common/Buttons/ButtonLink";
import { BackButton } from "components/common/Buttons/BackButton";

export interface Props {
  shown: boolean;
  header?: string;
  message?: string;
  goBack?: boolean;
}

export const NotFound: React.SFC<Props> = ({ shown = true, header, message, goBack }) =>
  shown ? (
    <div className="w-50 mx-auto mt-5">
      <h1 className="display-3">{header || "404"}</h1>
      <p className="lead">{message || "The requested resource cannot be found."}</p>
      <div className="ml-1">{goBack ? <BackButton /> : <ButtonLink to={ROUTES.HOME} label={"Home"} />}</div>
    </div>
  ) : null;
