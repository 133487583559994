import { UserProfile } from "containers/userProfile/userProfile.typings";
import { actionCreatorFactory } from "typescript-fsa";

const actionCreator = actionCreatorFactory("user");

export enum UserProfileActionName {
  FETCH_USER_INFO = "FETCH_USER_INFO",
}

export const fetchUserInfo = actionCreator.async<string, UserProfile, string>(UserProfileActionName.FETCH_USER_INFO);
