import { ActionsObservable } from "redux-observable";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { Action } from "redux";
import { EmptyClaimId } from "components/Request/RequestValidate/Tabs/SelectAxis";
import { SetIdEnum } from "../request.visibility.typings";
import { mapDataCollectSuppliersToDimension } from "api/request/visibility/webModules/request.webmodules.mapper";
import { DataCollectSuppliersDTO } from "api/request/visibility/webModules/request.webmodules.typings";
import { fetchDataCollectSuppliersClaimValues } from "api/request/visibility/webModules/request.webmodules.api";
import { DomainEnum } from "api/request/request.typings";
import { fetchDataCollectSuppliersClaimValuesAction } from "./request.datacollect.action";

export const fetchDataCollectSuppliersClaimValuesEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    filter(fetchDataCollectSuppliersClaimValuesAction.started.match),
    switchMap(({ payload, meta }) =>
      (payload.claimId !== EmptyClaimId && payload.claimServiceUrl
        ? fetchDataCollectSuppliersClaimValues(payload.claimServiceUrl, meta.headers)
        : of({ suppliers: [] } as DataCollectSuppliersDTO)
      ).pipe(
        map(suppliersDto =>
          fetchDataCollectSuppliersClaimValuesAction.done({
            result: mapDataCollectSuppliersToDimension(SetIdEnum.One, payload.claimId, suppliersDto, DomainEnum.DATACOLLECT),
            params: undefined,
          })
        ),
        catchError(({ message }) =>
          of(fetchDataCollectSuppliersClaimValuesAction.failed({ error: message, params: payload }))
        )
      )
    )
  );
