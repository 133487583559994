import { toast } from "react-toastify";
import { catchError } from "rxjs/operators";
import { AjaxError } from "rxjs/ajax";
import { get } from "lodash";
import { ProblemDetails } from "api/api.typings";
import { OperatorFunction } from "rxjs";

const genericErrorMessage = "An error occured. Contact the support if the problem persists";

export const toastifyError = <T>(message = genericErrorMessage): OperatorFunction<T, T> =>
  catchError((error: AjaxError) => {
    console.log(error);
    const errorMessage = error && error.response ? getErrorMessage(error.response) : message;

    toast.error(errorMessage);
    throw error;
  });

export const toastifyErrorMessage = (message: string) => toast.error(message);

export const toastifySuccess = (message: string) => {
  toast.success(message);
};

const getErrorMessage = (response: any) => {
  if (typeof response === "string") {
    return response;
  }
  if ((response as ProblemDetails) !== undefined) {
    return response.title + " - " + response.detail;
  }
  return get(response, "error");
};
