import * as React from "react";
import { ErrorInfo } from "react";
import { logError } from "utils/logger/logger";
import { ErrorCanvas } from "components/common/BlankCanvas/ErrorCanvas";

interface ErrorState {
  hasError: boolean;
  errorMessage?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorHandler extends React.Component<{}, ErrorState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorCanvas visible={true} title={this.state.errorMessage} />;
    }
    return this.props.children;
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, errorInfo ? errorInfo.componentStack : undefined);
    this.setState({ hasError: true, errorMessage: error ? error.message : " " });
  }
}
