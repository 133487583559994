import * as React from "react";
import { map, find, filter, flatten } from "lodash";
import {
  Axis,
  AxisSettings,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { IdName } from "common/common.typings";
import { VisibilitySetRow } from "./VisibilitySetRow";

export enum ViewSettingEnum {
  SG,
  MARK,
  EXTENDED_SG,
  EXTENDED_MARK,
  ASOF,
  WEBMODULES,
  DATAQUALITY,
  CLIENTCONTRIBUTION
}
export interface StateProps {
  id: number;
  viewSettingType: ViewSettingEnum;
  axises: Axis[];
  dimensions: Dimension[];
  settings: AxisSettings[];
  allowAccess: boolean;
}
export interface SetIds {
  setId: number;
  axisLabel: string;
  viewSettingType: ViewSettingEnum;
}

export interface DispatchProps {
  fetchClaimValues: (
    setId: number,
    axisLabel: string,
    claimId: string,
    claimServiceUrl: string,
    viewSettingType: ViewSettingEnum
  ) => void;
  onClaimIdChange: (setIds: SetIds, claimId: string, claimLabel: string, operator: string) => void;
  onClaimValueChange: (setIds: SetIds, claimValues: string[]) => void;
  onClaimOperatorChange: (setIds: SetIds, operator: string) => void;
}

export type Props = StateProps & DispatchProps;

export const VisibilitySet: React.FunctionComponent<Props> = ({
  id,
  viewSettingType,
  axises,
  fetchClaimValues,
  onClaimIdChange,
  onClaimValueChange,
  onClaimOperatorChange,
  dimensions,
  settings,
  allowAccess,
}) => {
  const getAxisOptions = (
    dimensionParams: Dimension[],
    visibilitySetId: number,
    axisName: string,
    settingsType: ViewSettingEnum
  ): Array<IdName<string>> => {
    const dimensionsValues = map(
      filter(
        dimensionParams,
        ({ axisLabel, setId, type }) => axisLabel === axisName && setId === visibilitySetId && type === settingsType
      ),
      ({ values }) => values
    );

    return flatten(dimensionsValues);
  };

  const getSetting = (axisSettings: AxisSettings[], axisId: string) =>{
    return find(axisSettings, ({ axisName }) => axisId === axisName);
  }

  return (
    <>
      {map(
        filter(axises, axis => !!axis.axisId),
        ( axis ) => (
          <VisibilitySetRow
          allowAccess={allowAccess}
          axis={axis}
          axisOptions={getAxisOptions(dimensions, id, axis.axisId, viewSettingType)}
          fetchClaimValues={fetchClaimValues}
          id={id}
          onClaimIdChange={onClaimIdChange}
          onClaimValueChange={onClaimValueChange}
          onClaimOperatorChange={onClaimOperatorChange}
          setting={getSetting(settings, axis.axisId)}
          viewSettingType={viewSettingType}
          ></VisibilitySetRow>
        )
      )}
    </>
  );
};
