import * as React from "react";
import { find, get } from "lodash";
import {
  Axis,
  AxisValue,
  AxisSettings,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import { SelectAxis } from "./SelectAxis";
import { MultiSelector } from "components/common/MultiSelector/MultiSelector";
import { IdAndLabel, IdName } from "common/common.typings";
import { getIndicator } from "./visibility.state.logic";
import { Select } from "components/Select/Select";
import { DispatchProps } from "./VisibilitySet";

export enum ViewSettingEnum {
  SG,
  MARK,
  EXTENDED_SG,
  EXTENDED_MARK,
  ASOF,
  WEBMODULES,
  DATAQUALITY,
  CLIENTCONTRIBUTION
}
export interface StateProps {
  id: number;
  viewSettingType: ViewSettingEnum;
  allowAccess: boolean;
  axis: Axis;
  axisOptions: Array<IdName<string>>;
  setting: AxisSettings;
}

export type Props = StateProps & DispatchProps;

export const VisibilitySetRow: React.FunctionComponent<Props> = ({
  id,
  viewSettingType,
  fetchClaimValues,
  onClaimIdChange,
  onClaimValueChange,
  onClaimOperatorChange,
  allowAccess,
  axis,
  axisOptions,
  setting
}) => {
  const operator = React.useRef(setting?.claim?.operator ?? "equal");

  const handleOnClaimIdChange = (
    axisLabel: string,
    viewSettingEnum: ViewSettingEnum,
    claimId: string,
    axisValues: AxisValue[]
  ) => {
    const value = find(axisValues, axisValue => axisValue.claimId === claimId);
    onClaimIdChange({ axisLabel, viewSettingType: viewSettingEnum, setId: id }, claimId, get(value, "claimLabel"), operator.current);
    const indicatorId = getIndicator(viewSettingType);
    fetchClaimValues(
      id,
      axisLabel,
      claimId,
      get(value, "claimValuesUrl") ? get(value, "claimValuesUrl").replace("{indicatorId}", indicatorId) : null,
      viewSettingType
    );
  };

  const handleOnClaimValueChange = (
    axisLabel: string,
    viewSettingTypeParam: ViewSettingEnum,
    claimValues: string[]
  ) => {
    onClaimValueChange({ axisLabel, viewSettingType: viewSettingTypeParam, setId: id }, claimValues);
  };

  const handleOnClaimOperatorChange = (
    axisLabel: string,
    viewSettingTypeParam: ViewSettingEnum,
    operatorId: string
  ) => {
    operator.current = operatorId; 
    onClaimOperatorChange({ axisLabel, viewSettingType: viewSettingTypeParam, setId: id }, operator.current)
  };

  return (
    <>
      <div className="d-flex align-items-end">
        <SelectAxis
          label={axis.axisLabel}
          values={axis.values}
          disabled={!allowAccess}
          initialValue={setting?.claim?.id ?? ""}
          onChange={claimId => handleOnClaimIdChange(axis.axisId, viewSettingType, claimId, axis.values)}
        />
        <div className="form-group col-2">
          <Select<IdAndLabel<string>, string>
            options={[
              { id: "equal", label: "Equal" },
              { id: "notequal", label: "Not Equal" },
            ]}
            onSelectionChange={id => handleOnClaimOperatorChange(axis.axisId, viewSettingType, id)}
            selectedId={setting?.claim?.operator ?? "equal"}
          />
        </div>
        <div className="form-group d-flex col-5 align-items-end">
          <MultiSelector
            selectedItems={setting?.claim?.values ?? []}
            items={axisOptions}
            onChanged={claimValues => handleOnClaimValueChange(axis.axisId, viewSettingType, claimValues)}
            closeOnSelect={false}
            disabled={!allowAccess}
            placeHolder="Select any claim values"
            iconName="account_balance"
          />
        </div>
      </div>
    </>
  );
};
