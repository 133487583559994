import actionCreatorFactory from "typescript-fsa";
import { RequestPage } from "../request.typings";
import { RequestListFilter } from "containers/request/list/request.list.typings";

const enum RequestActionName {
  FETCH_REQUESTS = "FETCH_REQUESTS",
}

const requestActionFactory = actionCreatorFactory("userList");

export const fetchRequestsAction = requestActionFactory.async<RequestListFilter, RequestPage, string>(
  RequestActionName.FETCH_REQUESTS
);
