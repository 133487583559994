import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { Dispatch } from "redux";
import { fetchUserRequestsAction, terminateUserAccessAction } from "./user.detail.action";
import { StateProps, DispatchProps, UserDetailPage } from "components/User/UserDetail/UserDetailPage";
import { RouteComponentProps } from "react-router";
import { ApplicationEnum } from "containers/referential/referential.typings";
import { get } from "lodash";

type OwnProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (
  { userState, connectedUserProfile, contactState }: GlobalState,
  props: OwnProps
): StateProps => ({
  userId: props.match.params.id,
  userRight: connectedUserProfile.userInfo.userRight,
  requests: userState.userDetail.requests,
  isFetching: userState.userDetail.isFetching,
  errorMessage: userState.userDetail.errorMessage,
  applications: get(contactState.contact, "applications"),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onFetchUserRequests: (userId: string) => dispatch(fetchUserRequestsAction.started(userId)),
  terminateAccess: (id: string, applicationId: ApplicationEnum) =>
    dispatch(terminateUserAccessAction.started({ id, applicationId })),
});

export const UserDetailContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailPage);
