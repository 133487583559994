import { ViewSettingEnum } from "components/Request/RequestValidate/Tabs/VisibilitySet";
import actionCreatorFactory from "typescript-fsa";
import { Dimension } from "./request.visibility.set.typings";

const enum RequestVisibilityActionName {
  FETCH_CLAIM_VALUES = "FETCH_CLAIM_VALUES",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchClaimVluesAction = actionCreator.async<
  { setId: number; axisLabel: string; claimId: string; claimServiceUrl: string; viewSettingType: ViewSettingEnum },
  Dimension,
  string
>(RequestVisibilityActionName.FETCH_CLAIM_VALUES);
