import { SetSettings, Domain } from "../request.visibility.typings";
import { GlobalState } from "store/store.typings";
import { connect } from "react-redux";
import { get } from "lodash";
import { fetchClientContributionProfilesClaimValuesAction } from "./request.clientcontribution.action";
import { StateProps, VisibilityModule } from "components/Request/RequestValidate/Tabs/VisibilityModule";
import { DomainEnum } from "api/request/request.typings";

interface OwnProps {
  domainsConfig: Domain[];
  clientContributionSettings: SetSettings[];
}

interface DispatchProps {
  fetchClaimValues: (claimId: string, claimServiceUrl: string) => void;
}

const mapStateToProps = (state: GlobalState, { domainsConfig, clientContributionSettings }: OwnProps): StateProps => {
  return {
    domainsConfig,
    domainSettings: clientContributionSettings,
    domainId: DomainEnum.CLIENTCONTRIBUTION,
    dimensions: get(state, "requestState.requestVisibility.dimensions"),
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => ({
  fetchClaimValues: (claimId: string, claimServiceUrl: string) =>
    dispatch(fetchClientContributionProfilesClaimValuesAction.started({ claimId, claimServiceUrl })),
});

export const VisibilityClientContributionContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(VisibilityModule);
