import { toPairs, isArray, fromPairs } from "lodash";
import { stringify, IStringifyOptions } from "qs";

const stringifyUrlOptions: IStringifyOptions = { arrayFormat: "comma", encode: false };

// eslint-disable-next-line @typescript-eslint/ban-types
export const stringifyLists = (object: Object) => {
  const attributes = toPairs(object).map(([key, value]) => (isArray(value) ? [key, value.join(",")] : [key, value]));
  return fromPairs(attributes);
};

export const stringifyParams = params => (params ? "?" + stringify(params, stringifyUrlOptions) : "");
