import { AlertMessage } from "containers/request/validate/requestVisibility/request.visibility.typings";
import React from "react";

export const WarningAlertMessage: React.FunctionComponent = () => (
  <div className="pt-4">
    <div className="d-inline" style={{ width: "60px" }}>
      <span>
        <b>Warning:</b>
      </span>
    </div>
    <div className="col d-inline text-warning" style={{ maxWidth: "fit-content" }}>
      {AlertMessage}
    </div>
  </div>
);
