import {
  UserRequestDetailDTO,
  UserRequestDTO,
  UserRequestCreateDTO,
  RequestPageDTO,
  UserRequestExportDTO,
} from "api/request/request.typings";
import { find, map } from "lodash";
import { UserRequest, UserRequestDetail, UserRequestCreate, RequestPage } from "containers/request/request.typings";
import { Contact } from "containers/contact/contact.typings";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";
import { formatDate } from "utils/dates/dateUtils";
import { IdAndLabel } from "common/common.typings";
import { isStringEmpty, isStringEqual } from "utils/strings/stringUtils";
import { mapClaimsDtoToSettings } from "containers/request/validate/requestVisibility/request.visibility.mapper";

export const mapToUserRequest = (userRequestDTO: UserRequestDTO, contacts: Contact[] = []): UserRequest => {
  const contact = find(contacts, ({ id }) => isStringEqual(id, userRequestDTO.userId));
  return {
    contactId: userRequestDTO.userId,
    requestId: userRequestDTO.requestId,
    validatorComment: userRequestDTO.validatorComment,
    endDate: userRequestDTO.endDate,
    lastModificationDate: formatDate(userRequestDTO.lastModificationDate),
    status: mapToIdAndLabel<StatusEnum>(userRequestDTO.lastRequestStatusId, userRequestDTO.lastRequestStatus),
    application: mapToIdAndLabel<ApplicationEnum>(userRequestDTO.applicationId, userRequestDTO.application),
    user: contact ? contact : ({ email: userRequestDTO.email, id: userRequestDTO.userId } as Contact),
    validators: userRequestDTO.validators,
  };
};

export const mapToUserRequestsPage = (userRequestsDTO: RequestPageDTO, contacts: Contact[] = []): RequestPage => ({
  requests: mapToUserRequests(userRequestsDTO.requests, contacts),
  hasNextPage: userRequestsDTO.hasNextPage,
  hasPreviousPage: userRequestsDTO.hasPreviousPage,
});

export const mapToUserExportRequest = (userRequestDTO: UserRequestExportDTO, contacts: Contact[]): any => {
  const contact = find(contacts, ({ id }) => isStringEqual(id, userRequestDTO.contactId));
  const exportObject = {
    email: userRequestDTO.email,
    fullName: "",
    country: "",
    department: "",
    status: userRequestDTO.status,
    startDate: userRequestDTO.startDate,
    endDate: userRequestDTO.endDate,
    view: userRequestDTO.domain,
    visibility: userRequestDTO.visibility,
  };
  if (contact) {
    exportObject.fullName = contact.fullName;
    exportObject.country = contact.country;
    exportObject.department = contact.internalHrTeam;
  }
  return exportObject;
};

export const mapToUserRequests = (userRequestsDTO: UserRequestDTO[], contacts: Contact[]): UserRequest[] =>
  userRequestsDTO.map(request => mapToUserRequest(request, contacts));

export const mapToExportUserRequests = (userRequestsDTO: UserRequestExportDTO[], contacts: Contact[]): any[] =>
  userRequestsDTO.map(request => mapToUserExportRequest(request, contacts));

export const mapToIdAndLabel = <T>(id: any, label: string): IdAndLabel<T> =>
  isStringEmpty(id) && isStringEmpty(label) ? null : { id: id as any, label };

export const mapToRequestDetail = (
  userRequestDetailDTO: UserRequestDetailDTO,
  user?: Contact,
  contacts?: Contact[]
): UserRequestDetail => ({
  // TODO: need user in UserRequestDetail ?
  user,
  validator: find(contacts, ({ id }) => userRequestDetailDTO.validatorId === id),
  requestId: userRequestDetailDTO.requestId,
  contactId: userRequestDetailDTO.userId,
  status: mapToIdAndLabel<StatusEnum>(userRequestDetailDTO.statusId, userRequestDetailDTO.status),
  application: mapToIdAndLabel<ApplicationEnum>(userRequestDetailDTO.applicationId, userRequestDetailDTO.application),
  validatorComment: userRequestDetailDTO.validatorComment,
  userComment: userRequestDetailDTO.userComment,
  createdDate: formatDate(userRequestDetailDTO.createdDate),
  startDate: formatDate(userRequestDetailDTO.startDate),
  endDate: formatDate(userRequestDetailDTO.endDate),
  modifiedDate: formatDate(userRequestDetailDTO.modifiedDate),
  modifiedUser: find(contacts, ({ id }) => userRequestDetailDTO.modifiedUserId === id),
  requestVisibility: mapClaimsDtoToSettings(userRequestDetailDTO.claims),
  isValidateCommentRequired: userRequestDetailDTO.isValidateCommentRequired,
  impulseId: userRequestDetailDTO.impulseId,
  cerberusRole: userRequestDetailDTO.cerberusRole,
  validators: userRequestDetailDTO.validators,
  requestor: find(contacts, ({ id }) => userRequestDetailDTO.requestorId === id),
  needKoreanData: userRequestDetailDTO.needKoreanData,
  koreanDataComment: userRequestDetailDTO.koreanDataComment
});

export interface UserRequestWithMeta {
  userRequestDetailDTO: UserRequestDetailDTO;
  user: Contact;
  contacts: Contact[];
}

export const mapToUserRequestsDetails = (userRequests: UserRequestWithMeta[]): UserRequestDetail[] =>
  map(userRequests, ({ userRequestDetailDTO, user, contacts }) =>
    mapToRequestDetail(userRequestDetailDTO, user, contacts)
  );

export const mapToUserRequestCreateDTO = (userRequestCreate: UserRequestCreate): UserRequestCreateDTO => {
  return {
    userId: userRequestCreate.contactId,
    applicationId: userRequestCreate.applicationId,
    cerberusRoleId: userRequestCreate.cerberusRoleId,
    userComment: userRequestCreate.popsComment,
    impulseId: userRequestCreate.impulseId,
  };
};
