import { noop } from "lodash";
import * as React from "react";

interface ClearButtonProps {
  onClear?: () => void;
  label?: string;
  className?: string;
}

export const ClearButton: React.FunctionComponent<ClearButtonProps> = ({ onClear = noop, label, className }) => (
  <div className={className}>
    <button type="button" className="btn btn-link px-1" onClick={onClear}>
      {label || "Clear"}
    </button>
  </div>
);
