import { ROUTES } from "common/routes";
import { Contact } from "containers/contact/contact.typings";
import { get } from "lodash";
import * as React from "react";
import { ButtonLink } from "../Buttons/ButtonLink";

export interface StateProps {
  contact: Contact;
  userEmail: string;
}

export interface DispatchProps {
  updateUserEmail: (contactId: string) => void;
}
export type Props = StateProps & DispatchProps;

export const UserEmailDiscrepancy: React.FunctionComponent<Props> = ({ contact, userEmail, updateUserEmail }) => {
  const showEmailDiscrepancyAlert = get(contact, "email") !== userEmail;

  const handleEmailUpdate = () => {
    updateUserEmail(get(contact, "id"));
  };
  return (
    showEmailDiscrepancyAlert && (
      <>
        <div role="alert" className="alert alert-danger">
          <strong>IMPORTANT Notification!</strong> User email discrepancy detected, fix it through this{" "}
          <a href="javascript: void(0)" onClick={handleEmailUpdate}>
            link
          </a>
        </div>
      </>
    )
  );
};
