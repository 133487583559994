import { RequestVisibilityState, initState } from "../request.visibility.reducer";
import { isType } from "typescript-fsa";
import { filter } from "lodash";
import reduceReducer from "reduce-reducers";
import {
  fetchWebModulesSuppliersClaimValuesAction,
  fetchWebModulesProfilesClaimValuesAction,
  fetchWebModulesSBLClaimValuesAction,
} from "./request.webmodules.action";
import { DomainEnum } from "api/request/request.typings";

export const fetchWebModulesVisibilityClaimValuesReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (
    isType(action, fetchWebModulesProfilesClaimValuesAction.started) ||
    isType(action, fetchWebModulesSuppliersClaimValuesAction.started) ||
    isType(action, fetchWebModulesSBLClaimValuesAction.started)
  ) {    
    
    return { ...state, isClaimValuesFetching: true, errorMessage: null };
  }
  if (
    isType(action, fetchWebModulesProfilesClaimValuesAction.done) ||
    isType(action, fetchWebModulesSuppliersClaimValuesAction.done) ||
    isType(action, fetchWebModulesSBLClaimValuesAction.done)
  ) {    
    
    if (action.payload.result.domainId === DomainEnum.MASSUPLOAD)
    {
        return { ...state, dimensions: [...filter(state.dimensions, ({ domainId }) => domainId !== action.payload.result.domainId), action.payload.result], isClaimValuesFetching: false };
    }
    const rest = filter(state.dimensions, ({ axisLabel }) => axisLabel !== action.payload.result.axisLabel);
    
    return { ...state, dimensions: [...rest, action.payload.result], isClaimValuesFetching: false };
  }
  if (
    isType(action, fetchWebModulesProfilesClaimValuesAction.failed) ||
    isType(action, fetchWebModulesSuppliersClaimValuesAction.failed) ||
    isType(action, fetchWebModulesSBLClaimValuesAction.failed)
  ) {
    return { ...state, isClaimValuesFetching: false, errorMessage: action.payload.error };
  }
  
  return state;
};

export const requestWebModulesReducer = reduceReducer(fetchWebModulesVisibilityClaimValuesReducer);
