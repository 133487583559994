export const whiteList = [
  "rozenn.le-mee@sgcib.com",
  "william.eglenne@sgcib.com",
  "xavier.boschel@sgcib.com",
  "ousmane-hamadou.sow-ext@sgcib.com",
  "samar.ben-ammar@sgcib.com",
  "muriel.lin-si-ext@sgcib.com",
  "claire.le-tutour@sgcib.com",
  "ajith.av@socgen.com",
  "mahesh.babu@socgen.com",
  "pushpendra.choubey@socgen.com",
  "renjith.kurup@socgen.com",
  "nicolas.viel-ext@sgcib.com"
];
