import { RequestVisibilityState, initState } from "../request.visibility.reducer";
import { isType } from "typescript-fsa";
import { filter } from "lodash";
import reduceReducer from "reduce-reducers";
import { fetchDataQualityProfilesClaimValuesAction } from "./request.dataquality.action";

export const fetchDataQualityVisibilityClaimValuesReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchDataQualityProfilesClaimValuesAction.started)) {
    return { ...state, isClaimValuesFetching: true, errorMessage: null };
  }
  if (isType(action, fetchDataQualityProfilesClaimValuesAction.done)) {
    const rest = filter(state.dimensions, ({ axisLabel }) => axisLabel !== action.payload.result.axisLabel);
    return { ...state, dimensions: [...rest, action.payload.result], isClaimValuesFetching: false };
  }
  if (isType(action, fetchDataQualityProfilesClaimValuesAction.failed)) {
    return { ...state, isClaimValuesFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const requestDataQualityReducer = reduceReducer(fetchDataQualityVisibilityClaimValuesReducer);
