import { KeyValue } from "api/request/request.typings";
import { Loading } from "components/common/Loading/Loading";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { Applications, ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { isEmpty, replace } from "lodash";
import { useState } from "react";
import React = require("react");
import ReactPaginate from "react-paginate";
export interface StateProps {
  userRight: ConnectedUserProfileRights;
  userId: string;
  userEmail: string;
  coverage: KeyValue[];
  applications: Applications;
  isFetching: boolean;
  errorMessage: string;
}

export interface DispatchProps {
  onFetchUserCoverage: (userEmail: string) => void;
}

export type Props = StateProps & DispatchProps;

export const UserCoverage: React.FunctionComponent<Props> = ({
  isFetching,
  userEmail,
  coverage,
  onFetchUserCoverage,
}: Props) => {
  const [filteredResults, setFilteredResults] = useState(coverage);
  const [pageNumber, setPageNumber] = useState(0);
  const [paginatedClients, setPaginatedClients] = useState([]);
  const perPage = 15;

  React.useEffect(() => {
    if (userEmail && !coverage) {
      onFetchUserCoverage(userEmail);
    }
  }, [userEmail]);

  const handlePageClick = event => {
    setPageNumber(event.selected);
  };

  const onSearch = (term: React.ChangeEvent<HTMLInputElement>) => {
    if (!isEmpty(term.target.value)) {
      const filteredClients = coverage.filter(
        name =>
          name.value.toLowerCase().indexOf(term.target.value.toLowerCase()) !== -1 ||
          name.key.toLowerCase().indexOf(term.target.value.toLowerCase()) !== -1
      );
      setFilteredResults(filteredClients);
    } else {
      setFilteredResults(coverage);
    }
    setPageNumber(0);
  };

  React.useEffect(() => {
    if (filteredResults) {
      setPaginatedClients(filteredResults.slice(pageNumber * perPage, (pageNumber + 1) * perPage).sort());
    }
  }, [filteredResults, pageNumber]);

  return (
    <>
      <Loading isVisible={isFetching} />
      <div className="table table-responsive-md pb-5">
        <div className="col-md-4 pl-0">
          <FormField id="search" label="">
            <input className={`form-control`} placeholder="Search" onChange={onSearch} />
          </FormField>
        </div>
        {paginatedClients.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  <th>Client name / BDR ccial ID</th>
                </tr>
              </thead>
              <tbody>
                {paginatedClients.map((post: KeyValue, index) => (
                  <tr key={index}>
                    <td>
                      {replace(post.value, /"/g, "")} / {post.key}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              pageCount={Math.ceil(filteredResults.length / perPage)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageRangeDisplayed={undefined}
              marginPagesDisplayed={undefined}
            />
          </>
        )}
      </div>
      {paginatedClients.length === 0 && <label>No coverage found for this user</label>}
    </>
  );
};
