import { GlobalState } from "store/store.typings";
import { connect } from "react-redux";
import { UserEmailDiscrepancy, StateProps, DispatchProps } from "components/common/PageHeader/UserEmailDiscrepancy";
import { get } from "lodash";
import { updateUserEmailAction } from "containers/user/detail/user.detail.action";
import { Dispatch } from "redux";

const mapStateToProps = ({ contactState }: GlobalState): StateProps => {
  return {
    contact: contactState.contact,
    userEmail: get(contactState.contact, "cerberusUserEmail"),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  updateUserEmail: (id: string) => dispatch(updateUserEmailAction.started({ id })),
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const EmailDiscrepancyContainer = connect<StateProps, DispatchProps, {}, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(UserEmailDiscrepancy);
