import appLogo from "assets/images/app-logo.png";
import sgLogo from "assets/images/sg-logo.png";
import * as React from "react";
import { ROUTES } from "common/routes";
import { HeaderMenu } from "components/layout/Header/HeaderMenu";
import { IconCaretDown } from "components/common/Icon/Icon";
import { Link } from "react-router-dom";
import { HeaderContainer } from "containers/header/header.container";
import { getEnvironment } from "common/environment";

export const Header: React.FunctionComponent = () => {
  const environment = getEnvironment();
  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-light bg-white shadow-lg">
        <div className="d-flex">
          <Link to={ROUTES.HOME} className="navbar-brand d-flex align-items-center">
            <img src={sgLogo} alt="Logo SG" height="32" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="icon">
              <IconCaretDown />
            </i>
          </button>
        </div>

        <div className="d-flex align-items-center order-xl-1">
          <HeaderContainer environment={environment} />
          <a className="d-none d-md-block overflow-visible ml-3">
            <img src={appLogo} />
          </a>
        </div>
        <div className="collapse navbar-collapse" id="main-navbar">
          <HeaderMenu />
        </div>
      </nav>
    </>
  );
};
