import { UserRequest } from "../request.typings";
import { isType } from "typescript-fsa";
import { validateRequestAction } from "./request.validate.action";

export interface RequestValidateState {
  request: UserRequest;
  isFetching: boolean;
  requestId?: string;
  errorMessage: string;
}

export const initUserState: RequestValidateState = {
  request: null,
  errorMessage: null,
  isFetching: false,
};

export const requestValidateReducer = (state: RequestValidateState = initUserState, action): RequestValidateState => {
  if (isType(action, validateRequestAction.started)) {
    return { ...state, isFetching: true, request: null, errorMessage: null };
  }
  if (isType(action, validateRequestAction.done)) {
    return { ...state, isFetching: false };
  }
  if (isType(action, validateRequestAction.failed)) {
    return { ...state, isFetching: false, request: null, errorMessage: action.payload.error };
  }
  return { ...state };
};
