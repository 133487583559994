import { DomainEnum } from "api/request/request.typings";
import actionCreatorFactory from "typescript-fsa";
import { Dimension } from "../requestVisibilitySet/request.visibility.set.typings";

const enum WebModulesVisibilityActionName {
  FETCH_WEBMODULES_PROFILES_CLAIM_VALUES = "FETCH_WEBMODULES_PROFILES_CLAIM_VALUES",
  FETCH_WEBMODULES_SUPPLIERS_CLAIM_VALUES = "FETCH_WEBMODULES_SUPPLIERS_CLAIM_VALUES",
  FETCH_WEBMODULES_SBL_CLAIM_VALUES = "FETCH_WEBMODULES_SBL_CLAIM_VALUES",
  FETCH_WEBMODULES_DATACOLLECT_SUPPLIERS_CLAIM_VALUES = "FETCH_WEBMODULES_DATACOLLECT_SUPPLIERS_CLAIM_VALUES",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchWebModulesProfilesClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string },
  Dimension,
  string
>(WebModulesVisibilityActionName.FETCH_WEBMODULES_PROFILES_CLAIM_VALUES);

export const fetchWebModulesSuppliersClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string; domainId: DomainEnum },
  Dimension,
  string
>(WebModulesVisibilityActionName.FETCH_WEBMODULES_SUPPLIERS_CLAIM_VALUES);

export const fetchWebModulesSBLClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string },
  Dimension,
  string
  >(WebModulesVisibilityActionName.FETCH_WEBMODULES_SBL_CLAIM_VALUES);
