import { combineReducers } from "redux";
import { UserDetailState, userDetailReducer } from "./detail/user.detail.reducer";

export interface UserState {
  userDetail: UserDetailState;
}

export const userReducer = combineReducers<UserState>({
  userDetail: userDetailReducer,
});
