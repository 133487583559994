const USER_ROUTES = {
  USER_LIST: "/user",
  USER_DETAIL: "/user/:id",
  USER_DETAIL_INFO: "/user/:id/info",
  USER_REQUESTS_LIST: "/user/:id/request",
  USER_COVERAGE: "/user/:id/coverage",
};

const REQUEST_ROUTES = {
  REQUEST_LIST: "/request",
  REQUEST_CREATE: "/request/create",
  REQUEST_DETAIL_MAIN_VISIBILITY: "/request/:id/details/visibility/:type",
  REQUEST_DETAIL_WEBMODULES_VISIBILITY: "/request/:id/details/visibility/:type",
  REQUEST_DETAIL_EXTENDED_VISIBILITY: "/request/:id/details/visibility/:type",
  REQUEST_DETAIL_USER_INFO: "/request/:id/details/userinfo",
  REQUEST_DETAIL: "/request/:id/details",
  REQUEST_DETAIL_DATAQUALITY_VISIBILITY: "/request/:id/details/visibility/:type",
  REQUEST_DETAIL_CLIENTCONTRIBUTION_VISIBILITY: "/request/:id/details/visibility/:type"
};

export const ROUTES = {
  ...USER_ROUTES,
  ...REQUEST_ROUTES,
  HOME: "/",
  NOT_FOUND: "/404",
};
