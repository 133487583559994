import * as React from "react";
import { Role, RoleEnum } from "containers/referential/referential.typings";
import { noop } from "lodash";
import { Select } from "components/Select/Select";

export interface StateProps {
  selectedId?: RoleEnum;
  roles: Role[];
}

export interface DispatchProps {
  onFetchApplications: () => void;
  onSelectionChange?: (cerberusRoleId: RoleEnum) => void;
}

export type Props = StateProps & DispatchProps;

export class RoleSelect extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    this.props.onFetchApplications();
  }

  public render() {
    const { selectedId = null, roles, onSelectionChange = noop } = this.props;
    return <Select<Role, RoleEnum> options={roles} selectedId={selectedId} onSelectionChange={onSelectionChange} />;
  }
}
