import { IcHttpResquestHeader } from "api/api.typings";
import { Application, ReferentialDTO, Role, Status } from "containers/referential/referential.typings";
import { Observable } from "rxjs";
import { createRepository } from "api/api";

export const fetchApplications = (headers: IcHttpResquestHeader): Observable<ReferentialDTO<Application>> =>
  createRepository().get<ReferentialDTO<Application>>("/api/v1/applications", headers);

export const fetchStatuses = (headers: IcHttpResquestHeader): Observable<ReferentialDTO<Status>> =>
  createRepository().get<ReferentialDTO<Status>>("/api/v1/statuses", headers);

export const fetchRoles = (headers: IcHttpResquestHeader): Observable<ReferentialDTO<Role>> =>
  createRepository().get<ReferentialDTO<Role>>("/api/v1/cerberusroles", headers);
