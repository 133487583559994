import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { StateProps, DispatchProps } from "components/User/UserDetail/UserDetailPage";
import { RouteComponentProps } from "react-router";
import { get } from "lodash";
import { UserRequestList } from "components/User/UserDetail/UserRequestsList";
type OwnProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = (
  { userState, connectedUserProfile, contactState }: GlobalState,
  props: OwnProps
): StateProps => ({
  userId: props.match.params.id,
  userRight: connectedUserProfile.userInfo.userRight,
  requests: userState.userDetail.requests,
  isFetching: false,
  errorMessage: userState.userDetail.errorMessage,
  applications: get(contactState.contact, "applications"),
});

export const UserRequestsContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(mapStateToProps)(
  UserRequestList
);
