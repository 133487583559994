import * as React from "react";
import { Footer } from "components/layout/Footer/Footer";
import { Header } from "components/layout/Header/Header";
import { PositionOptions, ToastContainer } from "react-toastify";

export const MainLayout: React.FunctionComponent = ({ children }) => (
  <>
    <Header />
    <main role="main" className="container-fluid bg-white px-5 pb-5 mb-5">
      {children}
    </main>
    <ToastContainer hideProgressBar={true} autoClose={5000} position={"top-center" as PositionOptions} />
    <Footer className={"fixed-bottom"} />
  </>
);
