import { AsyncActionTypes, LogMessage, LogTypes } from "utils/logger/logger.typings";
import { Maybe } from "utils/monads/maybe";
import { isEmpty } from "lodash";
import { ActionLog, ActionMeta, AsyncActionLog } from "common/action.meta";
import { AnyAction } from "redux";
import { createLogger, Environment, Level } from "@dcc-cli/cli-web-logger";
import { getEnvironment } from "common/environment";

const mapToLogApiEnvironment = (environment: string): Environment => {
  switch (environment) {
    case "DEV":
    case "UAT":
      return "HOM";
    case "ISO":
      return "ISO";
    case "PROD":
      return "PRD";
    default:
      return "HOM";
  }
};

type Logger = ReturnType<typeof createLogger>;

const logger = createLogger("CPR", mapToLogApiEnvironment(getEnvironment()));

export const logAction = (action: AnyAction) => {
  createLogMessageFromAction(action).do(logMessage);
};

export const createLogMessageFromAction = ({ meta, type }: AnyAction): Maybe<LogMessage> => {
  if (!isActionCanBeLogged(meta, type)) {
    return Maybe.none();
  }
  const logger = meta.logger;
  return Maybe.some(
    getAsyncActionLogMessage(type, logger as AsyncActionLog).getOrElse(getActionLogMessage(type, logger as ActionLog))
  );
};

export const logError = (error: Error, stack: string) => {
  logger.technical("UnknownError", `${error.message}${stack}`, Level.Error);
};

export const logMessage = (message: LogMessage, customLogger = logger): void => {
  const logger: Logger = customLogger.setCustom("feature", message.feature).setCustom("event", message.event);
  switch (message.type) {
    case LogTypes.feature:
      logger.functional(message.name, message.description);
      break;
    case LogTypes.functional:
      logger.functional(message.name, message.description);
      break;
    case LogTypes.technical:
      logger.technical(message.name, message.description, message.level ? message.level : Level.Error);
      break;
  }
};

const getAsyncActionLogMessage = (name: string, { event, description }: AsyncActionLog): Maybe<LogMessage> => {
  const message = { name, feature: name.split("/")[0], event, description };
  if (name.indexOf(AsyncActionTypes.started) >= 0 || name.indexOf(AsyncActionTypes.done) >= 0) {
    return Maybe.some<LogMessage>({ ...message, type: LogTypes.feature, level: Level.Info });
  } else if (name.indexOf(AsyncActionTypes.failed) >= 0) {
    return Maybe.some<LogMessage>({ ...message, type: LogTypes.technical, level: Level.Error });
  } else {
    return Maybe.none<LogMessage>();
  }
};

const getActionLogMessage = (name, { description, event, level, logType }: ActionLog): LogMessage => ({
  name,
  type: logType,
  feature: name.split("/")[0],
  event,
  description,
  level,
});

const isActionCanBeLogged = (meta: ActionMeta, actionName: string): boolean => {
  return !isEmpty(actionName) && actionName.split("/").length > 0 && !!meta && !!meta.logger;
};
