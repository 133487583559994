import * as React from "react";
import {
  SetSettings,
  Domain,
  SetIdEnum,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import { DomainEnum } from "api/request/request.typings";
import { isEmpty, find, get, filter, first, map, flatten } from "lodash";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";
import { IdNames } from "common/common.typings";
import { WebModulesCheckboxes } from "./WebModulesCheckboxes";
import { WebModuleProfileSelect } from "./WebModuleProfileSelect";
import { getClaimUrl } from "./visibility.state.logic";

export interface StateProps {
  domainsConfig: Domain[];
  webModulesSettings: SetSettings[];
  dimensions: Dimension[];
}

export interface DispatchProps {
  onWebModuleSelectionChanged: (domainId: DomainEnum, checked: boolean) => void;
  onClaimValueChanged?: (domainId: DomainEnum, claimId: string, values: string[]) => void;
  fetchClaimValues: (claimId: string, claimServiceUrl: string) => void;
}

export type Props = StateProps & DispatchProps;

export const isWebModuleChecked = {
  MASSUPLOAD: false,
  PSM: false,
  CORRECTION: false,
  FORECAST: false,
  DATACOLLECT: false
};

// TODO :
// * display information in visibility tags (on visibility screen and user profile)
export const VisibilityWebModules: React.FunctionComponent<Props> = ({
  domainsConfig,
  dimensions,
  webModulesSettings,
  onWebModuleSelectionChanged,
  fetchClaimValues,
  onClaimValueChanged,
}) => {
  React.useEffect(() => {
    if (!isEmpty(domainsConfig) && !isEmpty(webModulesSettings)) {
      const profileClaimUrl = getClaimUrl(domainsConfig, DomainEnum.WEBMODULES, "profile");
      
      fetchClaimValues("profile", profileClaimUrl);
    }
  }, [webModulesSettings]);

  const allCheckboxesUnchecked = (): boolean => {
    if (Object.keys(isWebModuleChecked).some(key => isWebModuleChecked[key] === true)) {
      return false;
    }
    return true;
  };

  return (
    <div className="form-group my-2 mx-3">
      <div className="row justify-content-start">
        <WebModuleProfileSelect
          dimensions={dimensions}
          onClaimValueChanged={onClaimValueChanged}
          allCheckboxesUnchecked={allCheckboxesUnchecked}
          domainsConfig={domainsConfig}
          fetchClaimValues={fetchClaimValues}
          webModulesSettings={webModulesSettings}
        />
        <WebModulesCheckboxes
          webModulesSettings={webModulesSettings}
          dimensions={dimensions}
          onWebModuleSelectionChanged={onWebModuleSelectionChanged}
          onClaimValueChanged={onClaimValueChanged}
          fetchClaimValues={fetchClaimValues}
          domainsConfig={domainsConfig}
        />
      </div>
    </div>
  );
};

export const getClaimOptions = (dimensionParams: Dimension[], claimId: string, domainEnum?: DomainEnum): IdNames<string> => {
  
  const dimensionsValues = map(
    filter(dimensionParams, ({ axisLabel, setId, domainId }) => (axisLabel === claimId && setId === SetIdEnum.One
      && (domainEnum === null || domainId === domainEnum) )),
    dimension => dimension.values
  );    
  return flatten(dimensionsValues);
};

export const getClaimValues = (
  webModulesSettings: SetSettings[],
  domainId: DomainEnum,
  claimId: string,
  defaultValue?: string
): string[] => {
  const defaultValueSet = defaultValue ? [defaultValue] : [];
  return !isEmpty(webModulesSettings)
    ? get(
        find(
          webModulesSettings[0].settings,
          setting => setting.claim.domainId === domainId && setting.claim.id === claimId
        ),
        "claim.values",
        defaultValueSet
      )
    : defaultValueSet;
};
