import * as React from "react";
import { ButtonProps } from "components/common/Buttons/button.typings";

export const BackButton: React.SFC<ButtonProps> = ({ label, className }) => {
  const handleOnclick = () => history.back();
  return (
    <button type="button" className={className ? className : "btn btn-lg btn-primary"} onClick={handleOnclick}>
      <i className="icon icon-prepend" />
      {label || "Back"}
    </button>
  );
};
