import * as React from "react";
import classNames from "classnames";
import { ComponentSize } from "components/common/components.typings";
import { InputPrefix } from "components/common/InputPrefix/InputPrefix";

export interface Props {
  term?: string;
  size?: ComponentSize;
  placeholder?: string;
  onChange: (term: string) => void;
}

interface State {
  term: string;
}

export default class QuickSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = { term: props.term || "" };
  }

  public render() {
    const { placeholder, size } = this.props;
    const sizeClass = size ? `input-group-${size}` : "";
    return (
      <div className={classNames("input-group", sizeClass)}>
        <InputPrefix iconName="search" />
        <input
          type="search"
          className="form-control"
          placeholder={placeholder || "Quick search"}
          value={this.state.term}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }

  private handleOnChange(event: any) {
    const term = event.target.value;
    this.setState({ term }, () => this.props.onChange(this.state.term));
  }
}
