import { connect } from "react-redux";
import { Visibility, DispatchProps, StateProps } from "components/Request/RequestValidate/Tabs/Visibility";
import {
  fetchVisibilityDomainsAction,
  requestUserVisibilityAction,
  fetchVisibilityByContactIdAction,
  saveVisibilityAction,
  validateVisibilityAction,
  rejectVisibilityAction,
  validateVisibilityFromModalAction,
} from "./request.visibility.action";
import { GlobalState } from "store/store.typings";
import { RouteComponentProps } from "react-router";
import { push } from "connected-react-router";
import { ROUTES } from "common/routes";
import { SetSettings, VisibilityClone } from "./request.visibility.typings";
import { Dispatch, Action } from "redux";
import { get } from "lodash";
import { mapTovisibilityRequestDTO, mapToRejectRequestDTO, mapToValidateRequestDTO } from "./request.visibility.mapper";

type OwnProps = RouteComponentProps<{ id: string; type: string }>;
const mapStateToProps = (
  {
    requestState: {
      requestDetail: { request },
      requestVisibility: { isFetching, isVisibilityFecthing, visibility, domains, dimensions },
    },
    connectedUserProfile: { userInfo },
  }: GlobalState,
  props: OwnProps
): StateProps => ({
  isFetching,
  isVisibilityFecthing,
  requestId: props.match.params.id,
  visibility,
  connectedUser: userInfo.fullName,
  isValidateCommentRequired: get(request, "isValidateCommentRequired"),
  domains,
  dimensions,
  visibilityType: props.match.params.type,
  koreanDataFlag: request?.needKoreanData,
  koreanDataComment: request?.koreanDataComment
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  cancelRequest: () => dispatch(push(ROUTES.HOME)),
  fetchAxises: () => dispatch(fetchVisibilityDomainsAction.started(null)),
  submitRequest: (allSettings: SetSettings[], requestId: string, koreanDataFlag: boolean | null, koreanDataComment: string | null) =>
    dispatch(saveVisibilityAction.started(mapTovisibilityRequestDTO(allSettings, requestId, koreanDataFlag, koreanDataComment))),
  validateRequest: (allSettings: SetSettings[], requestId: string, koreanDataFlag: boolean | null, koreanDataComment: string | null) =>
    dispatch(validateVisibilityAction.started(mapTovisibilityRequestDTO(allSettings, requestId, koreanDataFlag, koreanDataComment))),
  rejectRequest: (requestId: string, rejectionComment: string) =>
    dispatch(rejectVisibilityAction.started(mapToRejectRequestDTO(requestId, rejectionComment))),
  fetchVisibility: (requestId: string) => dispatch(requestUserVisibilityAction.started(requestId)),
  validateRequestFromModal: (requestId: string, validationComment: string) =>
    dispatch(validateVisibilityFromModalAction.started(mapToValidateRequestDTO(requestId, validationComment))),
  cloneRequest: (cloneVibility: VisibilityClone) => dispatch(fetchVisibilityByContactIdAction.started(cloneVibility)),
});

export const RequestVisiblityContainer = connect(mapStateToProps, mapDispatchToProps)(Visibility);
