import * as React from "react";
import { UserRequestDetail } from "containers/request/request.typings";
import { SectionTitle } from "components/common/Title/SectionTitle";
import { ContactViewerContainer } from "containers/contact/contact.container";
import { BlankCanvas } from "components/common/BlankCanvas/BlankCanvas";
import { RequestDetail } from "./RequestDetail";
import { Loading } from "components/common/Loading/Loading";
import { ErrorCanvas } from "components/common/BlankCanvas/ErrorCanvas";
import { noop, isEmpty, find } from "lodash";
import { isStringEmpty } from "utils/strings/stringUtils";
import {
  SetSettings,
  SgViewTagsColorEnum,
  MarkViewTagsColorEnum,
  AxisSettings,
  Domain,
  VisibilityClaim,
} from "containers/request/validate/requestVisibility/request.visibility.typings";
import {
  fillVisibilityClaimsColors,
  getClaims,
  getTagListForWebmodules,
  getClaimUrl,
} from "../RequestValidate/Tabs/visibility.state.logic";
import { VisibilityTagList } from "../RequestValidate/Tabs/VisibilityTagList";
import { DomainEnum } from "api/request/request.typings";
import { Dimension } from "containers/request/validate/requestVisibility/requestVisibilitySet/request.visibility.set.typings";

export interface StateProps {
  request: UserRequestDetail;
  requestId?: string;
  isReadOnly?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  sgViewSettings?: SetSettings[];
  markViewSettings?: SetSettings[];
  extendedSgViewSettings?: SetSettings[];
  extendedMarkViewSettings?: SetSettings[];
  asofViewSettings?: SetSettings[];
  facetViewSettings?: SetSettings[];
  webModulesSettings?: SetSettings[];
  dimensions?: Dimension[];
  domains?: Domain[];
  dataQualitySettings?: SetSettings[];
  clientContributionSettings?: SetSettings[];
}

export interface DispatchProps {
  onGetRequestDetails?: (requestId: string) => void;
  fetchClaimValues?: (claimId: string, claimServiceUrl: string, domain: DomainEnum) => void;
  fetchAxises?: () => void;
}

type Props = StateProps & DispatchProps;

export const RequestInfo: React.FunctionComponent<Props> = ({
  request,
  isFetching,
  errorMessage,
  requestId,
  sgViewSettings,
  markViewSettings,
  webModulesSettings,
  extendedSgViewSettings,
  extendedMarkViewSettings,
  asofViewSettings,
  facetViewSettings,
  onGetRequestDetails = noop,
  fetchClaimValues,
  dimensions,
  fetchAxises,
  domains,
  dataQualitySettings,
  clientContributionSettings
}: Props) => {
  React.useEffect(() => {
    onGetRequestDetails(requestId);
    fetchAxises();
  }, []);
  const getViewAccess = (viewSettings: SetSettings[]): boolean => !isEmpty(viewSettings);

  const hasError = !isFetching && !isStringEmpty(errorMessage);
  const contactNotFound = !isFetching && request && !request.user;
  const sgViewAccess = getViewAccess(sgViewSettings);
  const markViewAccess = getViewAccess(markViewSettings);
  const sgViewClaims = fillVisibilityClaimsColors(getClaims(sgViewSettings), SgViewTagsColorEnum, sgViewAccess);
  const markViewClaims = fillVisibilityClaimsColors(getClaims(markViewSettings), MarkViewTagsColorEnum, markViewAccess);
  const webModulesClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
    getClaims(webModulesSettings),
    SgViewTagsColorEnum,
    false,
    true,
    true,
    true
  );
  const dataQualityClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
    getClaims(dataQualitySettings),
    SgViewTagsColorEnum,
    false,
    true,
    true,
    false
  );

  const updatedWebModules: VisibilityClaim = {
    ...webModulesClaims[0],
    claims: getTagListForWebmodules(webModulesClaims[0].claims, dimensions),
  };

  const extendedSgViewAccess = getViewAccess(extendedSgViewSettings);
  const extendedMarkViewAccess = getViewAccess(extendedMarkViewSettings);
  const extendedSgViewClaims = fillVisibilityClaimsColors(
    getClaims(extendedSgViewSettings),
    SgViewTagsColorEnum,
    extendedSgViewAccess
  );
  const extendedMarkViewClaims = fillVisibilityClaimsColors(
    getClaims(extendedMarkViewSettings),
    MarkViewTagsColorEnum,
    extendedMarkViewAccess
  );

  const asofViewAccess = getViewAccess(asofViewSettings);
  const facetViewAccess = getViewAccess(facetViewSettings);
  const asofViewClaims = fillVisibilityClaimsColors(getClaims(asofViewSettings), MarkViewTagsColorEnum, asofViewAccess);
  const facetViewClaims = fillVisibilityClaimsColors(
    getClaims(facetViewSettings),
    MarkViewTagsColorEnum,
    facetViewAccess
  );

  if (!isEmpty(webModulesSettings) && !find(webModulesSettings[0].settings, x => x.claim.id === "profile")) {
    const profileSetting: AxisSettings = {
      axisName: undefined,
      claim: {
        id: "profile",
        label: "Profile",
        values: ["User"],
        domainId: DomainEnum.WEBMODULES,
        operator: "equal"
      },
    };
    webModulesSettings[0].settings.push(profileSetting);
  }

  const clientContributionClaims: VisibilityClaim[] = fillVisibilityClaimsColors(
    getClaims(clientContributionSettings),
    SgViewTagsColorEnum,
    false,
    true,
    true,
    false
  );
    
  React.useEffect(() => {
    if (
      !isEmpty(webModulesSettings) &&
      find(webModulesSettings[0].settings, x => x.claim.id === "suppliers") &&
      dimensions.length === 0
    ) {
      const suppliersClaimUrl = getClaimUrl(domains, DomainEnum.MASSUPLOAD, "suppliers");
      fetchClaimValues("suppliers", suppliersClaimUrl, DomainEnum.MASSUPLOAD);

      const dataCollectsuppliersClaimUrl = getClaimUrl(domains, DomainEnum.DATACOLLECT, "suppliers");
      fetchClaimValues("suppliers", dataCollectsuppliersClaimUrl, DomainEnum.DATACOLLECT);
    }
  }, [webModulesSettings]);

  return (
    <>
      <br />
      <Loading isVisible={isFetching} />
      <ErrorCanvas
        visible={hasError || !requestId}
        title={!requestId ? "Request does not exists" : "An error occured"}
      />

      {!hasError && !!request && (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 order-first">
                <SectionTitle size="4" title="Contact information" />
                {!contactNotFound ? (
                  <ContactViewerContainer contactId={request.contactId} />
                ) : (
                  <BlankCanvas iconName="search" title="Contact does not exists" />
                )}
                <RequestDetail request={request} />
              </div>
              <div className="mb-4 col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div className="pb-4">
                  <SectionTitle size="4" title="Visibility information" />
                </div>
                <VisibilityTagList
                  sgViewClaims={sgViewClaims}
                  markViewClaims={markViewClaims}
                  webModulesClaims={[updatedWebModules]}
                  extendedSgViewClaims={extendedSgViewClaims}
                  extendedMarkViewClaims={extendedMarkViewClaims}
                  asOfViewClaims={asofViewClaims}
                  facetViewClaims={facetViewClaims}
                  dataQualityClaims={dataQualityClaims}
                  clientContributionClaims={clientContributionClaims}
                  showTitle={true}
                />
                <br />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
