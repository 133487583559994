import * as React from "react";
import * as ReactDOM from "react-dom";
import { App } from "components/App/App";
import { sgwtConnectComponent } from "common/auth/getWidgetBus";
import { initialize } from "store/store";
import "styles/main.scss";

const sgwtConnect = sgwtConnectComponent();
if (sgwtConnect.isAuthorized()) {
  const { store, history } = initialize();
  ReactDOM.render(<App store={store} history={history} />, document.getElementById("root"));
} else if (sgwtConnect.getAuthorizationError()) {
  console.error({
    source: "authorization",
    error: `Authorization failed with error ${sgwtConnect.getAuthorizationError()}`,
  });
} else {
  sgwtConnect.requestAuthorization();
}
