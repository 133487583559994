import { VisibilityClaim } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { map, groupBy, sortBy } from "lodash";
import { ComponentColor } from "components/common/components.typings";
import * as React from "react";
import { TagList } from "components/common/TagList/TagList";
import { DomainEnum } from "api/request/request.typings";

export interface ViewSettingsTagListProps {
  label?: string;
  settings: VisibilityClaim[];
  displayOperator: boolean;
}

export const ViewSettingsTagList: React.FunctionComponent<ViewSettingsTagListProps> = ({ label, settings, displayOperator }) => (
  <div className="d-flex">
    {label && (
      <div className="d-inline" style={{ width: "140px" }}>
        <span>
          <b>{label}</b>
        </span>
      </div>
    )}
    <div className="col d-inline" style={{ maxWidth: "fit-content" }}>
      {map(settings, (visibilityClaim, keyIndex) =>
        map(
          groupBy(
            sortBy(visibilityClaim.claims, [
              claim => (claim.domainId === DomainEnum.WEBMODULES ? 0 : 1),
              item => (item.id === "allowaccess" ? 0 : 1),
            ]),
            "domainId"
          ),
          (claims, domainId) => (
            <div key={`${domainId}_${keyIndex}`} className="d-block p-1">
              {map(claims, (claim, claimIndex) => (
                <TagList
                  key={`${domainId}_${keyIndex}_${claimIndex}`}
                  type={claim.color as ComponentColor}
                  outline={true}
                  prependSquare={true}
                  domainId={domainId}
                  claim={claim}
                  displayOperator={displayOperator}
                  className="mb-1"
                />
              ))}
            </div>
          )
        )
      )}
    </div>
  </div>
);
