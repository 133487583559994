import * as React from "react";
import { IconAdd } from "../Icon/Icon";

export interface AddButtonProps {
  onClick: (event: any) => void;
  label: string;
  className?: string;
}

export const AddButton: React.FunctionComponent<AddButtonProps> = ({ onClick, label, className }) => (
  <div className={className}>
    <button type="button" className="btn btn-lg btn-default sgbs-btn-default btn-icon-text w-100" onClick={onClick}>
      <IconAdd />
      {label}
    </button>
  </div>
);
