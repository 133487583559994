import * as React from "react";
import { Link } from "react-router-dom";
import { ButtonProps } from "components/common/Buttons/button.typings";

export interface ButtonLinkProps extends ButtonProps {
  to: string;
}

export const ButtonLink: React.SFC<ButtonLinkProps> = ({ to, className, label, children }) => (
  <Link className={className ? className : "btn btn-lg btn-primary"} to={to}>
    {label}
    {children}
  </Link>
);
