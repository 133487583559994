import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { RouteComponentProps } from "react-router";
import { fetchRequestByIdAction } from "../../detail/request.detail.action";
import { RequestInfo, StateProps, DispatchProps } from "components/Request/Common/RequestInfo";
import { get } from "lodash";
import { performFetchClaimValues } from "../requestVisibility/webModules/request.webmodules.container";
import { fetchVisibilityDomainsAction } from "../requestVisibility/request.visibility.action";
import { DomainEnum } from "api/request/request.typings";

type OwnProps = RouteComponentProps<{ id: string }>;

const mapStateToProps = ({ requestState }: GlobalState, { match }: OwnProps): StateProps => ({
  requestId: match.params.id,
  request: requestState.requestInfo.request,
  isFetching: requestState.requestValidate.isFetching,
  sgViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "sgViewSettings"),
  markViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "markViewSettings"),
  webModulesSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "webModulesSettings"),
  extendedSgViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "extendedSgViewSettings"),
  extendedMarkViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "extendedMarkViewSettings"),
  asofViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "asofViewSettings"),
  facetViewSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "facetViewSettings"),
  dimensions: get(get(requestState, "requestVisibility"), "dimensions"),
  domains: get(get(requestState, "requestVisibility"), "domains"),
  dataQualitySettings: get(get(requestState.requestInfo.request, "requestVisibility"), "dataQualitySettings"),
  clientContributionSettings: get(get(requestState.requestInfo.request, "requestVisibility"), "clientContributionSettings")
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onGetRequestDetails: (requestId: string) => dispatch(fetchRequestByIdAction.started(requestId)),
  fetchClaimValues: (claimId: string, claimServiceUrl: string, domain: DomainEnum) =>
    dispatch(performFetchClaimValues(claimId, claimServiceUrl, domain)),
  fetchAxises: () => dispatch(fetchVisibilityDomainsAction.started(null)),
});

export const RequestInfoContainer = connect(mapStateToProps, mapDispatchToProps)(RequestInfo);
