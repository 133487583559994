import actionCreatorFactory from "typescript-fsa";
import { Dimension } from "../requestVisibilitySet/request.visibility.set.typings";

const enum DataCollectVisibilityActionName {
  FETCH_WEBMODULES_DATACOLLECT_SUPPLIERS_CLAIM_VALUES = "FETCH_WEBMODULES_DATACOLLECT_SUPPLIERS_CLAIM_VALUES",
}

const actionCreator = actionCreatorFactory("RequestVisibility");

export const fetchDataCollectSuppliersClaimValuesAction = actionCreator.async<
  { claimId: string; claimServiceUrl: string },
  Dimension,
  string
>(DataCollectVisibilityActionName.FETCH_WEBMODULES_DATACOLLECT_SUPPLIERS_CLAIM_VALUES);
