import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { UserRequestDetail } from "containers/request/request.typings";
import { ApplicationEnum, StatusEnum } from "containers/referential/referential.typings";
import { get } from "lodash";

export const hasUserRight = ({ canValidateCprofitRequest }: ConnectedUserProfileRights, request: UserRequestDetail) => {
  const applicationId = get(request, "application.id");
  const hasCprofitRight = applicationId === ApplicationEnum.C_PROFIT && canValidateCprofitRequest;
  return hasCprofitRight;
};

export const isRequestReadOnly = (userRight: ConnectedUserProfileRights, request: UserRequestDetail) => {
  return request && request.status.id !== StatusEnum.PENDING && hasUserRight(userRight, request);
};
