import * as React from "react";
import { includes, isEqual, filter } from "lodash";

export const useToggleList = <T>(
  initList: T[] = []
): [T[], (item: T) => void, React.Dispatch<React.SetStateAction<T[]>>] => {
  const [list, setList] = React.useState<T[]>(initList);

  const toggleItemInList = (item: T) => {
    if (!includes(list, item)) {
      setList([...list, item]);
    } else {
      setList(filter(list, s => !isEqual(s, item)));
    }
  };

  return [list, toggleItemInList, setList];
};
