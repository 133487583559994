import * as React from "react";
import { SubmitButton } from "components/common/Buttons/SubmitButton";
import { Loading } from "components/common/Loading/Loading";
export interface StateProps {
  isFetching: boolean;
}
export interface DispatchProps {
  onFetchExportRequest: () => void;
}
export type Props = DispatchProps & StateProps;

export class Export extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { isFetching } = this.props;
    return (
      <>
        <Loading isVisible={isFetching} />
        <div className="col">
          <SubmitButton
            onClick={this.handleOnExport}
            disabled={isFetching}
            label="Download"
            className={"btn btn-lg btn-primary"}
            icon="save_alt"
            iconClassName={"icon mr-2"}
            isIconBeforeLabel={true}
          />
        </div>
      </>
    );
  }
  private handleOnExport = () => {
    this.props.onFetchExportRequest();
  };
}
