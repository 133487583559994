import * as React from "react";
import { UserRequestDetail } from "containers/request/request.typings";
import { RequestStatus } from "components/common/RequestStatus/RequestStatus";
import { get } from "lodash";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { getNavigateToUrl } from "components/Request/Common/navigate";

export interface Props {
  request: UserRequestDetail;
  right: ConnectedUserProfileRights;
}

export const UserRequestsRow: React.FunctionComponent<Props> = ({
  request: { requestId, status, modifiedDate, modifiedUser, createdDate, endDate, validator, application },
  right,
}) => (
  <tr key={requestId}>
    <td>
      <RequestStatus status={status} />
    </td>
    <td>{createdDate}</td>
    <td>{modifiedDate}</td>
    <td>{get(modifiedUser, "fullName", get(modifiedUser, "email", ""))}</td>
    <td>{endDate}</td>
    <td>{get(validator, "fullName", get(validator, "email", ""))}</td>
    <td>{getNavigateToUrl(requestId, get(application, "id", ""), get(status, "id", ""), right)} </td>
  </tr>
);
