export type Environment = "test" | "development" | "DEV" | "UAT" | "ISO" | "production" | "PROD";
export const environment = process.env.NODE_ENV as Environment;

export const getEnvironment = (): Environment => {
  const executionMode = process.env.NODE_ENV;
  if (executionMode !== "production") {
    return executionMode as Environment;
  }
  return window["globalConfiguration"].env;
};
