import { RequestVisibilityState, initState } from "../request.visibility.reducer";
import { isType } from "typescript-fsa";
import { filter } from "lodash";
import reduceReducer from "reduce-reducers";
import { fetchDataCollectSuppliersClaimValuesAction } from "./request.datacollect.action";

export const fetchDataCollectVisibilityClaimValuesReducer = (
  state: RequestVisibilityState = initState,
  action
): RequestVisibilityState => {
  if (isType(action, fetchDataCollectSuppliersClaimValuesAction.started)) {
    return { ...state, isClaimValuesFetching: true, errorMessage: null };
  }
  if (isType(action, fetchDataCollectSuppliersClaimValuesAction.done)) {    
    return { ...state, dimensions: [...filter(state.dimensions, ({ domainId }) => domainId !== action.payload.result.domainId), action.payload.result], isClaimValuesFetching: false };
  }
  if (isType(action, fetchDataCollectSuppliersClaimValuesAction.failed)) {
    return { ...state, isClaimValuesFetching: false, errorMessage: action.payload.error };
  }
  return state;
};

export const requesDataCollectReducer = reduceReducer(fetchDataCollectVisibilityClaimValuesReducer);
