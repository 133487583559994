import * as React from "react";
import { ClickableButtonProps } from "components/common/Buttons/button.typings";
import { noop } from "lodash";
import { Icon as IconType } from "../components.typings";

interface IconProps {
  className: string;
  icon: IconType;
}

const Icon: React.FC<IconProps> = ({ icon, className }: IconProps) =>
  icon ? <i className={className}> {icon}</i> : null;

export const SubmitButton: React.FC<ClickableButtonProps> = ({
  onClick,
  label,
  className,
  iconClassName,
  isInModal,
  disabled,
  icon,
  isIconBeforeLabel,
}) => (
  <button
    type="button"
    disabled={disabled}
    data-dismiss={isInModal ? "modal" : ""}
    className={className ? className : "btn btn-lg btn-primary mr-2"}
    onClick={onClick || noop}
  >
    <>
      {isIconBeforeLabel && <Icon icon={icon} className={iconClassName || "mr-1 icon icon-sm"} />}
      {label || "Submit"}
      {!isIconBeforeLabel && <Icon icon={icon} className={iconClassName || "ml-1 icon icon-sm"} />}
    </>
  </button>
);
