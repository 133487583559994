import * as React from "react";
import { NotFound } from "components/layout/NotFound/NotFound";
import { ROUTES } from "common/routes";
import { Redirect, Route, Switch } from "react-router";
import { UserRoute } from "containers/user/user.route";
import { RequestRoute } from "containers/request/request.route";

export const AppRoutes: React.FunctionComponent = () => (
  <Switch>
    <Redirect exact={true} from={ROUTES.HOME} to={ROUTES.REQUEST_LIST} />
    <Route path={ROUTES.USER_LIST} component={UserRoute} />
    <Route path={ROUTES.REQUEST_LIST} component={RequestRoute} />
    <Route path={ROUTES.NOT_FOUND} component={NotFound} />
    <Route component={NotFound} />
  </Switch>
);
