import { UserProfile, Applications } from "containers/userProfile/userProfile.typings";
import { UserDTO } from "api/user/user.typing";
import { isEmpty, get } from "lodash";
import { whiteList } from "containers/user/coverage/user.coverage.const";

export const mapToUserProfile = (userProfile: UserProfile, userDTO?: UserDTO): UserProfile => {
  const canCreateRequest = !!userDTO;
  const canValidateCprofitRequest = canCreateRequest && userDTO.isValidatorCprofit;

  const canAccessCoverage = whiteList.indexOf(userDTO.email) !== -1;

  return {
    ...userProfile,
    userRight: { canCreateRequest, canValidateCprofitRequest, canAccessCoverage },
  };
};

export const mapToApplication = (userDTO?: UserDTO): Applications => {
  return {
    hasAccessToCprofit: !isEmpty(get(userDTO, "validatedRequestCProfit")),
  };
};
