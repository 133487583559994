import * as React from "react";
import { map } from "lodash";
import { AxisValue } from "containers/request/validate/requestVisibility/request.visibility.typings";
import { Select } from "components/Select/Select";
import { IdAndLabel } from "common/common.typings";
import { IconInfo } from "components/common/Icon/Icon";
import { If } from "components/common/If/If";

export const EmptyClaimId = "emptyAxis";

export interface SelectAxisProps {
  label: string;
  values: AxisValue[];
  onChange: (selectedAxis: string) => void;
  initialValue?: string;
  disabled?: boolean;
}

export const SelectAxis: React.FunctionComponent<SelectAxisProps> = ({
  label,
  values,
  onChange,
  initialValue = "",
  disabled = false,
}) => {
  const options = map(values, ({ claimId, claimLabel }) => ({ id: claimId, label: claimLabel }));
  const emptyClaim = { id: EmptyClaimId, label: "Select an axis" };
  const [previousId, setPreviousId] = React.useState<string>(undefined);

  const handleOnChange = (selectedId: string) => {
    if (selectedId !== previousId) {
      setPreviousId(selectedId);
      onChange(selectedId);
    }
  };

  return (
    <div className="form-group col-5">
      <fieldset disabled={disabled}>
        <div className="d-flex">
          <label htmlFor="Axises">{label}</label>
          <If condition={label === "Coverage"}>
            <a
              className="ml-2"
              data-toggle="tooltip"
              title="The Coverage = Y configuration allows user to access C-profit data  in SG View on his IC portfolio clients (list available in coverage tab)"
            >
              <IconInfo size="sm" />
            </a>
          </If>
        </div>
        <Select<IdAndLabel<string>, string>
          options={[emptyClaim, ...options]}
          onSelectionChange={id => handleOnChange(id)}
          selectedId={initialValue}
        />
      </fieldset>
    </div>
  );
};
