import { Epic, combineEpics } from "redux-observable";
import { Action } from "redux";
import { Observable, of, forkJoin } from "rxjs";
import { filter, switchMap, catchError, map } from "rxjs/operators";
import { fetchCoverageByUserId, fetchRequestsByUserId, terminateUserAccess, updateUserEmail } from "api/user/user.api";
import { mapToUserRequestsDetails, UserRequestWithMeta } from "api/request/request.mapper";
import { fetchContactsByIds, fetchContactById } from "api/contacts/contact.api";
import { UserRequestDetailDTO } from "api/request/request.typings";
import { IcHttpResquestHeader } from "api/api.typings";
import { isEmpty, map as lodashMap } from "lodash";
import {
  fetchUserCoverageAction,
  fetchUserRequestsAction,
  terminateUserAccessAction,
  updateUserEmailAction,
} from "./user.detail.action";
import { Contact } from "containers/contact/contact.typings";
import { mapToApplication } from "containers/userProfile/userProfile.map";

export const getUserRequestsByIdEpic: Epic<Action> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(fetchUserRequestsAction.started.match),
    switchMap(({ payload, meta }) =>
      forkJoin(fetchRequestsByUserId(payload, meta.headers), fetchContactById(payload, meta.headers)).pipe(
        switchMap(([requestsList, user]) =>
          !isEmpty(requestsList)
            ? fetchContactByRequestUser(requestsList.requests, meta.headers, user)
            : of(fetchUserRequestsAction.done({ result: [], params: null }))
        ),
        catchError(error => of(fetchUserRequestsAction.failed({ params: payload, error })))
      )
    )
  );

export const terminateUserAccessEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(terminateUserAccessAction.started.match),
    switchMap(({ payload, meta }) =>
      terminateUserAccess(payload, meta.headers).pipe(
        map(user => terminateUserAccessAction.done({ result: mapToApplication(user), params: payload })),
        catchError(({ message }) => of(terminateUserAccessAction.failed({ error: message, params: payload })))
      )
    )
  );

const fetchContactByRequestUser = (requests: UserRequestDetailDTO[], header: IcHttpResquestHeader, user: Contact) =>
  forkJoin(
    fetchContactsByIds(
      lodashMap(requests, ({ validatorId }) => validatorId).concat(
        lodashMap(requests, ({ modifiedUserId }) => modifiedUserId)
      ),
      header
    )
  ).pipe(
    map(([contacts]) => mapToUserRequestsDetails(buildUserResquestsWithMeta(requests, user, contacts))),
    map(finalResult => fetchUserRequestsAction.done({ result: finalResult, params: null })),
    catchError(({ message }) => of(fetchUserRequestsAction.failed({ error: message, params: null })))
  );

export const updateUserEmailEpic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(updateUserEmailAction.started.match),
    switchMap(({ payload, meta }) =>
      updateUserEmail(payload, meta.headers).pipe(
        map(user => updateUserEmailAction.done({ result: user.email, params: payload })),
        catchError(({ message }) => of(updateUserEmailAction.failed({ error: message, params: payload })))
      )
    )
  );

const buildUserResquestsWithMeta = (
  requests: UserRequestDetailDTO[],
  user: Contact,
  contacts: Contact[]
): UserRequestWithMeta[] =>
  lodashMap(requests, request => ({
    userRequestDetailDTO: request,
    user,
    contacts,
  }));

export const getUserCoverageByIdEpic: Epic<Action> = (action$: Observable<Action>) =>
  action$.pipe(
    filter(fetchUserCoverageAction.started.match),
    switchMap(({ payload, meta }) =>
      fetchCoverageByUserId(payload, meta.headers).pipe(
        map(user => fetchUserCoverageAction.done({ result: user.keysValues, params: payload })),
        catchError(error => of(fetchUserCoverageAction.failed({ params: payload, error })))
      )
    )
  );

export const userDetailEpic = combineEpics(
  getUserRequestsByIdEpic,
  terminateUserAccessEpic,
  updateUserEmailEpic,
  getUserCoverageByIdEpic
);
