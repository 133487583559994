import * as React from "react";
import { UserRequestClaims, UserRequestDetail } from "containers/request/request.typings";
import { ConnectedUserProfileRights } from "containers/userProfile/userProfile.typings";
import { VisibilityNavigator } from "../Navigator/VisibilityNavigator";
import { ROUTES } from "common/routes";
import { Switch, Route, Redirect } from "react-router";
import { PageHeader } from "components/common/PageHeader/PageHeader";
import { RequestInfoContainer } from "containers/request/validate/requestInfo/request.info.container";
import { RequestVisiblityContainer } from "containers/request/validate/requestVisibility/request.visibility.container";
import { If } from "components/common/If/If";
import { hasUserRight } from "./requestValidateRights";
import { ExceptionMessage } from "./ExceptionMessage";
import { ErrorCanvas } from "components/common/BlankCanvas/ErrorCanvas";
import { isStringEmpty } from "utils/strings/stringUtils";
import { EmailDiscrepancyContainer } from "containers/header/emailDiscrepancy.container";

export interface StateProps {
  requestId: string;
  isFetching: boolean;
  errorMessage: string;
  request: UserRequestDetail;
  userRight: ConnectedUserProfileRights;
  isReadOnly?: boolean;
}

export interface DispatchProps {
  onValidateRequest: (requestWithClaims: UserRequestClaims) => void;
}

export type Props = StateProps & DispatchProps;

export const RequestValidatePage: React.FunctionComponent<Props> = ({
  errorMessage,
  requestId,
  request,
  userRight,
  isReadOnly,
}) => {
  const hasError = !isStringEmpty(errorMessage);
  const canValidate = hasUserRight(userRight, request);
  const canEditRequest = canValidate && !isReadOnly;

  return (
    <If
      condition={!hasError}
      elseComponent={
        <ErrorCanvas
          visible={true}
          title={!canValidate ? ExceptionMessage.insufficientRight : ExceptionMessage.internalError}
        />
      }
    >
      <EmailDiscrepancyContainer />
      <PageHeader title="Request details" isBackButtonShown={true} />
      <div className="row mt-4">
        <VisibilityNavigator requestId={requestId} canValidate={canEditRequest} />
      </div>
      <Switch>
        <Route exact={true} path={ROUTES.REQUEST_DETAIL_USER_INFO} component={RequestInfoContainer} />
        {canEditRequest && (
          <Route exact={true} path={ROUTES.REQUEST_DETAIL_MAIN_VISIBILITY} component={RequestVisiblityContainer} />
        )}
        {canEditRequest && (
          <Route
            exact={true}
            path={ROUTES.REQUEST_DETAIL_WEBMODULES_VISIBILITY}
            component={RequestVisiblityContainer}
          />
        )}
        <Redirect to={ROUTES.REQUEST_DETAIL_USER_INFO} />
      </Switch>
    </If>
  );
};
