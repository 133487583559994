import * as React from "react";
import { Icon } from "components/common/Icon/Icon";
import { useDropdownState } from "hooks/useDropdownState";
import { noop } from "lodash";
import * as styles from "./SelectDropDown.scss";
import classNames from "classnames";
import { ComponentSize } from "../common/components.typings";
import { ToggleDropDownButton } from "./ToggleDropDownButton";
import { isStringEmpty } from "utils/strings/stringUtils";

interface Props {
  displayedText?: string;
  onToggleDropDown?: (isDropDownShown: boolean) => void;
  isDropDownShown?: boolean;
  iconName?: string;
  size?: ComponentSize;
  children?: React.ReactNode;
  withOverflow?: boolean;
  withMinWidth?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  onClearClick?: () => void;
}

export const SelectDropDown: React.FunctionComponent<Props> = ({
  iconName,
  size = "md",
  displayedText = "",
  children = null,
  isDropDownShown = false,
  onToggleDropDown = noop,
  withOverflow,
  withMinWidth = false,
  placeHolder = "",
  disabled = false,
  onClearClick = noop,
}: Props) => {
  const ref = React.useRef(null);
  const [isDropDownShownState, setToggleDropdown] = useDropdownState(ref, isDropDownShown);
  const [displayedTextState, setDisplayedTextState] = React.useState(displayedText);

  const handleOnInputClick = () => {
    if (!disabled) {
      setToggleDropdown(!isDropDownShownState);
    }
  };

  const handleOnClearClick = () => {
    if (!disabled) {
      onClearClick();
    }
  };

  React.useEffect(() => onToggleDropDown(isDropDownShownState), [isDropDownShownState]);
  React.useEffect(() => setDisplayedTextState(displayedText), [displayedText]);

  return (
    <div
      ref={ref}
      className={classNames(`input-group input-group-${size}`, withMinWidth ? styles.SelectDropDown : undefined)}
    >
      {iconName && (
        <div className="input-group-prepend input-group-merged">
          <Icon iconName={iconName} className="text-secondary" />
        </div>
      )}
      <ToggleDropDownButton
        isDropDownShown={isDropDownShownState}
        size={size}
        onClick={handleOnInputClick}
        enableClear={!isStringEmpty(displayedTextState)}
        onClearClick={handleOnClearClick}
      />
      <input
        type="text"
        className={`form-control form-control-${size} ${disabled ? "" : "ignore-read-only-style"}`}
        readOnly={true}
        value={displayedTextState}
        onClick={handleOnInputClick}
        placeholder={placeHolder}
      />
      {isDropDownShownState && (
        <>
          <div className={`card card-dropdown shadow-lg ${withOverflow ? "card-dropdown-overflow" : ""}`}>
            <div className="card-body p-0">{children}</div>
          </div>
        </>
      )}
    </div>
  );
};
