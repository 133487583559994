import * as React from "react";

interface Props {
  id: string;
  label: string;
  className?: string;
  children: React.ReactNode;
}

export const FormField: React.FunctionComponent<Props> = ({ id, label, children = null, className = "" }: Props) => (
  <div className={`form-group ${className}`}>
    <label className="text-secondary mb-0" htmlFor={id}>
      {label}
    </label>
    <span id={id}>{children}</span>
  </div>
);
