import * as React from "react";
import { Application, ApplicationEnum } from "containers/referential/referential.typings";
import { noop } from "lodash";
import { Select } from "components/Select/Select";

export interface StateProps {
  selectedId?: ApplicationEnum;
  applications: Application[];
}

export interface DispatchProps {
  onFetchApplications: () => void;
  onSelectionChange?: (applicationId: ApplicationEnum) => void;
}

export type Props = StateProps & DispatchProps;

export class ApplicationSelect extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public componentDidMount() {
    this.props.onFetchApplications();
  }

  public render() {
    const { selectedId = null, applications, onSelectionChange = noop } = this.props;
    return (
      <Select<Application, ApplicationEnum>
        options={applications}
        selectedId={selectedId}
        onSelectionChange={onSelectionChange}
      />
    );
  }
}
