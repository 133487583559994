import { includes } from "lodash";
import { Store } from "redux";
import { GlobalState } from "store/store.typings";
import { IcHttpResquestHeader } from "api/api.typings";
import { UserProfileState } from "containers/userProfile/userProfile.reducer";
import { sgwtConnectComponent } from "common/auth/getWidgetBus";

export const apiMiddleware = (store: Store<GlobalState>) => next => action => {
  if (includes(action.type, "STARTED")) {
    const { connectedUserProfile: userProfile } = store.getState();

    const userEmail = getConnectedUserEmail(userProfile);

    const sgwtConnect = sgwtConnectComponent();
    const token = sgwtConnect.getAuthorizationHeader();
    const headers: IcHttpResquestHeader = {
      authorization: token,
      userEmail,
    };

    action.meta = action.meta ? { ...action.meta, headers } : { headers };
  }
  next(action);
};

export const getConnectedUserEmail = (connectedUser: UserProfileState): string | null =>
  connectedUser && connectedUser.userInfo && connectedUser.userInfo.userEmail ? connectedUser.userInfo.userEmail : null;
