import { connect } from "react-redux";
import { GlobalState } from "store/store.typings";
import { Dispatch } from "redux";
import { fetchRolesAction } from "containers/referential/referential.action";
import { noop } from "lodash";
import { RoleEnum } from "containers/referential/referential.typings";
import { DispatchProps, RoleSelect, StateProps } from "components/Select/RoleSelect";

export interface OwnProps {
  selectedId?: RoleEnum;
  onSelectionChange?: (cerberusRoleId: RoleEnum) => void;
}

const mapStateToProps = ({ referential }: GlobalState, props: OwnProps): StateProps => ({
  roles: referential.roles,
  selectedId: props.selectedId,
});

const mapDispatchToProps = (dispatch: Dispatch, { onSelectionChange = noop }: OwnProps): DispatchProps => ({
  onFetchApplications: () => dispatch(fetchRolesAction.started(null)),
  onSelectionChange: (cerberusRoleId: RoleEnum) => onSelectionChange(cerberusRoleId),
});

export const RoleSelectContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(RoleSelect);
