import actionCreatorFactory from "typescript-fsa";
import { UserRequestDetail } from "../request.typings";

const enum RequestDetailActionName {
  FETCH_REQUESTS_BY_ID = "FETCH_REQUESTS_BY_ID",
}

const requestActionFactory = actionCreatorFactory("RequestDetail");

export const fetchRequestByIdAction = requestActionFactory.async<string, UserRequestDetail, string>(
  RequestDetailActionName.FETCH_REQUESTS_BY_ID
);
