import * as React from "react";
import { Counter } from "../Counter/Counter";
import { IconPaginationRight } from "../Icon/Icon";

interface Props {
  id: string;
  label: string;
  collapse?: boolean;
  count?: number;
  children?: React.ReactNode;
}

export const Collapse: React.FunctionComponent<Props> = ({
  id,
  label,
  collapse = true,
  count = 0,
  children = null,
}) => {
  const [isCollapsed, setCollapse] = React.useState<boolean>(collapse);
  const handleOnclick = () => setCollapse(!isCollapsed);

  return (
    <div className="card">
      <div className="pl-0 card-header" id="headingOne">
        <button
          className={`btn btn-flat-primary text-large justify-content-start pl-0 ml-2 ${
            isCollapsed ? "collapsed" : ""
          } `}
          data-toggle="collapse"
          data-target={`#${id}`}
          aria-expanded={!isCollapsed}
          onClick={handleOnclick}
        >
          <IconPaginationRight />
          {label}
          <Counter count={count} />
        </button>
      </div>
      <div
        id={id}
        className={`collapse ${isCollapsed ? "" : "show"}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body pt-0">{children}</div>
      </div>
    </div>
  );
};
