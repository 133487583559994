import { GlobalState } from "store/store.typings";
import { connect } from "react-redux";
import { ContactViewer, StateProps, DispatchProps } from "components/Contact/ContactViewer/ContactViewer";
import { fetchContactByIdAction } from "containers/contact/contact.action";

interface OwnProps {
  contactId: string;
}

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onFetchContactById: (contactId: string) => dispatch(fetchContactByIdAction.started(contactId)),
});

const mapStateToProps = ({ contactState }: GlobalState, ownProps: OwnProps): StateProps => {
  return {
    contactId: ownProps.contactId,
    contact: contactState.contact,
    isFetching: contactState.isFetching,
  };
};

export const ContactViewerContainer = connect<StateProps, DispatchProps, OwnProps, GlobalState>(
  mapStateToProps,
  mapDispatchToProps
)(ContactViewer);
